import type { EditorSDK, PageData } from '@wix/platform-editor-sdk';
import type { Link, TpaPageLink } from '@wix/document-services-types';

import { log } from '../../../../utils/monitoring';
import { MEMBER_TPA_PAGE_ID, MENU_IDS, MenuItemIdentifier } from '../../../constants';
import { getApplicationPages } from '../../../wrappers/pages';
import { getIsTpaPageLink } from '../../../wrappers/menus';

import {
  getCorruptedMenuItemsData,
  getFixedMenuItemsData,
  updateMenus,
  logMenuItemsFix,
} from '../../../data-fixers/utils/fix-tpa-page-links';

const menuIds = [MENU_IDS.SUB_MENU_ID, MENU_IDS.LOGIN_MENU_ID, MENU_IDS.LOGIN_ICONS_MENU_ID];

const getPageUriSEO = (applicationPages: PageData[]) => {
  const profilePageUriSEO = applicationPages.find(({ tpaPageId }) => tpaPageId === MEMBER_TPA_PAGE_ID)?.pageUriSEO;

  if (!profilePageUriSEO) {
    throw new Error('could not find profile page uri SEO');
  }

  return profilePageUriSEO;
};

const getIsTpaLinkValid = (link: Link | undefined, applicationPages: PageData[]) => {
  if (!getIsTpaPageLink(link)) {
    return true;
  }

  const { itemTypeIdentifier, path } = link as TpaPageLink;

  if (itemTypeIdentifier !== MenuItemIdentifier.SubPage) {
    return true;
  }

  const pageUriSEO = getPageUriSEO(applicationPages);

  return path.startsWith(`/${pageUriSEO}`);
};

const validateAndFixTpaPageLinks = async (editorSDK: EditorSDK) => {
  const applicationPages = await getApplicationPages({ editorSDK });

  const corruptedMenuItemsData = await getCorruptedMenuItemsData({
    editorSDK,
    menuIds,
    applicationPages,
    getIsTpaLinkValid,
  });

  if (!corruptedMenuItemsData.length) {
    return;
  }

  const fixedMenuItemsData = getFixedMenuItemsData({
    corruptedMenuItemsData,
    applicationPages,
    getIsTpaLinkValid,
    getPageUriSEO,
  });

  await updateMenus(editorSDK, fixedMenuItemsData);

  const fixedMenuIds = corruptedMenuItemsData.map(({ menuId }) => menuId);
  logMenuItemsFix(fixedMenuIds);
};

export const fixTpaPageLinksIfCorrupted = async (editorSDK: EditorSDK) => {
  try {
    await validateAndFixTpaPageLinks(editorSDK);
  } catch (e) {
    log('Failed to fix TpaPageLinks in menu items (v2)', {
      extra: {
        error: e,
      },
    });
  }
};
