import type { AppManifest } from '@wix/platform-editor-sdk';
import type { TranslationFunction } from 'i18next';

import { MEMBERS_MENU_CONTROLLER_TYPE } from '../../../constants/layouts/menus/common-menu-layouts';
import { LOGIN_BAR_CONTROLLER_TYPE } from '../../../constants/layouts/login-bar/classic-login-bar-layouts';

// https://github.com/wix-private/js-platform-editor-sdk/blob/master/docs/articles/controller-stage-data-manifest.md
export const getControllersStageDataManifest = (t: TranslationFunction): AppManifest['controllersStageData'] => ({
  members: {
    default: {
      visibility: 'NEVER',
    },
  },
  [LOGIN_BAR_CONTROLLER_TYPE]: {
    default: {
      displayName: t('LoginBarAppWidget_Display_Name'),
    },
  },
  [MEMBERS_MENU_CONTROLLER_TYPE]: {
    default: {
      displayName: t('MembersMenuAppWidget_Display_Name'),
    },
  },
});
