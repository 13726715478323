import { EditorReadyOptions, EditorSDK, PageData } from '@wix/platform-editor-sdk';
import { MenuItem, TpaPageLink } from '@wix/document-services-types';
import { IntegrationApplication } from '@wix/members-area-app-definitions';
import { MEMBERS_AREA_V2 } from '@wix/app-definition-ids';

import {
  getMyAccountInstallDefinition,
  MENU_IDS,
  MY_ACCOUNT_APP_DEF_ID,
  MY_ACCOUNT_WIDGET_ID,
  MenuItemIdentifier,
} from '../../../constants';
import { getMembersAreaPage } from '../../../wrappers/pages';
import { getDataByAppDefId } from '../../../wrappers/tpa';
import * as menusWrapper from '../../../wrappers/menus';
import { updateMenuItems } from '../../../wrappers/menus';
import { shouldAddNotificationsIcon } from '../../../../utils/experiments';

const convertPageIdToLinkPageId = (pageId: string | undefined) => {
  if (!pageId) {
    return '';
  }

  return `#${pageId}`;
};

const createTpaPageLinkMenuItem = ({
  label,
  page,
  definition,
}: {
  label: string;
  page: PageData;
  definition: IntegrationApplication;
}): MenuItem => {
  if (!definition.urlOverride) {
    throw new Error(`MA V2: urlOverride is missing for ${definition.appDefinitionId}`);
  }

  const pageId = convertPageIdToLinkPageId(page.id);
  const link: TpaPageLink = {
    type: 'TpaPageLink',
    itemTypeIdentifier: MenuItemIdentifier.SubPage,
    itemId: definition.widgetId,
    pageId,
    appDefinitionId: MEMBERS_AREA_V2,
    path: `/${page.pageUriSEO}/my/${definition.urlOverride}`,
  };

  return {
    id: '',
    type: 'BasicMenuItem',
    label,
    link,
    isVisible: true,
    isVisibleMobile: true,
    items: [],
  };
};

const getDefinitionsByMenuId = (definitions: IntegrationApplication[], menuId: string) => {
  return menuId === MENU_IDS.SUB_MENU_ID
    ? definitions.filter((app) => app.showInMemberMenu)
    : definitions.filter((app) => app.showInLoginMenu);
};

const getAppsData = (editorSDK: EditorSDK, definitions: IntegrationApplication[]) => {
  return Promise.all(
    definitions.map((definition) => getDataByAppDefId({ editorSDK, appDefinitionId: definition.appDefinitionId })),
  );
};

const getMenuItems = async (editorSDK: EditorSDK, definitions: IntegrationApplication[]) => {
  const membersAreaPage = await getMembersAreaPage(editorSDK);

  const appsData = await getAppsData(editorSDK, definitions);

  return appsData.map((appData, index) => {
    const definition = definitions[index];

    if (!definition) {
      throw new Error('Page definition is missing when creating the login menu item');
    }

    // @ts-expect-error: Platform types are missing
    const appDataComponent = appData.components.find((comp) => comp.componentId === definition.widgetId);

    if (!appDataComponent) {
      throw new Error('App data for widget is missing when creating the login menu item');
    }

    return createTpaPageLinkMenuItem({ page: membersAreaPage, label: appDataComponent.name, definition });
  });
};

const addMenuItems = async (editorSDK: EditorSDK, definitions: IntegrationApplication[], menuId: string) => {
  const filteredDefinitions = getDefinitionsByMenuId(definitions, menuId);
  const menuItems = await getMenuItems(editorSDK, filteredDefinitions);

  return menusWrapper.addMenuItems({ editorSDK, menuId, items: menuItems });
};

// only Notifications app has an icon menu item
export const addIconsMenuItems = async (editorSDK: EditorSDK, definitions: IntegrationApplication[]) => {
  const isEnabled = await shouldAddNotificationsIcon();

  if (!isEnabled) {
    return;
  }

  const notificationsApp = definitions.find((app) => app.pageId === 'notifications_app');

  if (!notificationsApp) {
    return;
  }

  const [notificationsIcon] = await getMenuItems(editorSDK, [notificationsApp]);
  const menuItem = {
    ...notificationsIcon,
    iconRef: { svgId: '2c36dc006cb94853a49daee7e821f642.svg', type: 'VectorImage' },
  };

  return menusWrapper.addMenuItems({ editorSDK, menuId: MENU_IDS.LOGIN_ICONS_MENU_ID, items: [menuItem] });
};

const addMyAccountMenuItem = async (editorSDK: EditorSDK, options: EditorReadyOptions, menuId: string) => {
  const myAccountDefinition = getMyAccountInstallDefinition(options.origin);
  const membersAreaPage = await getMembersAreaPage(editorSDK);

  const myAccountTPA = await getDataByAppDefId({ editorSDK, appDefinitionId: MY_ACCOUNT_APP_DEF_ID });
  // @ts-expect-error: Platform types are missing, and we have no other source for apps dev center texts
  const myAccountWidget = await myAccountTPA.widgets[MY_ACCOUNT_WIDGET_ID];

  if (!myAccountWidget) {
    throw new Error('My Account widget is not found');
  }

  const menuItem = createTpaPageLinkMenuItem({
    page: membersAreaPage,
    label: myAccountWidget.appPage.name,
    definition: myAccountDefinition,
  });

  return updateMenuItems({ editorSDK, menuId, items: [menuItem] });
};

const addMyAccountLoginMenuItem = (editorSDK: EditorSDK, options: EditorReadyOptions) =>
  addMyAccountMenuItem(editorSDK, options, MENU_IDS.LOGIN_MENU_ID);

const addMyAccountSubMenuItem = (editorSDK: EditorSDK, options: EditorReadyOptions) =>
  addMyAccountMenuItem(editorSDK, options, MENU_IDS.SUB_MENU_ID);

const addLoginBarMenuItems = async (editorSDK: EditorSDK, definitions: IntegrationApplication[]) =>
  addMenuItems(editorSDK, definitions, MENU_IDS.LOGIN_MENU_ID);

const addLoginBarIconsItems = async (editorSDK: EditorSDK, definitions: IntegrationApplication[]) =>
  addIconsMenuItems(editorSDK, definitions);

const addMembersSubMenuItems = async (editorSDK: EditorSDK, definitions: IntegrationApplication[]) =>
  addMenuItems(editorSDK, definitions, MENU_IDS.SUB_MENU_ID);

export const addMenuItemsToMembersAreaMenus = async (editorSDK: EditorSDK, definitions: IntegrationApplication[]) => {
  return Promise.all([
    addLoginBarMenuItems(editorSDK, definitions),
    addLoginBarIconsItems(editorSDK, definitions),
    addMembersSubMenuItems(editorSDK, definitions),
  ]);
};

export const addMyAccountMenuItemToMembersAreaMenus = async (editorSDK: EditorSDK, options: EditorReadyOptions) =>
  Promise.all([addMyAccountLoginMenuItem(editorSDK, options), addMyAccountSubMenuItem(editorSDK, options)]);
