import { type EditorSDK, EventType } from '@wix/platform-editor-sdk';

import { shouldShowOnboardingModal } from '../../../../utils/experiments';
import { openWelcomePanelIfItWasNotShown } from '../../../wrappers/panels';
import { SANTA_MEMBERS_APP_ID } from '../../../constants';

export const addFocusedPageChangedListener = async (editorSDK: EditorSDK) => {
  return editorSDK.addEventListener(EventType.focusedPageChanged, async (event) => {
    const { pageRef } = event.detail;
    const { managingAppDefId } = await editorSDK.pages.getPageData('', { pageRef });

    if (managingAppDefId === SANTA_MEMBERS_APP_ID && (await shouldShowOnboardingModal())) {
      await openWelcomePanelIfItWasNotShown(editorSDK, pageRef);
    }
  });
};
