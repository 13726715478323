import type { EditorReadyFn, EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';

import { MEMBERS_AREA_V2 } from '@wix/app-definition-ids';

import { shouldEnableV3TpaPageLinksDataFixer } from '../../../utils/experiments';
import { log, monitoredTransactionFactory, toMonitored } from '../../../utils/monitoring';
import { APP_TOKEN } from '../../constants';
import { isApplicationInstalled } from '../../wrappers/application';
import { add as addHistory, HistoryLabel } from '../../wrappers/history';
import { registerToComponentAddedToStageEvent } from '../../wrappers/components';
import { startSequentialPromises, stopSequentialPromises } from '../../enforceSequentiality';
import { maybeFixBrokenMenusData } from '../../data-fixers/fixBrokenMenus';
import { fixTpaPageLinksIfCorrupted } from './data-fixers/fix-tpa-page-links';
import { removeGlobalControllerIfExists } from './services/global-controller';
import { setStateForPages } from './services/pages';
import { installMembersArea } from './editor-ready-utils/installation';
import { registerAlwaysAvailableApps } from './integration';
import { addEventsListeners } from './event-listener';

const shouldInstallMembersArea = async (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  if (options.firstInstall) {
    const isBobApplicationInstalled = await isApplicationInstalled(editorSDK, MEMBERS_AREA_V2);
    return !isBobApplicationInstalled;
  }

  return false;
};

const setSignUpPrivacyNoteType = async (editorSDK: EditorSDK) => {
  try {
    await editorSDK.siteMembers.setPrivacyNoteType(APP_TOKEN, 'NOTE');
  } catch {
    log('Failed to set privacyNoteType');
  }
};

export const editorReady: EditorReadyFn = async (editorSDK, _, options) => {
  const monitoredTransaction = monitoredTransactionFactory(editorSDK);

  const editorReadyAction = async () => {
    if (await shouldInstallMembersArea(editorSDK, options)) {
      await removeGlobalControllerIfExists(editorSDK);
      await monitoredTransaction('v3.install', () => installMembersArea(editorSDK, options));
      await setSignUpPrivacyNoteType(editorSDK);
      await addHistory(editorSDK, HistoryLabel.InstallV3);
    }

    setStateForPages(editorSDK);
    await registerToComponentAddedToStageEvent(editorSDK);
    await registerAlwaysAvailableApps(editorSDK);
    await addEventsListeners(editorSDK);

    // Try to fix broken menus data
    // TODO: merge data fixers - https://jira.wixpress.com/browse/MA-3358
    await maybeFixBrokenMenusData(editorSDK);

    if (await shouldEnableV3TpaPageLinksDataFixer()) {
      await fixTpaPageLinksIfCorrupted(editorSDK);
    }

    startSequentialPromises();
  };

  try {
    await toMonitored('v3.editorReady', editorReadyAction);
  } catch (error: any) {
    console.error('Members Area V3 editorReady failed');
    console.error(error);
    stopSequentialPromises(error);

    throw error;
  }
};
