import { EditorSDK } from '@wix/platform-editor-sdk';
import { MembersAreaContext } from '../../types/context';
import { getMembersAreaContext } from '../services/context';
import { createPublicAPI as createCurrentMAPublicAPI } from './current-ma';
import { createPublicAPI as createMAOnMSBPublicAPI } from './ma-on-msb';
import { createPublicAPI as createSplitMAPublicAPI } from './split-ma';
import { MembersAreaPublicApi } from './types';

type PublicAPIFactory = (editorSDK: EditorSDK) => MembersAreaPublicApi;

const publicAPIFactoryMap: { [key in MembersAreaContext]: PublicAPIFactory } = {
  [MembersAreaContext.V1]: createCurrentMAPublicAPI,
  [MembersAreaContext.V2]: createMAOnMSBPublicAPI,
  [MembersAreaContext.V3]: createSplitMAPublicAPI,
};

const initPublicAPI = async (editorSDK: EditorSDK) => {
  const membersAreaContext = await getMembersAreaContext(editorSDK);
  const publicAPIFactory = publicAPIFactoryMap[membersAreaContext];

  return publicAPIFactory(editorSDK);
};

export const createPublicAPI = (editorSDK: EditorSDK): MembersAreaPublicApi => {
  const getPublicAPI = () => initPublicAPI(editorSDK);

  return {
    getRegisteredApps: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getRegisteredApps();
    },
    registerMembersAreaApps: async (applications, verticalAppDefId, applicationsOptions) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.registerMembersAreaApps(applications, verticalAppDefId, applicationsOptions);
    },
    installRegisteredApps: async (verticalAppDefId, options) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.installRegisteredApps(verticalAppDefId, options);
    },
    addApplications: async (applications, shouldNavigate, options) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.addApplications(applications, shouldNavigate, options);
    },
    openGeneralSettingsPanel: async (referralInfo) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.openGeneralSettingsPanel(referralInfo);
    },
    getGeneralSettingsPanelData: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getGeneralSettingsPanelData();
    },
    getMemberPrivacySettings: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getMemberPrivacySettings();
    },
    modifyPages: async (configurationDetails) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.modifyPages(configurationDetails);
    },
    modifyPagesAndOpenManagePages: async (configurationDetails, referralInfo) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.modifyPagesAndOpenManagePages(configurationDetails, referralInfo);
    },
    getMembersPageRef: async (page) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getMembersPageRef(page);
    },
    removeMembersAreaPage: async (pageId, appDefinitionId) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.removeMembersAreaPage(pageId, appDefinitionId);
    },
    setHorizontalLayout: async (pwHeight) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.setHorizontalLayout(pwHeight);
    },
    setSidebarLayout: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.setSidebarLayout();
    },
    _getIsResponsiveEditor: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI._getIsResponsiveEditor();
    },
    handleVerticalDeletion: async (verticalAppDefId) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.handleVerticalDeletion(verticalAppDefId);
    },
    addCustomPage: async (isPrivate) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.addCustomPage(isPrivate);
    },
    refreshPageState: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.refreshPageState();
    },
    getProfileType: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getProfileType();
    },
    setProfileType: async (profileType) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.setProfileType(profileType);
    },
    refreshRouters: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.refreshRouters();
    },
    refreshMembersAreaApps: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.refreshMembersAreaApps();
    },
    setProfileWidgetHeight: async (height: number) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.setProfileWidgetHeight(height);
    },
    registerAdditionalWidgets: () => {},
    getAdditionalWidgets: () => {},
    installAdditionalWidgets: () => {},
    openAddTabsPanel: async (props) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.openAddTabsPanel(props);
    },
    getIsMembersAreaV2Context: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getIsMembersAreaV2Context();
    },
    getMembersAreaContext: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getMembersAreaContext();
    },
    isAppSectionInstalled: async (sectionData) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.isAppSectionInstalled(sectionData);
    },
    navigateToSection: async (section) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.navigateToSection(section);
    },
    getRoutes: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getRoutes();
    },
    setRoutes: async (routes) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.setRoutes(routes);
    },
    getSettingsRoutes: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getSettingsRoutes();
    },
    setSettingsRoutes: async (routes) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.setSettingsRoutes(routes);
    },
    openUnifiedManagerPanel: async (props) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.openUnifiedManagerPanel(props);
    },
  };
};
