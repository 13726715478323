import { EditorSDK } from '@wix/platform-editor-sdk';

import { openAddTabsPanel, OpenAddTabsPanelPayload } from '../../../wrappers/panels';
import { getMembersAreaPage } from '../../../wrappers/pages';
import { APP_TOKEN } from '../../../constants';

export const handleAddTabs = async (editorSDK: EditorSDK, eventPayload: OpenAddTabsPanelPayload = {}) => {
  const membersAreaPage = await getMembersAreaPage(editorSDK);
  if (membersAreaPage) {
    await editorSDK.pages.navigateTo(APP_TOKEN, { pageRef: { id: membersAreaPage.id!, type: 'DESKTOP' } });
  }
  return openAddTabsPanel({ editorSDK, eventPayload });
};
