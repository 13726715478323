import { IntegrationApplication, MA_APP_IDS } from '@wix/members-area-integration-kit';
import {
  DynamicPageLink,
  EditorSDK,
  PageData,
  PageRef,
  RouterData,
  TPAComponentType,
  TPAComponentTypeStrings,
} from '@wix/platform-editor-sdk';

import * as tpa from '../wrappers/tpa';
import * as constants from '../constants';
import * as pagesService from '../services/pages';
import * as menusService from '../services/menus';
import { log } from '../../utils/monitoring';
import { getIsResponsiveEditor } from '../services/applicationState';
import * as routersWrapper from '../wrappers/routers';
import * as applicationWrapper from '../wrappers/application';
import * as componentsWrapper from '../wrappers/components';
import * as menusWrapper from '../wrappers/menus';
import * as pagesWrapper from '../wrappers/pages';
import * as routersService from '../services/routers';
import * as layoutsService from '../services/layouts';
import { createBIService } from '../../utils/bi';
import { allSettled } from '../../utils/promises';
import { getUniqueApplications } from '../../utils/pages';
import { registerAlwaysAvailableApps } from '../services/integration';
import { MembersPage } from '../../types/EditorAppModule';
import { BiData } from '../../types/bi';
import { withTimeoutMonitor } from '../../utils/promise-timeout';
import { IntegrationApplicationWithoutWidgetId } from '../../types/general-settings';

const ADD_APP_TIMEOUT_AMOUNT = 15000;

type AddComponentOptions = {
  x: number;
  y: number;
  width: number;
  height: number;
  method: string | undefined;
  appDefinitionId: string;
  managingAppDefId: string;
  componentType: TPAComponentTypeStrings;
  shouldNavigate: boolean;
  page: { pageId: string; requireLogin: boolean; shouldNavigate: boolean; showInLoginMenu: boolean };
  biData: BiData;
};

type AddApplicationOptions = {
  showPageAddedPanel?: boolean;
  disableAddPanel?: boolean;
  shouldNavigate?: boolean;
  appDefinitionId: string;
  managingAppDefId?: string;
  isSilent?: boolean;
};

type PartialAddApplicationResponse = { instanceId: string };

export type FullAddApplicationResponse = { instanceId: string; pageRef: PageRef; pageUriSEO: string; title: string };

type AddApplicationResponse = PartialAddApplicationResponse | FullAddApplicationResponse;

type AddComponentResponse =
  | { compId: string }
  | { compId: string; pageRef: PageRef; pageUriSEO: string; title: string };

export type AddApplicationOrComponentResponse = AddApplicationResponse | AddComponentResponse;

const filterNotInstalledApplications = async ({
  editorSDK,
  applications,
}: {
  editorSDK: EditorSDK;
  applications: IntegrationApplication[];
}) => {
  const promises = applications.map((app) => {
    const { appDefinitionId, pageId } = app;

    if (!pageId) {
      return Promise.resolve(app);
    }

    return tpa
      .isAppSectionInstalled({ editorSDK, appDefinitionId, sectionId: pageId })
      .then((isInstalled) => (isInstalled ? false : app));
  });

  const notInstalledApps = await Promise.all(promises);
  return notInstalledApps.filter((app) => !!app) as IntegrationApplication[];
};

export const installSiteApplications = async ({
  editorSDK,
  applications,
  shouldNavigate,
}: {
  editorSDK: EditorSDK;
  applications: AddApplicationOptions[];
  shouldNavigate: boolean;
}) => {
  const notInstalledAppsPromises = applications.map((app) =>
    tpa
      .isApplicationInstalled({ editorSDK, appDefinitionId: app.appDefinitionId })
      .then((isInstalled) => (isInstalled ? false : app)),
  );
  const notInstalledApps = await Promise.all(notInstalledAppsPromises).then(
    (apps) => apps.filter(Boolean) as AddApplicationOptions[],
  );

  const installAppPromises = notInstalledApps.map(({ appDefinitionId }) => {
    return tpa.addApplication(editorSDK, { appDefinitionId, shouldNavigate });
  });

  return allSettled<AddApplicationResponse[]>(installAppPromises);
};

const addApplicationsOrComponents = async ({
  editorSDK,
  applications,
  shouldNavigate,
}: {
  editorSDK: EditorSDK;
  applications: AddComponentOptions[];
  shouldNavigate: boolean;
}) => {
  const biService = await createBIService({ editorSDK });

  const addApplicationOrComponent = async (definition: AddComponentOptions) => {
    const addingMethod = definition.method;
    const isApplication = addingMethod === 'addApplication';
    delete definition.method;

    const addPromise = async () => {
      // TODO: remove when uninstall is handled gracefully with all MA apps (currently DM's 'addApps' hang if you try to reinstall active app)
      const isApplicationActive = await applicationWrapper.isApplicationInstalled(
        editorSDK,
        definition.appDefinitionId,
      );

      return isApplication && !isApplicationActive
        ? tpa.addApplication(editorSDK, definition)
        : tpa.addComponent(editorSDK, definition);
    };

    const timeoutMonitorName = isApplication ? 'sdk.tpa.add.application' : 'sdk.tpa.add.component';
    const sentryPayload = {
      extra: {
        definition,
      },
      tags: {
        pageId: definition.page.pageId,
      },
    };

    const addResponse = await withTimeoutMonitor(timeoutMonitorName, ADD_APP_TIMEOUT_AMOUNT, addPromise, sentryPayload);

    return addResponse;
  };

  const navigateToAddedApps = (addedApps: AddApplicationOrComponentResponse[]) => {
    const pageRef = (addedApps[0] as FullAddApplicationResponse)?.pageRef;
    pagesWrapper.navigateToPageRef({ editorSDK, pageRef });

    return addedApps;
  };

  const verifyAddedApplicationsOrComponents = (addedApps: AddApplicationOrComponentResponse[]) => {
    const verifyPromises = applications.map((definition) =>
      tpa
        .isAppSectionInstalled({
          editorSDK,
          appDefinitionId: definition.appDefinitionId,
          sectionId: definition.page.pageId,
        })
        .then((isInstalled) => {
          if (!isInstalled) {
            const tags = { pageId: definition.page.pageId, appDefinitionId: definition.appDefinitionId };
            log('App installation failed verification just after the successful installation', { tags });
          } else {
            biService.maPageInstalled({ originAppId: definition.appDefinitionId, pageName: definition.page.pageId });
          }
        }),
    );

    return Promise.all(verifyPromises).then(() => (shouldNavigate ? navigateToAddedApps(addedApps) : addedApps));
  };

  const addedApplicationsOrComponents = await Promise.all(
    applications.map((definition) => addApplicationOrComponent(definition)),
  );

  return Promise.resolve(addedApplicationsOrComponents).then(verifyAddedApplicationsOrComponents);
};

const createApplicationsDefinitions = ({
  applications,
  isHorizontalLayout,
}: {
  applications: (IntegrationApplication & { biData: BiData })[];
  isHorizontalLayout: boolean;
}) =>
  applications.map((app) => ({
    method: app.method,
    appDefinitionId: app.appDefinitionId,
    managingAppDefId: constants.SANTA_MEMBERS_APP_ID,
    componentType: 'PAGE' as TPAComponentType,
    shouldNavigate: !!app.shouldNavigate,
    page: {
      pageId: app.pageId,
      requireLogin: !app.social,
      shouldNavigate: !!app.shouldNavigate,
      showInLoginMenu: !!app.showInLoginMenu,
    },
    biData: app.biData,
    ...(isHorizontalLayout ? constants.SECTION_DEFAULT_LAYOUT_HORIZONTAL : constants.SECTION_DEFAULT_LAYOUT),
  }));

const getConnectionConfigUrls = ({
  page,
  app,
  routers,
}: {
  page: PageData;
  app: IntegrationApplication;
  routers: { publicRouter: RouterData; privateRouter: RouterData };
}) => {
  const { publicRouter, privateRouter } = routers;
  const router = app.social ? publicRouter : privateRouter;
  const allRoutes = (router.config && router.config.patterns && Object.keys(router.config.patterns)) || [];
  const urlOverride = app.urlOverride && routersWrapper.createNewPageRoute(allRoutes, app.urlOverride);
  const pageUriSEO = page && page.pageUriSEO && routersWrapper.createNewPageRoute(allRoutes, page.pageUriSEO);

  return { pageUriSEO, urlOverride };
};

const createConnectionConfigs = ({
  applications,
  pages,
  routers,
}: {
  applications: IntegrationApplication[] | IntegrationApplicationWithoutWidgetId[];
  pages: PageData[];
  routers: { publicRouter: RouterData; privateRouter: RouterData };
}) =>
  applications.map<MembersPage>((app, index) => {
    const isPrivate = !app.social;
    const page = pages[index];
    const isNotifications = app.pageId === 'notifications_app';
    const { urlOverride, pageUriSEO } = getConnectionConfigUrls({ page, app, routers });

    const routerConfig = {
      socialHome: !!app.socialHome,
      pageId: page.id!,
      appData: {
        numbers: app.numbers,
        appDefinitionId: app.appDefinitionId,
        appPageId: app.pageId,
        menuOrder: app.menuOrder || constants.DEFAULT_MENU_ORDER,
        visibleForRoles: app.visibleForRoles || [],
      },
    };

    return {
      routerConfig,
      urlOverride,
      pageData: {
        isPrivate,
        ...page,
        pageUriSEO,
      },
      showInLoginMenu: app.showInLoginMenu,
      showInMemberMenu: app.showInMemberMenu,
      appDefinitionId: app.appDefinitionId,
      loginMenuTitle: app.loginMenuTitle,
      showInIconsMenu: isNotifications,
      menuIds: menusService.getMembersAreaMenuIds(),
    };
  });

const installMembersAreaApplications = async ({
  editorSDK,
  applications,
  forceHorizontalLayout,
  shouldNavigate,
}: {
  editorSDK: EditorSDK;
  applications: (IntegrationApplication & { biData: BiData })[];
  forceHorizontalLayout: boolean;
  shouldNavigate: boolean;
}) => {
  const isHorizontalLayout =
    getIsResponsiveEditor() ||
    forceHorizontalLayout ||
    (await layoutsService.isMyAccountLayoutHorizontal({ editorSDK }));

  const applicationsDefinitions = createApplicationsDefinitions({ applications, isHorizontalLayout });
  const createdPages = await addApplicationsOrComponents({
    editorSDK,
    applications: applicationsDefinitions,
    shouldNavigate,
  });
  const routers = (await routersService.getMembersAreaRouters(editorSDK)) || { publicRouter: {}, privateRouter: {} };
  // @ts-expect-error - not typed properly, previously createdPages was any, that's why it was not caught
  const connectionConfigs = createConnectionConfigs({ applications, pages: createdPages, routers });
  await pagesService.connectPagesToMembers({ editorSDK, pages: connectionConfigs });

  if (!isHorizontalLayout) {
    await componentsWrapper.fixSOSPHeightForVerticalLayout({ editorSDK });
  }
  await pagesService.setStateForPages(editorSDK);
  await registerAlwaysAvailableApps(editorSDK);

  // MA-260 investigation, check if all added pages are actually in the router
  await confirmPagesInRouters(editorSDK, applications);
};

const maybeConfirmMyWishlistInstallation = async (editorSDK: EditorSDK, applications: IntegrationApplication[]) => {
  try {
    if (applications.length === 1 && applications[0].pageId === MA_APP_IDS.MY_WISHLIST.pageId) {
      const menuItems = await menusWrapper.getMenuItems({ editorSDK, menuId: constants.MENU_IDS.LOGIN_MENU_ID });
      const myWishlistMenuItem = menuItems.find((i) => (i.link as DynamicPageLink).innerRoute === 'my-wishlist');

      if (!myWishlistMenuItem) {
        log('My Wishlist menu item did not add to login menu when supposed to');
      }
    }
  } catch (e) {
    log('Failed to confirm My Wishlist installation', { extra: { e } });
  }
};

const confirmPagesInRouters = async (editorSDK: EditorSDK, applications: IntegrationApplication[]) => {
  try {
    const routers = await routersService.getMembersAreaRouters(editorSDK);
    applications.forEach((app) => {
      const router = app.social ? routers.publicRouter : routers.privateRouter;
      const appInRouter = routersService.getRouterPatternDataByPageId(router, app.pageId);

      if (!appInRouter) {
        log('Application was added but is not found in the router, it will probably be disconnected from MA', {
          tags: { appPageId: app.pageId },
          extra: { appPageId: app.pageId, applications, router },
        });
      }
    });
  } catch (e) {
    log('Failed to confirm applications installation', { extra: { e } });
  }
};

const withBiData = (applications: IntegrationApplication[], biData: BiData) =>
  applications.map((app) => ({
    ...app,
    biData,
  }));

const addApplications = async ({
  editorSDK,
  applications,
  biData = {},
  forceHorizontalLayout = false,
  shouldNavigate = false,
}: {
  editorSDK: EditorSDK;
  applications: IntegrationApplication[];
  biData?: BiData;
  forceHorizontalLayout?: boolean;
  shouldNavigate?: boolean;
}) => {
  const siteApps = applications.filter((app) => app.method === 'addApplicationToSite');
  const membersAreaApps = getUniqueApplications(applications.filter((app) => app.method !== 'addApplicationToSite'));
  const filteredMembersAreaApps = await filterNotInstalledApplications({ editorSDK, applications: membersAreaApps });

  await Promise.all(
    [
      siteApps.length > 0 && installSiteApplications({ editorSDK, applications: siteApps, shouldNavigate }),
      filteredMembersAreaApps.length > 0 &&
        installMembersAreaApplications({
          editorSDK,
          applications: withBiData(filteredMembersAreaApps, biData),
          forceHorizontalLayout,
          shouldNavigate,
        }),
    ].filter((p) => !!p),
  );

  // EE-27682 investigation, check if menu items are properly added
  await maybeConfirmMyWishlistInstallation(editorSDK, applications);
};

export { addApplications, createConnectionConfigs, createApplicationsDefinitions };
