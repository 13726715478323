import { AppActionClicked as AppActionClickedEvent, EventType } from '@wix/platform-editor-sdk';

import { ReferralInfo } from '../../../../types/bi';
import { openRemovePagePanel, openUninstallPanel } from '../../../wrappers/platformPanels';
import {
  openGeneralSettingsPanel,
  openMemberPrivacySettingsDashboard,
  openUnifiedManagerPanel,
} from '../../../wrappers/panels';
import { MembersAreaEvent, MembersAreaOnEventFn } from '../../types';
import { publishSettingsForMembersAreaApps } from '../../../services/members-area';
import { navigateToMembersAreaPage } from '../services/navigation';
import { handleLoginBarAdded } from '../services/login-bar';
import { handleAppManagerEvent } from './apps-manager';
import { handleAddTabs } from './add-tabs';
import { handleCreateBlankPageEvent } from './custom-pages';

export const onEvent: MembersAreaOnEventFn = async (event, editorSDK) => {
  const { eventType, eventPayload } = event;

  switch (eventType) {
    // Platform Events
    case EventType.appActionClicked:
      return handleAppManagerEvent(editorSDK, event as AppActionClickedEvent);
    case EventType.widgetAdded:
      return handleLoginBarAdded(editorSDK, eventPayload);
    case EventType.siteWasPublished:
      return publishSettingsForMembersAreaApps(editorSDK);
    // Members Area Events
    case MembersAreaEvent.CreateBlankPage:
      return handleCreateBlankPageEvent(editorSDK);
    case MembersAreaEvent.ManagePages:
      return handleAddTabs(editorSDK, eventPayload);
    case MembersAreaEvent.RemovePage:
      return openRemovePagePanel(editorSDK, eventPayload.pageRef);
    case MembersAreaEvent.Uninstall:
      return openUninstallPanel(editorSDK);
    case MembersAreaEvent.GeneralSettings:
      await navigateToMembersAreaPage(editorSDK);
      return openGeneralSettingsPanel({
        editorSDK,
        eventPayload: { ...eventPayload, referralInfo: ReferralInfo.ActionsMenu },
      });
    case MembersAreaEvent.MemberPrivacyDashboard:
      return openMemberPrivacySettingsDashboard(editorSDK);
    case MembersAreaEvent.OpenUnifiedManager:
      return openUnifiedManagerPanel({ editorSDK, eventPayload: { initialTab: 'pages' } });
  }
};
