import { InstallLocation, IntegrationApplication } from '@wix/members-area-app-definitions';

import type { IntegrationApplicationWithoutWidgetId } from '../../../../types/general-settings';

export const groupDefinitionsByMethod = (
  definitions: IntegrationApplication[] | IntegrationApplicationWithoutWidgetId[],
) => {
  const definitionsGroupedByMethod = {
    profileWidgetPlugins: [] as IntegrationApplication[],
    settingsWidgetPlugins: [] as IntegrationApplication[],
    standalonePrivatePages: [] as IntegrationApplication[],
    lightboxes: [] as IntegrationApplication[],
    siteApps: [] as IntegrationApplicationWithoutWidgetId[],
  };

  definitions.forEach((definition) => {
    if (definition.method === 'addApplicationToSite') {
      return definitionsGroupedByMethod.siteApps.push(definition);
    }

    switch (definition.installLocation) {
      case InstallLocation.Settings: {
        return definitionsGroupedByMethod.settingsWidgetPlugins.push(definition as IntegrationApplication);
      }
      case InstallLocation.Standalone: {
        return definitionsGroupedByMethod.standalonePrivatePages.push(definition as IntegrationApplication);
      }
      case InstallLocation.Profile: {
        return definitionsGroupedByMethod.profileWidgetPlugins.push(definition as IntegrationApplication);
      }
      case InstallLocation.Lightbox: {
        return definitionsGroupedByMethod.lightboxes.push(definition as IntegrationApplication);
      }
      default: {
        console.warn(`[SplitMA] Unhandled install location ${definition.installLocation}`);
      }
    }
  });

  return definitionsGroupedByMethod;
};
