import type { EditorSDK } from '@wix/platform-editor-sdk';

import type { IntegrationApplicationWithoutWidgetId } from '../../../../types/general-settings';

import { installSiteApplications as addSiteApplications } from '../../../platform-api/addApplications';
import { getPagesRefsFromAppOrComponentResponse, setPagesToNonIndexable } from '../../../wrappers/pages';

export const installSiteApplications = async (
  editorSDK: EditorSDK,
  definitions: IntegrationApplicationWithoutWidgetId[],
) => {
  const sitePagesCreated = await addSiteApplications({
    editorSDK,
    applications: definitions,
    shouldNavigate: false,
  });

  const pagesRefs = getPagesRefsFromAppOrComponentResponse(sitePagesCreated);

  if (pagesRefs.length > 0) {
    setPagesToNonIndexable(editorSDK, pagesRefs);
  }

  return sitePagesCreated;
};
