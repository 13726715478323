import { instanceValue } from '@wix/wix-instance-parser-js';
import { EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';
import webBiLogger from '@wix/web-bi-logger';
import type { Logger } from '@wix/web-bi-logger/dist/src/types';
import {
  addPageButtonClickMembersPages,
  loadMembersCustomPages,
  managePagesActionDelete,
  managePagesAddMemberPagesClick,
  managesPagesModalTabNavigation,
  maPageInstalled,
  memberPagesSettingsAction,
  memberPagesSettingsOpen,
  memberPagesSettingsSaveConfirm,
  membersAreaInstallationError,
  membersProfileSelection,
  menuToggle,
  verticalTriggeredMaInstallInitiated,
  verticalTriggeredMaInstallSuccess,
} from '@wix/bi-logger-members-app-users/v2';
import type {
  addPageButtonClickMembersPagesParams,
  loadMembersCustomPagesParams,
  maPageInstalledParams,
  memberPagesSettingsActionParams,
  membersProfileSelectionParams,
  menuToggleParams,
  verticalTriggeredMaInstallInitiatedParams,
  verticalTriggeredMaInstallSuccessParams,
} from '@wix/bi-logger-members-app-users/v2/types';
import { maOnboardingModalClick, maOnboardingModalShown } from '@wix/bi-logger-members-data/v2';
import { MA_APP_IDS } from '@wix/members-area-app-definitions';
import type { maOnboardingModalClickParams } from '@wix/bi-logger-members-data/v2/types';

import { SANTA_MEMBERS_APP_ID } from '../editor-app-module/constants';
import { Context, GeneralSettingsAction, Origin, ReferralInfo } from '../types/bi';
import { ProfileType } from '../types/blog-writer';
import { PageToModify } from '../types/general-settings';
import type { Page } from '../panels/generalSettings/types';
import { ConfirmationPanelType } from '../panels/generalSettings/utils';
import { arePagesEqual } from './pages';
import { log } from './monitoring';

const APP_DEF_TO_BI_VALUE: Record<string, GeneralSettingsAction> = {
  [MA_APP_IDS.ABOUT.appDefinitionId]: GeneralSettingsAction.Profile,
  [MA_APP_IDS.FOLLOWERS.appDefinitionId]: GeneralSettingsAction.Followers,
  [MA_APP_IDS.ALL_MEMBERS.appDefinitionId]: GeneralSettingsAction.Members,
};

type Modifications = {
  appsToInstall: PageToModify[];
  appsToRemove: PageToModify[];
  profilePageType: ProfileType.BWP_ONLY | ProfileType.BWP_ALL | null;
};

const createBIService = async ({
  editorSDK,
  withClientId,
  options,
}: {
  editorSDK: EditorSDK;
  withClientId?: boolean;
  options?: EditorReadyOptions;
}) => {
  const biService: BIService = new BIService();
  const originInfo = options?.origin.info?.type as string;
  await biService.init(editorSDK, withClientId, originInfo);
  return biService;
};

interface BaseProps {
  instance_id: string;
  app_id?: string;
  biToken?: string;
  client_id?: string;
  originInfo?: string;
  msid?: string;
}

class BIService {
  logger: Logger;
  baseProps: BaseProps = { instance_id: '', msid: '' };

  constructor() {
    this.logger = webBiLogger.factory().logger();
  }

  init = async (editorSDK: EditorSDK, withClientId: boolean = true, originInfo: string) => {
    const instance = await editorSDK.info.getAppInstance('');

    if (!instance) {
      log('missing instance in platform however the editor script will proceed');
    }

    const msid = await editorSDK.info.getMetaSiteId('');
    const instanceId = instance ? instanceValue.getValueFromInstance('instanceId', instance) ?? '' : '';
    const biToken = instance ? instanceValue.getValueFromInstance('biToken', instance) : undefined;
    this.baseProps = { app_id: SANTA_MEMBERS_APP_ID, instance_id: instanceId, biToken, originInfo, msid };
    if (withClientId) {
      this.baseProps.client_id = instance ? instanceValue.getValueFromInstance('uid', instance) : undefined;
    }
  };

  logMembersMenuToggle = ({ pageId, pageName, toggle_status, is_social }) => {
    const toggleName = 'Members Menu';
    const props = { ...this.baseProps, pageId, pageName, toggleName, toggle_status, is_social };
    return this.logger.report(menuToggle(props));
  };

  logLoginMenuToggle = ({ pageId, pageName, toggle_status, is_social }: Partial<menuToggleParams>) => {
    const toggleName = 'Login Menu';
    const props = { ...this.baseProps, pageId, pageName, toggleName, toggle_status, is_social };
    return this.logger.report(menuToggle(props));
  };

  logInstallationFailure = (errorMsg: string) => {
    const props = { ...this.baseProps, errorMsg, appInstanceId: this.baseProps.app_id };
    delete props.app_id;
    return this.logger.report(membersAreaInstallationError(props));
  };

  addPageButtonClick = ({
    origin,
    pageName,
    pageId,
    appId,
    context,
  }: Partial<addPageButtonClickMembersPagesParams>) => {
    return this.logger.report(
      addPageButtonClickMembersPages({ ...this.baseProps, origin, pageName, pageId, appId, context }),
    );
  };

  loadMembersCustomPages = ({ pageName, pageId, appId, context }: Partial<loadMembersCustomPagesParams>) => {
    return this.logger.report(loadMembersCustomPages({ ...this.baseProps, pageName, pageId, appId, context }));
  };

  verticalTriggeredMaInstallInitiated = ({ originAppId }: Partial<verticalTriggeredMaInstallInitiatedParams>) => {
    return this.logger.report(verticalTriggeredMaInstallInitiated({ ...this.baseProps, originAppId }));
  };

  verticalTriggeredMaInstallSuccess = ({ originAppId }: Partial<verticalTriggeredMaInstallSuccessParams>) => {
    return this.logger.report(verticalTriggeredMaInstallSuccess({ ...this.baseProps, originAppId }));
  };

  maPageInstalled = ({ originAppId, pageName }: Partial<maPageInstalledParams>) => {
    return this.logger.report(maPageInstalled({ ...this.baseProps, originAppId, pageName }));
  };

  managePagesActionDelete = ({ pageId, pageName, value, appId }) => {
    return this.logger.report(managePagesActionDelete({ ...this.baseProps, pageId, pageName, value, appId }));
  };

  managePagesAddMemberPagesClick = ({
    origin,
    context,
    referralInfo,
  }: {
    origin: Origin;
    context: Context;
    referralInfo?: ReferralInfo;
  }) => {
    return this.logger.report(managePagesAddMemberPagesClick({ ...this.baseProps, origin, referralInfo, context }));
  };

  managesPagesModalTabNavigation = ({ tabName }) => {
    return this.logger.report(
      managesPagesModalTabNavigation({ ...this.baseProps, tabName, context: Context.ManageMemberPages }),
    );
  };

  membersProfileSelection = ({ value }: Partial<membersProfileSelectionParams>) => {
    return this.logger.report(membersProfileSelection({ ...this.baseProps, value }));
  };

  maOnboardingModalShown = () => {
    return this.logger.report(
      maOnboardingModalShown({
        ...this.baseProps,
      }),
    );
  };

  maOnboardingModalClick = ({ modal_action, modal_page }: maOnboardingModalClickParams) => {
    return this.logger.report(
      maOnboardingModalClick({
        ...this.baseProps,
        modal_action,
        modal_page,
      }),
    );
  };

  memberPagesSettingsOpen = (referralInfo: ReferralInfo) => {
    return this.logger.report(memberPagesSettingsOpen({ ...this.baseProps, referralinfo: referralInfo }));
  };

  togglePublicPageAvailability = (isEnabled: boolean) => {
    const value = isEnabled ? GeneralSettingsAction.ShowPublicOn : GeneralSettingsAction.ShowPublicOff;
    return this.memberPagesSettingsAction({ value });
  };

  onProfilePageTypeChange = (profileType: ProfileType.BWP_ONLY | ProfileType.BWP_ALL) => {
    const value =
      profileType === ProfileType.BWP_ONLY
        ? GeneralSettingsAction.ProfileBlogWriters
        : GeneralSettingsAction.ProfileAll;
    return this.memberPagesSettingsAction({ value });
  };

  onFindMorePagesClick = () => {
    return this.memberPagesSettingsAction({ value: GeneralSettingsAction.FindMorePagesCTA });
  };

  onBackToOverviewClick = () => {
    return this.memberPagesSettingsAction({ value: GeneralSettingsAction.BackToOverviewCTA });
  };

  onPageToggle = (appDefinitionId: string) => {
    return this.memberPagesSettingsAction({ value: APP_DEF_TO_BI_VALUE[appDefinitionId] });
  };

  memberPagesSettingsSaveConfirm = (
    modifications: Modifications,
    pages: Page[],
    showPublicPagesToggleEnabled: boolean,
  ) => {
    if (!showPublicPagesToggleEnabled) {
      return this.pagesSettingsSaveConfirm({ selection: undefined });
    }

    const { appsToRemove, appsToInstall } = modifications;
    const selection = pages
      .filter((page) =>
        page.isInstalled
          ? !appsToRemove.some(({ integrationApplication }) => {
              return arePagesEqual(integrationApplication, page.integrationApplication);
            })
          : appsToInstall.some(({ integrationApplication }) => {
              return arePagesEqual(integrationApplication, page.integrationApplication);
            }),
      )
      .map(({ integrationApplication }) => APP_DEF_TO_BI_VALUE[integrationApplication.appDefinitionId])
      .toString();

    return this.pagesSettingsSaveConfirm({ selection });
  };

  pagesSettingsSaveConfirm = ({ selection, modalName }: { selection?: string; modalName?: ConfirmationPanelType }) => {
    return this.logger.report(
      memberPagesSettingsSaveConfirm({ ...this.baseProps, origin: Origin.Editor, selection, modalName }),
    );
  };

  private readonly memberPagesSettingsAction = ({ value }: Omit<memberPagesSettingsActionParams, 'instance_id'>) => {
    return this.logger.report(memberPagesSettingsAction({ ...this.baseProps, value, origin: Origin.Editor }));
  };
}

export { createBIService, BIService };
