import { EditorSDK } from '@wix/platform-editor-sdk';

import { RouteConfiguration } from '../../../../types/controllers';
import { log } from '../../../../utils/monitoring';
import { APP_TOKEN } from '../../../constants';
import { createController, getGlobalControllerRef, isGlobalControllerInstalled } from '../../../wrappers/controllers';
import { updateGlobalController } from '../../../services/controllers';

export const removeGlobalControllerIfExists = async (editorSDK: EditorSDK) => {
  try {
    const controllerRef = await getGlobalControllerRef(editorSDK);

    if (controllerRef) {
      log('[MA V2] removing global controller');
      await editorSDK.components.remove(APP_TOKEN, { componentRef: controllerRef });
    }
  } catch (e) {
    log('[MA V2] Error occurred in removeGlobalControllerIfExists', { extra: { error: JSON.stringify(e) } });
  }
};

export const addGlobalController = async (editorSDK: EditorSDK) => {
  const masterRef = await editorSDK.siteSegments.getSiteStructure(APP_TOKEN);

  if (!masterRef) {
    return;
  }

  const controllerRef = await createController(editorSDK, masterRef);

  await updateGlobalController({
    editorSDK,
    controllerRef,
    newConfig: { isMembersAreaV2: true, isMembersAreaSeoEnabled: true },
  });
};

export const fixGlobalControllerIfMissing = async (editorSDK: EditorSDK) => {
  if (await isGlobalControllerInstalled(editorSDK)) {
    return;
  }

  return addGlobalController(editorSDK);
};

export const getRoutesFromGlobalController = async (editorSDK: EditorSDK): Promise<RouteConfiguration[]> => {
  const controllerRef = await getGlobalControllerRef(editorSDK);
  if (!controllerRef) {
    return [];
  }

  const data = await editorSDK.controllers.getData(APP_TOKEN, {
    controllerRef,
    scope: 'APP',
  });

  return data?.config?.routes || [];
};
