import { EditorReadyOptions, EditorSDK, Monitoring } from '@wix/platform-editor-sdk';

import { APP_TOKEN } from '../editor-app-module/constants';
import { runAndWaitForApproval } from '../editor-app-module/wrappers/transactions';
import { getMembersAreaContext } from '../editor-app-module/services/context';
import { MembersAreaContext } from '../types/context';

const SENTRY_DSN = 'https://c661415425e4445894c14bd5ebcc8b66@sentry-next.wixpress.com/7857';

let fedopsInstance: ReturnType<Monitoring['createFedopsLogger']> | undefined;
let sentryMonitor: ReturnType<Monitoring['createSentryMonitorForApp']> | undefined;
let defaultMonitoringParams: DefaultMonitoringParams | undefined;

type SentryTags = {
  metaSiteId: string;
  siteRegion: string;
  siteLanguage: string;
  membersAreaContext: MembersAreaContext;
  firstInstall?: boolean;
  type?: string;
  editorScriptUrl?: string;
  originInfo?: string;
  brand?: string;
  silentInstallation?: boolean;
};

type DefaultMonitoringParams = SentryTags;

declare const self: {
  commonConfig: {
    brand: string;
  };
};

const getInteractionOptions = (customParams?: Record<string, unknown>, defaultParams?: DefaultMonitoringParams) => {
  return {
    customParams: {
      ...defaultParams,
      ...customParams,
    },
  };
};

async function initializeMonitoring(editorSDK: EditorSDK, options: EditorReadyOptions) {
  const [metaSiteId, siteRegion, siteLanguage, membersAreaContext] = await Promise.all([
    editorSDK.info.getMetaSiteId(APP_TOKEN),
    editorSDK.info.getSiteRegion(APP_TOKEN),
    editorSDK.info.getLanguage(APP_TOKEN),
    getMembersAreaContext(editorSDK, options),
  ]);

  const tags: SentryTags = {
    metaSiteId,
    siteRegion,
    siteLanguage,
    membersAreaContext,
    silentInstallation: !!options?.silentInstallation,
  };

  if (typeof options.firstInstall !== 'undefined') {
    tags.firstInstall = options.firstInstall;
  }
  if (options.origin && options.origin.type) {
    tags.type = options.origin.type;
  }

  if (options.origin?.info) {
    tags.originInfo = options.origin.info?.type || options.origin.info?.appDefinitionId;
  }

  if (options.initialAppData && options.initialAppData.editorScriptUrl) {
    tags.editorScriptUrl = options.initialAppData.editorScriptUrl;
  }

  if (self?.commonConfig?.brand) {
    tags.brand = self.commonConfig.brand;
  }

  const configuration = {
    dataCallback: () => {},
    tags,
  };

  defaultMonitoringParams = tags;

  sentryMonitor = options?.monitoring?.createSentryMonitorForApp(SENTRY_DSN, configuration);

  fedopsInstance = options?.monitoring?.createFedopsLogger();

  if (!sentryMonitor || !fedopsInstance) {
    console.error(`Issues while initialising loggers. Sentry: ${sentryMonitor}, Fedops: ${fedopsInstance}`);
  }

  await editorSDK.document.errors.registerToErrors('', {
    callback: ({ error, methodName }) => {
      console.log(`An error occured in DS, method ${methodName}, error: ${error}`);
      log('Error in DS', { tags: { errorMessage: error.toString(), methodName } });
    },
  });
}

function interactionStarted(interactionName: string, customParams?: Record<string, unknown>) {
  try {
    fedopsInstance.interactionStarted(interactionName, getInteractionOptions(customParams, defaultMonitoringParams));
  } catch (e) {
    const err = 'Failed to start fedops interaction, reason: ' + e;
    sentryMonitor?.captureException(err);
  }
}

function interactionEnded(interactionName: string, customParams?: Record<string, unknown>) {
  try {
    fedopsInstance.interactionEnded(interactionName, getInteractionOptions(customParams, defaultMonitoringParams));
  } catch (e) {
    const err = 'Failed to end fedops interaction, reason: ' + e;
    sentryMonitor?.captureException(err);
  }
}

function interactionFailed(interactionName: string, err: Error) {
  sentryMonitor?.captureException(err, { tags: { interactionName } });
}

async function toMonitored<T>(interactionName: string, promise: () => Promise<T> | T): Promise<T> {
  try {
    interactionStarted(interactionName);
    const response = await promise();
    interactionEnded(interactionName);
    return response;
  } catch (err) {
    interactionFailed(interactionName, err as Error);
    throw err;
  }
}

function log(message: string, options: any = {}) {
  sentryMonitor?.captureMessage(message, { level: 'info', ...options });
}

function logError(err: Error, extra: any = {}) {
  sentryMonitor?.captureException(err, extra);
}

function monitoredTransactionFactory(editorSDK: EditorSDK) {
  const transaction =
    <T extends any[], G>(action: (...args: T) => G) =>
    (...props: T) =>
      runAndWaitForApproval<G>(editorSDK, () => action(...props));

  return <T>(name: string, action: () => Promise<T>) => {
    return toMonitored(name, transaction(action)) as unknown as Promise<T>;
  };
}

export {
  initializeMonitoring,
  interactionStarted,
  interactionEnded,
  interactionFailed,
  toMonitored,
  log,
  logError,
  monitoredTransactionFactory,
};
