import type { EditorSDK } from '@wix/platform-editor-sdk';

import type { MembersAreaPublicApi } from '../types';

import { monitoredTransactionFactory, toMonitored } from '../../../utils/monitoring';
import { MembersAreaContext } from '../../../types/context';
import { PanelOrigin } from '../../constants/panels';
import enforceSequentiality from '../../enforceSequentiality';
import { openAddProfileTabsPanel, openAddSettingsTabsPanel, openUnifiedManagerPanel } from '../../wrappers/panels';
import { getDefinitions } from '../../wrappers/definitions';
import { getCurrentPage, getPageData } from '../../wrappers/pages';
import { getIsResponsiveEditor } from '../../services/applicationState';
import {
  getMemberSettingsPageRoutes,
  getProfilePageRoutes,
  setMemberSettingsPageRoutes,
  setProfilePageRoutes,
} from './services/routes';
import { navigateToPageByWidgetId } from './services/navigation';
import { getProfileType, setProfileType } from './services/blog-writer';
import { isMembersAreaBlocksPlatformValid } from './services/members-blocks-platform';
import {
  deleteVerticalApps,
  deleteStandalonePrivatePage,
  getRegisteredApps,
  installApps,
  installRegisteredApps,
  registerMembersAreaApps,
} from './integration';

const initPublicAPI = (editorSDK: EditorSDK): MembersAreaPublicApi => {
  const monitoredTransaction = monitoredTransactionFactory(editorSDK);

  const sequentialMonitoredTransaction = <T>(name: string, action: () => Promise<T>) => {
    return enforceSequentiality(name, () => monitoredTransaction(name, action)) as unknown as Promise<T>;
  };

  return {
    getRegisteredApps: () => {
      const action = () => getRegisteredApps(editorSDK);
      return toMonitored('editorAPI.v3.getRegisteredApps', action);
    },
    registerMembersAreaApps: (applications, verticalAppDefId, applicationsOptions) => {
      const action = () => registerMembersAreaApps(editorSDK, applications, verticalAppDefId, applicationsOptions);
      return monitoredTransaction('editorAPI.v3.registerMembersAreaApps', action);
    },
    installRegisteredApps: (verticalAppDefId, options) => {
      const action = () => installRegisteredApps(editorSDK, verticalAppDefId, options?.biData);
      return sequentialMonitoredTransaction('editorAPI.v3.installRegisteredApps', action);
    },
    addApplications: async (applications, shouldNavigate, options) => {
      const action = async () => {
        const definitions = await getDefinitions(editorSDK, applications);
        return installApps({ editorSDK, definitions, shouldNavigate, biData: options?.biData });
      };

      return sequentialMonitoredTransaction('editorAPI.v3.addApplications', action);
    },
    removeMembersAreaPage: (pageId, _appDefinitionId) => {
      const action = async () => {
        const currentPageRef = await getCurrentPage({ editorSDK });
        const currentPageData = await getPageData({ editorSDK, pageRef: currentPageRef });
        const pageToNavigateAfterRemove = currentPageData.tpaPageId === pageId ? undefined : currentPageRef;

        return deleteStandalonePrivatePage(editorSDK, pageId, pageToNavigateAfterRemove);
      };
      return toMonitored('editorAPI.v3.removeMembersAreaPage', action);
    },
    handleVerticalDeletion: (verticalAppDefId) => {
      const action = () => deleteVerticalApps(editorSDK, verticalAppDefId);
      return monitoredTransaction('editorAPI.v3.handleVerticalDeletion', action);
    },
    getRoutes: () => {
      const action = () => getProfilePageRoutes(editorSDK);
      return toMonitored('editorAPI.v3.getRoutes', action);
    },
    setRoutes: (routes) => {
      const action = () => setProfilePageRoutes(editorSDK, routes);
      return toMonitored('editorAPI.v3.setRoutes', action);
    },
    getSettingsRoutes: () => {
      const action = () => getMemberSettingsPageRoutes(editorSDK);
      return toMonitored('editorAPI.v3.getSettingsRoutes', action);
    },
    setSettingsRoutes: (routes) => {
      const action = () => setMemberSettingsPageRoutes(editorSDK, routes);
      return toMonitored('editorAPI.v3.setSettingsRoutes', action);
    },
    navigateToSection: ({ widgetId }) => {
      const action = () => navigateToPageByWidgetId(editorSDK, widgetId);
      return monitoredTransaction('editorAPI.v3.navigateToSection', action);
    },
    openAddTabsPanel: ({ panelOrigin, referralInfo }) => {
      switch (panelOrigin) {
        case PanelOrigin.ProfilePage:
          return openAddProfileTabsPanel({ editorSDK, eventPayload: { referralInfo } });
        case PanelOrigin.SettingsPage:
          return openAddSettingsTabsPanel({ editorSDK, eventPayload: { referralInfo } });
        case PanelOrigin.SinglePageApp:
          throw new Error('[SplitMA] PublicAPI.openAddTabsPanel is not supported for SinglePageApp');
        default:
          return Promise.resolve();
      }
    },
    getProfileType: () => getProfileType(editorSDK),
    setProfileType: (profileType) => {
      const action = () => setProfileType(editorSDK, profileType);
      return monitoredTransaction('editorAPI.v3.setProfileType', action);
    },
    modifyPages: (options) => {
      throw new Error('[SplitMA] PublicAPI.modifyPages is not supported');
    },
    modifyPagesAndOpenManagePages: (options, referralInfo) => {
      throw new Error('[SplitMA] PublicAPI.modifyPagesAndOpenManagePages is not supported');
    },
    refreshPageState: () => {
      return editorSDK.application.reloadManifest();
    },
    _getIsResponsiveEditor: () => {
      return Promise.resolve(getIsResponsiveEditor());
    },
    openUnifiedManagerPanel: async (options) => {
      return openUnifiedManagerPanel({ editorSDK, eventPayload: options });
    },
    getIsMembersAreaV2Context: () => {
      throw new Error('[SplitMA] PublicAPI.getIsMembersAreaV2Context is not supported');
    },
    getMembersAreaContext: () => Promise.resolve(MembersAreaContext.V3),
    isAppSectionInstalled: (sectionData) => {
      throw new Error('[SplitMA] PublicAPI.isAppSectionInstalled is not supported');
    },
    openGeneralSettingsPanel: async () => {
      throw new Error('[SplitMA] PublicAPI.openGeneralSettingsPanel is not supported');
    },
    getGeneralSettingsPanelData: () => {
      throw new Error('[SplitMA] PublicAPI.getGeneralSettingsPanelData is not supported');
    },
    getMemberPrivacySettings: () => {
      throw new Error('[SplitMA] PublicAPI.getMemberPrivacySettings is not supported');
    },
    getMembersPageRef: (page) => {
      throw new Error('[SplitMA] PublicAPI.getMembersPageRef is not implemented');
    },
    setHorizontalLayout: (pwHeight) => {
      throw new Error('[SplitMA] PublicAPI.setHorizontalLayout is not implemented');
    },
    setSidebarLayout: () => {
      throw new Error('[SplitMA] PublicAPI.setSidebarLayout is not implemented');
    },
    addCustomPage: (isPrivate) => {
      throw new Error('[SplitMA] PublicAPI.addCustomPage is not implemented');
    },
    refreshMembersAreaApps: () => {
      throw new Error('[SplitMA] PublicAPI.refreshMembersAreaApps is not implemented');
    },
    setProfileWidgetHeight: (height) => {
      throw new Error('[SplitMA] PublicAPI.setProfileWidgetHeight is not implemented');
    },
    /** @deprecated */
    refreshRouters: () => {
      throw new Error('[SplitMA] PublicAPI.refreshRouters is not implemented');
    },
    /** @deprecated */
    registerAdditionalWidgets: () => {
      throw new Error('[SplitMA] PublicAPI.registerAdditionalWidgets is not implemented');
    },
    /** @deprecated */
    getAdditionalWidgets: () => {
      throw new Error('[SplitMA] PublicAPI.getAdditionalWidgets is not implemented');
    },
    /** @deprecated */
    installAdditionalWidgets: () => {
      throw new Error('[SplitMA] PublicAPI.installAdditionalWidgets is not implemented');
    },
  };
};

export const createPublicAPI = (editorSDK: EditorSDK): MembersAreaPublicApi => {
  const getPublicAPI = async () => {
    const publicAPI = initPublicAPI(editorSDK);

    if (!(await isMembersAreaBlocksPlatformValid(editorSDK))) {
      throw new Error('[MA Umbrella - Public API V3] Settings Page or Widget is missing');
    }

    return publicAPI;
  };
  return {
    getRegisteredApps: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getRegisteredApps();
    },
    registerMembersAreaApps: async (applications, verticalAppDefId, applicationsOptions) => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.registerMembersAreaApps(applications, verticalAppDefId, applicationsOptions);
    },
    installRegisteredApps: async (verticalAppDefId, options) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.installRegisteredApps(verticalAppDefId, options);
    },

    addApplications: async (applications, shouldNavigate, options) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.addApplications(applications, shouldNavigate, options);
    },
    handleVerticalDeletion: async (verticalAppDefId) => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.handleVerticalDeletion(verticalAppDefId);
    },
    getProfileType: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getProfileType();
    },
    setProfileType: async (profileType) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.setProfileType(profileType);
    },
    modifyPages: async (options) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.modifyPages(options);
    },
    modifyPagesAndOpenManagePages: async (options, referralInfo) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.modifyPagesAndOpenManagePages(options, referralInfo);
    },
    refreshPageState: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.refreshPageState();
    },
    _getIsResponsiveEditor: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI._getIsResponsiveEditor();
    },
    openAddTabsPanel: async (props) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.openAddTabsPanel(props);
    },
    getIsMembersAreaV2Context: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getIsMembersAreaV2Context();
    },
    getMembersAreaContext: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getMembersAreaContext();
    },
    isAppSectionInstalled: async (sectionData) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.isAppSectionInstalled(sectionData);
    },
    navigateToSection: async (section) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.navigateToSection(section);
    },
    openGeneralSettingsPanel: async (referralInfo) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.openGeneralSettingsPanel(referralInfo);
    },
    getGeneralSettingsPanelData: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getGeneralSettingsPanelData();
    },
    getMemberPrivacySettings: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getMemberPrivacySettings();
    },
    getMembersPageRef: async (page) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getMembersPageRef(page);
    },
    removeMembersAreaPage: async (pageId, appDefinitionId) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.removeMembersAreaPage(pageId, appDefinitionId);
    },
    setHorizontalLayout: async (pwHeight) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.setHorizontalLayout(pwHeight);
    },
    setSidebarLayout: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.setSidebarLayout();
    },
    addCustomPage: async (isPrivate) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.addCustomPage(isPrivate);
    },
    refreshMembersAreaApps: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.refreshMembersAreaApps();
    },
    setProfileWidgetHeight: async (height) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.setProfileWidgetHeight(height);
    },
    refreshRouters: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.refreshRouters();
    },
    registerAdditionalWidgets: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.registerAdditionalWidgets();
    },
    getAdditionalWidgets: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getAdditionalWidgets();
    },
    installAdditionalWidgets: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.installAdditionalWidgets();
    },
    getRoutes: async () => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.getRoutes();
    },
    setRoutes: async (routes) => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.setRoutes(routes);
    },
    getSettingsRoutes: async () => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.getSettingsRoutes();
    },
    setSettingsRoutes: async (routes) => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.setSettingsRoutes(routes);
    },
    openAddProfileTabsPanel: async (referralInfo) => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.openAddProfileTabsPanel(referralInfo);
    },
    openAddSettingsTabsPanel: async (referralInfo) => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.openAddSettingsTabsPanel(referralInfo);
    },
    openUnifiedManagerPanel: async (options) => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.openUnifiedManagerPanel(options);
    },
  };
};
