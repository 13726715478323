import { EditorSDK } from '@wix/platform-editor-sdk';
import { IntegrationApplication, WidgetId } from '@wix/members-area-app-definitions';

import { shouldRemoveUpdatingBoBControllerAndNavigation } from '../../../../utils/experiments';
import { APP_TOKEN, PROFILE_PAGE_BOB_APP_DEF_ID, SANTA_MEMBERS_APP_ID } from '../../../constants';
import { getIsClassicEditor } from '../../../services/applicationState';
import type { Lightbox, MembersAreaPagePublicApi, WidgetPluginPointer } from '../../types';
import type { RouteConfiguration } from '../../types/route-configuration';
import { toMonitored } from '../../../../utils/monitoring';

export const MA_PUBLIC_API_NOT_FOUND_ERROR = '[MAV2] Members Area Page PublicAPI is not found';

export const getMembersAreaPagePublicAPI = async (editorSDK: EditorSDK) => {
  const membersAreaPagePublicApi = await toMonitored(
    'ma-on-msb.getMembersAreaPagePublicAPI#editorSDK.application.getPublicAPI',
    () =>
      editorSDK.application.getPublicAPI(APP_TOKEN, {
        appDefinitionId: PROFILE_PAGE_BOB_APP_DEF_ID,
      }),
  );

  if (!membersAreaPagePublicApi) {
    throw new Error(MA_PUBLIC_API_NOT_FOUND_ERROR);
  }

  return membersAreaPagePublicApi as MembersAreaPagePublicApi;
};

export const getMembersAreaPageRef = async (editorSDK: EditorSDK) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.getMembersAreaPageRef();
};

export const getInstalledApplications = async (editorSDK: EditorSDK) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.getInstalledWidgetPlugins();
};

export const getPublicApplications = async (editorSDK: EditorSDK) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.getPublicApplications();
};

export const setRouteData = async (editorSDK: EditorSDK, payload: RouteConfiguration) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.updateRoute(payload);
};

export const addLightboxes = async (editorSDK: EditorSDK, lightboxes: Lightbox[]) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.addLightboxes(lightboxes);
};

export const addApplicationsToSlots = async (
  editorSDK: EditorSDK,
  applications: IntegrationApplication[],
  shouldNavigate: boolean = false,
) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.addWidgetsPlugins(applications, shouldNavigate);
};

export const removeApplicationsFromSlots = async (editorSDK: EditorSDK, applications: WidgetPluginPointer[]) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  const widgetsIds = applications.map(({ widgetId }) => widgetId);

  return membersAreaPageAPI.removeWidgets(widgetsIds);
};

export const showProfileCard = async (editorSDK: EditorSDK) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.showProfileCard();
};

export const uninstallProfilePageBoB = async (editorSDK: EditorSDK) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.uninstall();
};

export const refreshMemberPage = async (editorSDK: EditorSDK) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.refreshApp();
};

export const isProfilePageBobValid = async (editorSDK: EditorSDK) => {
  try {
    const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
    return await membersAreaPageAPI.isProfilePageBobValid();
  } catch (e) {
    return false;
  }
};

export const addMembersAreaPage = async (editorSDK: EditorSDK) => {
  const avoidNavigation = getIsClassicEditor() || (await shouldRemoveUpdatingBoBControllerAndNavigation());
  return editorSDK.document.application.add(APP_TOKEN, {
    appDefinitionId: PROFILE_PAGE_BOB_APP_DEF_ID,
    managingAppDefId: SANTA_MEMBERS_APP_ID,
    shouldNavigate: !avoidNavigation,
    isSilent: true,
  });
};

export const navigateToSection = async (editorSDK: EditorSDK, widgetId: WidgetId) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.navigateToSection(widgetId);
};

export const navigateAndOpenManageAndNavigatePanel = async (editorSDK: EditorSDK) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  await membersAreaPageAPI.navigateToSection(WidgetId.MyAccount);
  return membersAreaPageAPI.openManageAndNavigatePanel();
};
