import { ExportsFn } from '@wix/platform-editor-sdk';

import { setEditorSDK } from '../services/applicationState';
import { createPublicAPI } from './public-api';
import { createPrivateAPI } from './private-api';
import { createEditorEventsHandlers } from './editor-events-api';

export const exportsFn: ExportsFn = (editorSDK) => {
  setEditorSDK(editorSDK);
  const publicApi = createPublicAPI(editorSDK);
  return {
    public: publicApi,
    private: createPrivateAPI(editorSDK),
    editor: createEditorEventsHandlers(editorSDK, publicApi),
  };
};
