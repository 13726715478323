import type { EditorSDK } from '@wix/platform-editor-sdk';
import type { IntegrationApplication } from '@wix/members-area-app-definitions';

import { WIX_BLOG } from '@wix/app-definition-ids';
import { WidgetId } from '@wix/members-area-app-definitions';

import { ProfileType } from '../../../../types/blog-writer';
import { BLOG_WRITER_ROLE, BLOG_WRITER_ROLES, PROFILE_PAGE_BOB_APP_DEF_ID } from '../../../constants';
import { getRegisteredApplicationOfInstalledVerticals } from '../integration';
import { getProfilePageRoutes, updateProfilePageRoute } from './routes';

const getProfileRoute = async (editorSDK: EditorSDK) => {
  const routes = await getProfilePageRoutes(editorSDK);
  return routes.find(({ widgetId }) => WidgetId.About === widgetId);
};

export const isProfileRegisteredOnlyByBlog = async (editorSDK: EditorSDK) => {
  const integrationApplicationMap = await getRegisteredApplicationOfInstalledVerticals(editorSDK);
  if (!integrationApplicationMap[WIX_BLOG]) {
    return false;
  }

  const { [PROFILE_PAGE_BOB_APP_DEF_ID]: _, ...tail } = integrationApplicationMap;
  const profileRegistrationCount = Object.values(tail)
    .flat()
    .filter(({ widgetId }) => WidgetId.About === widgetId).length;

  return profileRegistrationCount === 1;
};

export const getProfileType = async (editorSDK: EditorSDK): Promise<ProfileType> => {
  const profileRoute = await getProfileRoute(editorSDK);

  if (profileRoute && (await isProfileRegisteredOnlyByBlog(editorSDK))) {
    return profileRoute.vfr?.includes(BLOG_WRITER_ROLE) ? ProfileType.BWP_ONLY : ProfileType.BWP_ALL;
  }

  return ProfileType.ALL;
};

export const setProfileType = async (editorSDK: EditorSDK, profileType: ProfileType) => {
  const aboutPage = await getProfileRoute(editorSDK);
  if (!aboutPage) {
    return;
  }

  let { vfr = [] } = aboutPage;
  vfr = vfr.filter((role) => !BLOG_WRITER_ROLES.includes(role));
  if (profileType === ProfileType.BWP_ONLY) {
    vfr = vfr.concat(BLOG_WRITER_ROLES);
  }

  await updateProfilePageRoute(editorSDK, { widgetId: aboutPage.widgetId, vfr });
};

export const maybeResetProfileRolesOnVerticalRegistration = async (
  editorSDK: EditorSDK,
  verticalAppDefId: string,
  registeredApplications: IntegrationApplication[],
) => {
  const integrationApplicationMap = await getRegisteredApplicationOfInstalledVerticals(editorSDK);
  const isBlogIntegrated = integrationApplicationMap[WIX_BLOG];
  const isInstallingBlog = verticalAppDefId !== WIX_BLOG;
  const isProfilePageRegistered = registeredApplications.some(({ widgetId }) => WidgetId.About === widgetId);

  if (isInstallingBlog || !isBlogIntegrated || !isProfilePageRegistered) {
    return;
  }

  const aboutPage = await getProfileRoute(editorSDK);
  if (aboutPage?.vfr?.length) {
    await setProfileType(editorSDK, ProfileType.BWP_ALL);
  }
};
