import type { AppManifest, EditorSDK, InnerRoutesSortOptionsStrings } from '@wix/platform-editor-sdk';
import type { TranslationFunction } from 'i18next';

import { MEMBERS_PAGES_PANEL_HELP_ID } from '../../../../constants';
import { getApplicationActions } from './app-actions';
import { getPageSettings } from './settings';
import { getPageActions } from './page-actions';

const sortInnerRoutes: InnerRoutesSortOptionsStrings = 'no-sort';

export const getPagesManifest = async (editorSDK: EditorSDK, t: TranslationFunction): Promise<AppManifest['pages']> => {
  return {
    sortInnerRoutes,
    pageDescriptors: {
      default: { icon: 'membersAction' },
    },
    applicationSettings: {
      default: {
        displayName: t('Pages_Panel_MemberMenu_Title'),
        helpId: MEMBERS_PAGES_PANEL_HELP_ID,
      },
    },
    applicationActions: await getApplicationActions(t),
    pageSettings: await getPageSettings(editorSDK, t),
    pageActions: await getPageActions(t),
  };
};
