import type { EditorSDK, ComponentRef } from '@wix/platform-editor-sdk';

import { APP_TOKEN, MENU_IDS } from '../constants';
import { migrateHorizontalMemberMenuComponent } from './v2-migration-horizontal-member-menu';
import { migrateVerticalMemberMenuComponent } from './v2-migration-vertical-member-menu';
import { migrateMobileMemberMenuComponent } from './v2-migration-mobile-member-menu';
import { getProfilePageBobWidgetRef } from './v2-migration';
import { OldMemberMenuSettings } from './v2-migration-types';

type RootGetterParams = {
  editorSDK: EditorSDK;
  isMobile: boolean;
};

type DefaultGetterParams = RootGetterParams & {
  wasHorizontalLayout: boolean;
};

type OldMemberMenuSettingsByDevice = {
  oldMemberMenuComponentSettingsDesktop: OldMemberMenuSettings;
  oldMemberMenuComponentSettingsMobile: OldMemberMenuSettings;
};

// v1 menus
export const DROPDOWN_MENU_TYPE = 'wysiwyg.viewer.components.menus.DropDownMenu';
export const VERTICAL_MENU_TYPE = 'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu';

const getV1MemberMenuComponentType = (wasHorizontalLayout: boolean) => {
  return wasHorizontalLayout ? DROPDOWN_MENU_TYPE : VERTICAL_MENU_TYPE;
};

const getSerializedComponentData = async ({
  editorSDK,
  componentRef,
  isMobile,
}: RootGetterParams & {
  componentRef: ComponentRef;
}) => {
  try {
    const type = isMobile ? 'MOBILE' : 'DESKTOP';

    const serializedData = await editorSDK.components.serialize(APP_TOKEN, {
      componentRef: {
        ...componentRef,
        type,
      },
    });

    return serializedData;
  } catch {
    return {};
  }
};

const getOldMemberMenuComponentSettings = async ({ editorSDK, wasHorizontalLayout, isMobile }: DefaultGetterParams) => {
  const componentType = getV1MemberMenuComponentType(wasHorizontalLayout);
  const menuComponents = await editorSDK.components.findAllByType(APP_TOKEN, { componentType });
  const memberMenuSettings: OldMemberMenuSettings[] = [];

  await Promise.all(
    menuComponents.map(async (componentRef) => {
      const serializedData = await getSerializedComponentData({
        editorSDK,
        componentRef,
        isMobile,
      });

      if (serializedData.data?.menuRef === `#${MENU_IDS.SUB_MENU_ID}`) {
        memberMenuSettings.push({
          props: serializedData.props,
          style: serializedData.style,
          componentType: serializedData.componentType,
          layout: serializedData.layout,
        });
      }
    }),
  );

  if (!memberMenuSettings.length) {
    throw new Error('MA templates migration - unhandled missing member menu component case');
  }

  if (memberMenuSettings.length > 1) {
    throw new Error('MA templates migration - unhandled multiple member menu components case');
  }

  return memberMenuSettings[0];
};

const getNewMemberMenuComponentRef = async ({
  editorSDK,
  wasHorizontalLayout,
  isMobile,
}: DefaultGetterParams): Promise<ComponentRef> => {
  const widgetRef = await getProfilePageBobWidgetRef(editorSDK);
  const role = isMobile || wasHorizontalLayout ? 'horizontalMenu1' : 'expandableMenu1';
  const type = isMobile ? 'MOBILE' : 'DESKTOP';

  const [controllerRef] = await editorSDK.components.getChildren(APP_TOKEN, {
    componentRef: widgetRef,
  });

  const [menuRef] = await editorSDK.components.findAllByRole(APP_TOKEN, {
    controllerRef,
    role,
  });

  if (!menuRef) {
    throw new Error('MA templates migration - could not find latest member menu component ref');
  }

  return {
    ...menuRef,
    type,
  };
};

export const getNewMemberMenuComponentData = async (getterParams: DefaultGetterParams) => {
  const componentRef = await getNewMemberMenuComponentRef(getterParams);
  const serializedData = await getterParams.editorSDK.components.serialize(APP_TOKEN, { componentRef });

  return {
    style: serializedData.style,
    scopedStyles: serializedData.scopedStyles,
    componentRef,
  };
};

const getOldMemberMenuComponentSettingsByDevice = async (getterParams: Omit<DefaultGetterParams, 'isMobile'>) => {
  const oldMemberMenuComponentSettingsDesktop = await getOldMemberMenuComponentSettings({
    ...getterParams,
    isMobile: false,
  });

  const oldMemberMenuComponentSettingsMobile = await getOldMemberMenuComponentSettings({
    ...getterParams,
    isMobile: true,
  });

  return {
    oldMemberMenuComponentSettingsDesktop,
    oldMemberMenuComponentSettingsMobile,
  };
};

const getMigrationDataByDevice = async (
  getterParams: Omit<DefaultGetterParams, 'isMobile'>,
  { oldMemberMenuComponentSettingsDesktop, oldMemberMenuComponentSettingsMobile }: OldMemberMenuSettingsByDevice,
) => {
  const newMemberMenuComponentDataDesktop = await getNewMemberMenuComponentData({ ...getterParams, isMobile: false });
  const newMemberMenuComponentDataMobile = await getNewMemberMenuComponentData({ ...getterParams, isMobile: true });

  const migrationDataDesktop = {
    oldMemberMenuComponentSettings: oldMemberMenuComponentSettingsDesktop,
    newMemberMenuComponentData: newMemberMenuComponentDataDesktop,
  };

  const migrationDataMobile = {
    oldMemberMenuComponentSettings: oldMemberMenuComponentSettingsMobile,
    newMemberMenuComponentData: newMemberMenuComponentDataMobile,
  };

  return {
    migrationDataDesktop,
    migrationDataMobile,
  };
};

export const initMemberMenuComponentMigration = async (editorSDK: EditorSDK, wasHorizontalLayout: boolean) => {
  const getterParams = {
    editorSDK,
    wasHorizontalLayout,
  };

  const oldSettingsData = await getOldMemberMenuComponentSettingsByDevice({ ...getterParams });

  return async () => {
    const { migrationDataDesktop, migrationDataMobile } = await getMigrationDataByDevice(
      { ...getterParams },
      oldSettingsData,
    );

    if (wasHorizontalLayout) {
      await migrateHorizontalMemberMenuComponent({
        editorSDK,
        migrationData: migrationDataDesktop,
        isMobile: false,
      });
    } else {
      await migrateVerticalMemberMenuComponent(editorSDK, migrationDataDesktop);
    }

    return migrateMobileMemberMenuComponent(editorSDK, migrationDataMobile);
  };
};
