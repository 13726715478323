import { EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';

import { toMonitored } from '../../../../utils/monitoring';
import { addGlobalController } from '../services/controllers';
import { addLoginMenus, addMembersSubMenu } from '../services/menus';
import { addMembersAreaPage, refreshMemberPage } from '../services/members-area-page';
import { navigateToMembersAreaPage } from '../services/navigation';
import { addMyAccountMenuItemToMembersAreaMenus } from '../services/menu-items';

export const monitoredAddGlobalController = async (editorSDK: EditorSDK) => {
  try {
    await toMonitored('v2.install.addGlobalController', () => addGlobalController(editorSDK));
  } catch (error: any) {
    if (error?.message?.includes?.('already exists')) {
      return;
    }

    throw error;
  }
};

export const monitoredAddMembersSubMenu = (editorSDK: EditorSDK) =>
  toMonitored('v2.install.addMembersSubMenu', () => addMembersSubMenu(editorSDK));

export const monitoredAddLoginMenus = (editorSDK: EditorSDK, options: EditorReadyOptions) =>
  toMonitored('v2.install.addLoginMenus', () => addLoginMenus(editorSDK, options));

export const monitoredAddMembersAreaPage = (editorSDK: EditorSDK) =>
  toMonitored('v2.install.addMembersAreaPage', () => addMembersAreaPage(editorSDK));

export const monitoredAddMyAccountMenuItemToMembersAreaMenus = (editorSDK: EditorSDK, options: EditorReadyOptions) =>
  toMonitored('v2.install.addMyAccountMenuItemToMembersAreaMenus', () =>
    addMyAccountMenuItemToMembersAreaMenus(editorSDK, options),
  );

export const monitoredRefreshMemberPage = (editorSDK: EditorSDK) =>
  toMonitored('v2.install.refreshMemberPage', () => refreshMemberPage(editorSDK));

export const monitoredNavigateToMembersPage = (editorSDK: EditorSDK) =>
  toMonitored('v2.install.navigateAfterInstallation', () => navigateToMembersAreaPage(editorSDK));
