import { AppExposedApis, ComponentDefinition, DeviceType, EditorSDK } from '@wix/platform-editor-sdk';
import { MA_APP_IDS } from '@wix/members-area-app-definitions';
import { CompStructure } from '@wix/document-services-types';

import { MembersAreaPublicApi } from './types';
import { openRemoveLoginBarConfirmationPanel } from '../wrappers/platformPanels';
import { handleAppInstalled } from './ma-on-msb/editor-events-api';

function getIsAppWidget(componentType: string) {
  return componentType === 'platform.components.AppWidget';
}

async function getIsEditorInMobileMode(editorSDK: EditorSDK) {
  const editorMode = await editorSDK.editor.info.getEditorMode();
  return editorMode === DeviceType.Mobile;
}

function getIsLoginBar(componentType: string) {
  return componentType === 'wysiwyg.viewer.components.LoginSocialBar';
}

async function beforeComponentRemoved(componentDefinition: ComponentDefinition, editorSDK: EditorSDK) {
  const isEditorInMobileMode = await getIsEditorInMobileMode(editorSDK);

  if (isEditorInMobileMode) {
    return;
  }
  const componentType = componentDefinition.componentType;
  const isAppWidget = getIsAppWidget(componentType);
  const loginBarComponentDefinition = componentDefinition.components?.[0];
  const isComponentObject = typeof loginBarComponentDefinition === 'object';
  const isLoginBar =
    isAppWidget && isComponentObject
      ? getIsLoginBar((loginBarComponentDefinition as CompStructure)?.componentType)
      : getIsLoginBar(componentType);

  if (isLoginBar) {
    await openRemoveLoginBarConfirmationPanel(editorSDK);
  }
}

export const createEditorEventsHandlers = (
  editorSDK: EditorSDK,
  publicAPI: MembersAreaPublicApi,
): AppExposedApis['editor'] => {
  return {
    beforeComponentRemoved: ({ componentDefinition }) => beforeComponentRemoved(componentDefinition, editorSDK),
    async migrate(payload: any) {
      switch (payload?.migrationTask) {
        case 'install_subscriptions':
          await publicAPI.addApplications([MA_APP_IDS.MY_SUBSCRIPTIONS], false);
          break;

        case 'install_wallet':
          await publicAPI.addApplications([MA_APP_IDS.MY_WALLET], false);
          break;

        case 'install_subscriptions_and_wallet':
          await publicAPI.addApplications([MA_APP_IDS.MY_SUBSCRIPTIONS, MA_APP_IDS.MY_WALLET], false);
          break;
      }
    },
    appInstalled({ appDefinitionId }) {
      return handleAppInstalled(editorSDK, appDefinitionId);
    },
  };
};
