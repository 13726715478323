import { EditorSDK } from '@wix/platform-editor-sdk';

import { MembersAreaContext } from '../../types/context';
import { getMembersAreaContext } from '../services/context';
import { createPrivateAPI as createCurrentMAPrivateAPI } from './current-ma';
import { createPrivateAPI as createMAOnMSBPrivateAPI } from './ma-on-msb';
import { createPrivateAPI as createSplitMAPrivateAPI } from './split-ma';
import { MembersAreaPrivateApi } from './types';

type PrivateAPIFactory = (editorSDK: EditorSDK) => MembersAreaPrivateApi;

const privateAPIFactoryMap: { [key in MembersAreaContext]: PrivateAPIFactory } = {
  [MembersAreaContext.V1]: createCurrentMAPrivateAPI,
  [MembersAreaContext.V2]: createMAOnMSBPrivateAPI,
  [MembersAreaContext.V3]: createSplitMAPrivateAPI,
};

const initPrivateAPI = async (editorSDK: EditorSDK) => {
  const membersAreaContext = await getMembersAreaContext(editorSDK);
  const privateAPIFactory = privateAPIFactoryMap[membersAreaContext];

  return privateAPIFactory(editorSDK);
};

export const createPrivateAPI = (editorSDK: EditorSDK): MembersAreaPrivateApi => {
  const getPrivateAPI = () => initPrivateAPI(editorSDK);

  return {
    uninstall: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.uninstall();
    },
    getAllRouters: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.getAllRouters();
    },
    getRouterDataByPage: async (pageRef) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.getRouterDataByPage(pageRef);
    },
    updatePageDataInRouter: async (pageRef, dataFieldToUpdate, updatedData) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.updatePageDataInRouter(pageRef, dataFieldToUpdate, updatedData);
    },
    saveInnerRoute: async (newInnerRoute, pageRef, pageRouterData) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.saveInnerRoute(newInnerRoute, pageRef, pageRouterData);
    },
    updatePageData: async (options) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.updatePageData(options);
    },
    savePasswordPermissions: async (pageRef, privacy, plainPassword) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.savePasswordPermissions(pageRef, privacy, plainPassword);
    },
    hasSocialPages: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.hasSocialPages();
    },
    createPrivateMemberPage: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.createPrivateMemberPage();
    },
    renamePage: async (pageName, pageRef, pageRouterData) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.renamePage(pageName, pageRef, pageRouterData);
    },
    removePage: async (pageData) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.removePage(pageData);
    },
    shouldAllowUpdatingPagePrefix: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.shouldAllowUpdatingPagePrefix();
    },
    openSeoRedirectDashboard: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.openSeoRedirectDashboard();
    },
    shouldShowPageRedirectNote: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.shouldShowPageRedirectNote();
    },
    shouldSplitCustomPageTranslations: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.shouldSplitCustomPageTranslations();
    },
    getIsMigrating: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.getIsMigrating();
    },
    refreshMembersBlocksPlatform: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.refreshMembersBlocksPlatform();
    },
    deleteApps: async (definitions) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.deleteApps(definitions);
    },
    getUnifiedManagerPanelData: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.getUnifiedManagerPanelData();
    },
  };
};
