import type { EditorSDK } from '@wix/platform-editor-sdk';

type MembersAreaUserPreferences = Partial<{
  welcomePanel: {
    wasShown: boolean;
  };
}>;

const membersAreaUserPreferenceKeys: (keyof MembersAreaUserPreferences)[] = ['welcomePanel'];

const getMembersAreaUserPreferences = async (editorSDK: EditorSDK) => {
  return editorSDK.userPreferences.site.get(
    'members-area',
    membersAreaUserPreferenceKeys,
  ) as Promise<MembersAreaUserPreferences>;
};

const updateMembersAreaUserPreferences = async (editorSDK: EditorSDK, preferences: MembersAreaUserPreferences) => {
  return editorSDK.userPreferences.site.set('members-area', preferences);
};

export const getWelcomePanelWasShown = async (editorSDK: EditorSDK) => {
  const userPreferences = await getMembersAreaUserPreferences(editorSDK);
  return userPreferences.welcomePanel?.wasShown;
};

export const setWelcomePanelWasShown = async (editorSDK: EditorSDK, value: boolean) => {
  const userPreferences = await getMembersAreaUserPreferences(editorSDK);
  await updateMembersAreaUserPreferences(editorSDK, {
    ...userPreferences,
    welcomePanel: {
      ...userPreferences.welcomePanel,
      wasShown: value,
    },
  });
};
