import { EditorSDK } from '@wix/platform-editor-sdk';
import { WIX_BLOG } from '@wix/app-definition-ids';
import { IntegrationApplication, WidgetId } from '@wix/members-area-app-definitions';

import { createBIService } from '../../../../utils/bi';
import { toMonitored } from '../../../../utils/monitoring';
import { IntegrationApplicationWithoutWidgetId } from '../../../../types/general-settings';
import { ProfileType } from '../../../../types/blog-writer';
import { getIsADI, getIsBlogWriterProfilesOnly, getVerticalsApps } from '../../../services/applicationState';
import { installSiteApplications } from '../../../platform-api/addApplications';
import { Lightbox, RouteConfiguration } from '../../types';
import { addApplicationsToSlots, addLightboxes } from '../services/members-area-page';
import { addMenuItemsToMembersAreaMenus } from '../services/menu-items';
import { maybeShowProfileCard } from '../services/layout';
import { getProfileType, isProfileRegisteredOnlyByBlog, setProfileType } from '../services/blog-writer';
import { getRoutes } from '../services/routes';
import { groupDefinitionsByMethod } from './definitions';
import { getPagesRefsFromAppOrComponentResponse, setPagesToNonIndexable } from '../../../wrappers/pages';
import { getMyAccountInstallDefinition } from '../../../constants';
import { shouldEnableMyAccountParallelInstall } from '../../../../utils/experiments';
import { getApplicationPopupPages } from '../../../wrappers/popupPages';
import { mergeGlobalSettings } from '../../../services/members-area';

const filterOutInstalledWidgets = (toInstallWidget: IntegrationApplication[], installedWidgets: RouteConfiguration[]) =>
  toInstallWidget.filter(({ widgetId }) => !installedWidgets.some((route) => route.widgetId === widgetId));

const getNotInstalledWidgets = async (editorSDK: EditorSDK, widgetPlugins: IntegrationApplication[]) => {
  const routes = await toMonitored('editorAPI.v2.installApps.getRoutes', () => getRoutes(editorSDK));
  const containsMyAccount = widgetPlugins.some(({ widgetId }) => widgetId === WidgetId.MyAccount);

  if ((await shouldEnableMyAccountParallelInstall()) && !containsMyAccount) {
    const widgetPluginsWithMyAccount = [getMyAccountInstallDefinition(), ...widgetPlugins];

    return routes ? filterOutInstalledWidgets(widgetPluginsWithMyAccount, routes) : widgetPluginsWithMyAccount;
  }

  return routes ? filterOutInstalledWidgets(widgetPlugins, routes) : widgetPlugins;
};

const getNotInstalledLightboxes = async (editorSDK: EditorSDK, definitions: IntegrationApplication[]) => {
  if (definitions.length === 0) {
    return [];
  }

  const applicationPopupPages = await getApplicationPopupPages(editorSDK);
  const installedTpaPageIdMap = applicationPopupPages.reduce<Record<string, true>>(
    (map, { tpaPageId }) => (tpaPageId ? { ...map, [tpaPageId]: true } : map),
    {},
  );

  return definitions.filter(({ pageId }) => !installedTpaPageIdMap[pageId]);
};

export const installApps = async (
  editorSDK: EditorSDK,
  definitions: IntegrationApplication[] | IntegrationApplicationWithoutWidgetId[],
  shouldNavigate: boolean = false,
) => {
  const { widgetPlugins, siteApps, lightboxes } = groupDefinitionsByMethod(definitions);
  const notInstalledWidgets = await getNotInstalledWidgets(editorSDK, widgetPlugins);
  const notInstalledLightboxes = await getNotInstalledLightboxes(editorSDK, lightboxes);

  if (notInstalledWidgets.length > 0) {
    await addApplicationsToSlots(editorSDK, notInstalledWidgets, shouldNavigate);
    await addMenuItemsToMembersAreaMenus(editorSDK, notInstalledWidgets);
  }

  if (notInstalledLightboxes.length > 0) {
    const action = async () => {
      const willInstallFFlightbox = notInstalledLightboxes.some(
        ({ widgetId }) => widgetId === WidgetId.FollowingFollowersLightbox,
      );

      await addLightboxes(editorSDK, notInstalledLightboxes as Lightbox[]);

      if (willInstallFFlightbox) {
        mergeGlobalSettings(editorSDK, { ffLightboxInstalled: true });
      }
    };

    await toMonitored('editorAPI.v2.installApps.installLighboxes', action);
  }

  if (siteApps.length > 0) {
    const siteAppsPages = await toMonitored('editorAPI.v2.installApps.installSiteApplications', async () =>
      installSiteApplications({ editorSDK, applications: siteApps, shouldNavigate: false }),
    );
    const pageRefs = getPagesRefsFromAppOrComponentResponse(siteAppsPages);

    setPagesToNonIndexable(editorSDK, pageRefs);
  }
};

const maybeSetBlogWriterProfileType = async (editorSDK: EditorSDK, verticalAppDefId: string) => {
  const hasBlogTriggeredInstall = verticalAppDefId === WIX_BLOG;
  if (!hasBlogTriggeredInstall || getIsADI() || !(await isProfileRegisteredOnlyByBlog(editorSDK))) {
    return;
  }

  const profileType = getIsBlogWriterProfilesOnly() ? ProfileType.BWP_ONLY : ProfileType.BWP_ALL;
  const currentProfileType = await getProfileType(editorSDK);

  if (profileType !== currentProfileType) {
    await setProfileType(editorSDK, profileType);
  }
};

export const installRegisteredApps = async (editorSDK: EditorSDK, verticalAppDefId: string) => {
  const biService = await createBIService({ editorSDK });
  biService.verticalTriggeredMaInstallInitiated({ originAppId: verticalAppDefId });

  const integrationApps = getVerticalsApps(verticalAppDefId) as IntegrationApplication[];
  const integrationAppsToInstall = integrationApps.filter((app) => {
    return app.shouldInstallInitially !== false;
  });

  if (integrationAppsToInstall.length > 0) {
    await installApps(editorSDK, integrationAppsToInstall);
    await maybeShowProfileCard(editorSDK, integrationAppsToInstall);
  }

  await maybeSetBlogWriterProfileType(editorSDK, verticalAppDefId);
  biService.verticalTriggeredMaInstallSuccess({ originAppId: verticalAppDefId });
};
