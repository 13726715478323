import type { AppManifest, EditorSDK, PageSettings } from '@wix/platform-editor-sdk';
import type { TranslationFunction } from 'i18next';

import { buildPanelUrl } from '../../../../services/panel-url-builder';
import { shouldEnableV2PageInfoPanelCustomPage } from '../../../../../utils/experiments';

type PageSettingsOptions = NonNullable<AppManifest['pages']>['pageSettings'];

const getPageInfoV2Settings = async (editorSDK: EditorSDK, t: TranslationFunction): Promise<PageSettings> => ({
  type: 'page_info',
  title: t('NewPages_Panel_PageInfo_Members_Title'),
  url: await buildPanelUrl(editorSDK, 'pageInfoV2.html'),
  helpId: '2fd96dc5-ff35-4ead-9917-12b487c59fe4',
});

const pageInfoSettings: PageSettings = {
  title: 'Page Info',
  // @ts-expect-error
  event: 'pageInfo',
  icon: 'page_dynamic_icon',
  helpId: '2fd96dc5-ff35-4ead-9917-12b487c59fe4',
  type: 'page_info',
};

const seoSettings: PageSettings = {
  title: 'SEO Basics',
  type: 'seo',
};

const getLayoutSettings = (t: TranslationFunction): PageSettings => ({
  type: 'layout',
  title: t('NewPages_Panel_Layout_Members_Title'),
});

const getPermissionsSettings = (t: TranslationFunction): PageSettings => ({
  type: 'permissions',
  title: t('NewPages_Panel_Layout_Members_Title'),
  helpId: 'd243ad48-2e17-4786-99d7-23d011aa4bd6',
});

export const getPageSettings = async (editorSDK: EditorSDK, t: TranslationFunction): Promise<PageSettingsOptions> => {
  const showV2PageInfoPanelCustomPage = await shouldEnableV2PageInfoPanelCustomPage();
  return {
    default: [await getPageInfoV2Settings(editorSDK, t), getLayoutSettings(t), getPermissionsSettings(t)],
    custom: showV2PageInfoPanelCustomPage
      ? [await getPageInfoV2Settings(editorSDK, t), getLayoutSettings(t), getPermissionsSettings(t)]
      : [pageInfoSettings, getLayoutSettings(t), getPermissionsSettings(t), seoSettings],
    lightbox: [],
  };
};
