import type { EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';

import { log } from '../../../../utils/monitoring';
import { getIsClassicEditor, getIsMigration } from '../../../services/applicationState';
import { isStandaloneInstallation } from '../../../util';

import {
  shouldEnableMyAccountParallelInstall,
  shouldNotWaitNavigation,
  shouldRemoveUpdatingBoBControllerAndNavigation,
} from '../../../../utils/experiments';

import {
  APP_TOKEN,
  getMyAccountInstallDefinition,
  MEMBER_TPA_PAGE_ID,
  PROFILE_PAGE_BOB_APP_DEF_ID,
} from '../../../constants';

import {
  monitoredAddGlobalController,
  monitoredAddLoginMenus,
  monitoredAddMembersAreaPage,
  monitoredAddMembersSubMenu,
  monitoredAddMyAccountMenuItemToMembersAreaMenus,
  monitoredNavigateToMembersPage,
  monitoredRefreshMemberPage,
} from './monitored-methods';
import { installApps } from '../integration';
import { uninstallMembersArea } from '../services/uninstall';

const shouldNavigateAfterInstallation = async (options: EditorReadyOptions) => {
  if (getIsMigration()) {
    return false;
  }

  if (!getIsClassicEditor() && !(await shouldRemoveUpdatingBoBControllerAndNavigation())) {
    return false;
  }

  return isStandaloneInstallation(options);
};

const confirmCleanStateBeforeInstallation = async (editorSDK: EditorSDK) => {
  try {
    const allPages = await editorSDK.document.pages.data.getAll(APP_TOKEN);
    const membersAreaPage = allPages.find(({ tpaPageId }) => tpaPageId === MEMBER_TPA_PAGE_ID);
    const isMemberPageInstalled = await editorSDK.application.isApplicationInstalled(APP_TOKEN, {
      appDefinitionId: PROFILE_PAGE_BOB_APP_DEF_ID,
    });

    if (isMemberPageInstalled || membersAreaPage) {
      log('Member Page leftovers are found when installing Members Area', {
        extra: { isMemberPageInstalled, membersAreaPage },
      });
    }
  } catch (e: any) {
    log('Failed to confirm clean state before installation', { extra: { e: e.toString() } });
  }
};

export const installMembersArea = async (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  await confirmCleanStateBeforeInstallation(editorSDK);
  const enableMyAccountParallelInstall = await shouldEnableMyAccountParallelInstall();

  try {
    await monitoredAddGlobalController(editorSDK);
    await Promise.all([
      monitoredAddMembersSubMenu(editorSDK),
      monitoredAddLoginMenus(editorSDK, options),
      monitoredAddMembersAreaPage(editorSDK),
    ]);

    if (enableMyAccountParallelInstall && isStandaloneInstallation(options)) {
      await installApps(editorSDK, [getMyAccountInstallDefinition(options.origin)]);
    }

    if (!enableMyAccountParallelInstall) {
      await monitoredAddMyAccountMenuItemToMembersAreaMenus(editorSDK, options);
    }

    if (isStandaloneInstallation(options)) {
      await monitoredRefreshMemberPage(editorSDK);
    }

    if (await shouldNavigateAfterInstallation(options)) {
      if (await shouldNotWaitNavigation()) {
        // Not awaiting, for performance
        monitoredNavigateToMembersPage(editorSDK);
      } else {
        await monitoredNavigateToMembersPage(editorSDK);
      }
    }
  } catch (e) {
    try {
      await uninstallMembersArea(editorSDK);
    } catch (err) {}

    throw e;
  }
};
