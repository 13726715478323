import { EditorSDK } from '@wix/platform-editor-sdk';
import { IntegrationApplication, IntegrationApplicationMap } from '@wix/members-area-integration-kit';

import { arePagesEqual } from '../../../../utils/pages';
import { asyncFilter } from '../../../../utils/promises';
import { getAllIntegratedApps, getVerticalsApps } from '../../../services/applicationState';
import { isMyWishlist } from '../../../services/myWishlistIntegration';
import { APP_TOKEN, MEMBERS_LIST_APP_DEF_ID, MEMBERS_LIST_PAGE_ID } from '../../../constants';
import { WidgetPluginPointer } from '../../types';
import { getInstalledApplications } from '../services/members-area-page';

const isVerticalInstalled = (editorSDK: EditorSDK) => (appDefinitionId: string) => {
  return editorSDK.application.isApplicationInstalled(APP_TOKEN, { appDefinitionId });
};

export const isApplicationInstalled = (application: IntegrationApplication, widgetPointers: WidgetPluginPointer[]) => {
  return widgetPointers.some(({ appDefinitionId, widgetId }) => {
    return application.appDefinitionId === appDefinitionId && application.widgetId === widgetId;
  });
};

const getInstalledVerticalIds = async (editorSDK: EditorSDK): Promise<string[]> => {
  const allIntegratedAppsMap = getAllIntegratedApps();
  return asyncFilter(Object.keys(allIntegratedAppsMap), isVerticalInstalled(editorSDK));
};

export const getRegisteredApps = async (editorSDK: EditorSDK) => {
  const allIntegratedAppsMap = getAllIntegratedApps();
  const installedVerticalIds = await getInstalledVerticalIds(editorSDK);
  const installedApplications = await getInstalledApplications(editorSDK);

  return installedVerticalIds.reduce((integrationApplicationMap, appDefId) => {
    const definitions = (allIntegratedAppsMap[appDefId] ?? []) as IntegrationApplication[];

    return definitions.length > 0
      ? {
          ...integrationApplicationMap,
          [appDefId]: definitions.filter((definition) => {
            return !isMyWishlist(definition) && !isApplicationInstalled(definition, installedApplications);
          }),
        }
      : integrationApplicationMap;
  }, {} as IntegrationApplicationMap);
};

const getAppsOfOtherVerticals = async (editorSDK: EditorSDK, currentVerticalAppDefId: string) => {
  const installedVerticalIds = await getInstalledVerticalIds(editorSDK);
  const allIntegratedAppsMap = getAllIntegratedApps();

  return installedVerticalIds.reduce<IntegrationApplication[]>((appsOfOtherVerticals, appDefId) => {
    if (currentVerticalAppDefId === appDefId) {
      return appsOfOtherVerticals;
    }
    const verticalDefinitions = (allIntegratedAppsMap[appDefId] ?? []) as IntegrationApplication[];
    return [...appsOfOtherVerticals, ...verticalDefinitions];
  }, []);
};

const getWidgetPluginDefinitionsToDeleteWithVertical = (
  installedWidgetPlugins: WidgetPluginPointer[],
  verticalApps: IntegrationApplication[],
  appsOfOtherVerticals: IntegrationApplication[],
) => {
  return verticalApps.reduce<IntegrationApplication[]>((appsToDelete, app) => {
    if (isApplicationInstalled(app, installedWidgetPlugins)) {
      return isApplicationInstalled(app, appsOfOtherVerticals) ? appsToDelete : [...appsToDelete, app];
    }

    return appsToDelete;
  }, []);
};

const getSiteAppsToDeleteWithVertical = (
  verticalApps: IntegrationApplication[],
  appsOfOtherVerticals: IntegrationApplication[],
) => {
  const verticalSiteApps = verticalApps.filter(({ method }) => method === 'addApplicationToSite');
  const siteAppsToDeleteWithVertical = verticalSiteApps
    .filter((verticalApp) => {
      return appsOfOtherVerticals.some((otherVerticalsApp) => !arePagesEqual(verticalApp, otherVerticalsApp));
    })
    .map((verticalApp) => ({
      ...verticalApp,
      pageId: verticalApp.appDefinitionId === MEMBERS_LIST_APP_DEF_ID ? MEMBERS_LIST_PAGE_ID : verticalApp.pageId,
    }));

  return asyncFilter(siteAppsToDeleteWithVertical, isVerticalInstalled) as Promise<IntegrationApplication[]>;
};

export const getAppsToDeleteWithVertical = async (
  editorSDK: EditorSDK,
  verticalAppDefId: string,
): Promise<IntegrationApplication[]> => {
  const verticalApps = getVerticalsApps(verticalAppDefId) as IntegrationApplication[];
  const appsOfOtherVerticals = await getAppsOfOtherVerticals(editorSDK, verticalAppDefId);
  const installedWidgetPlugins = await getInstalledApplications(editorSDK);
  const widgetPluginsDefinitionsToDelete = getWidgetPluginDefinitionsToDeleteWithVertical(
    installedWidgetPlugins,
    verticalApps,
    appsOfOtherVerticals,
  );
  const siteAppsToDelete = await getSiteAppsToDeleteWithVertical(verticalApps, appsOfOtherVerticals);

  return [...widgetPluginsDefinitionsToDelete, ...siteAppsToDelete];
};

export const getRegisteredApplicationOfInstalledVerticals = async (editorSDK: EditorSDK) => {
  const allVerticals = getAllIntegratedApps();
  const installedVerticalIds = await getInstalledVerticalIds(editorSDK);

  return installedVerticalIds.reduce<{ [key: string]: IntegrationApplication[] }>(
    (verticals, verticalId) => ({
      ...verticals,
      [verticalId]: allVerticals[verticalId] as IntegrationApplication[],
    }),
    {},
  );
};
