import { TranslationFunction } from 'i18next';
import { Item } from '@wix/design-system';
import type { WidgetId } from '@wix/members-area-app-definitions';

import { Page, PageTypes } from '../../../types/page';
import { SANTA_MEMBERS_APP_ID } from '../../../editor-app-module/constants';
import { PagePrivacy } from '../../constants';
import { UnifiedManagerApplication } from '../../../types';

export const getPrivateCustomPageDefinitions = (
  t: TranslationFunction,
  shouldSplitCustomPageTranslations: boolean,
): Page => {
  return {
    id: PageTypes.BLANK_PRIVATE,
    name: shouldSplitCustomPageTranslations
      ? t('ManageMemberPages_V3_Custom_Private_Name')
      : t('ManageMemberPages_Custom_Private_Name'),
    shortDescription: shouldSplitCustomPageTranslations
      ? t('ManageMemberPages_V3_Custom_Private_Shortdesc')
      : t('ManageMemberPages_Custom_Private_Shortdesc'),
    description: shouldSplitCustomPageTranslations
      ? t('ManageMemberPages_V3_Custom_Private_Desc')
      : t('ManageMemberPages_Custom_Private_Desc'),
    iconURL: './icons/Members Area.svg',
    origin: shouldSplitCustomPageTranslations
      ? t('ManageMemberPages_V3_Custom_Private_Origin')
      : t('ManageMemberPages_Custom_Private_Origin'),
    parent: {
      social: false,
      pageId: 'custom_private',
      appDefinitionId: SANTA_MEMBERS_APP_ID,
    },
    isNew: false,
    screenshots: [
      './img/split_blank_private.png',
      './img/split_blank_private-2.png',
      './img/split_blank_private_mobile.png',
      './img/split_blank_private_mobile-2.png',
    ],
  };
};

export const getTabs = (t: TranslationFunction): Item[] => {
  return [
    { id: PagePrivacy.Private, title: t('AddPagesModal_PrivatePages_Tab'), dataHook: 'privateTab' },
    { id: PagePrivacy.Public, title: t('AddPagesModal_PublicPages_Tab'), dataHook: 'publicTab' },
  ];
};

export const getUnifiedManagerPrivateCustomPageDefinitions = (
  t: TranslationFunction,
  shouldSplitCustomPageTranslations: boolean,
): UnifiedManagerApplication => {
  return {
    id: PageTypes.BLANK_PRIVATE,
    page: {
      name: shouldSplitCustomPageTranslations
        ? t('ManageMemberPages_V3_Custom_Private_Name')
        : t('ManageMemberPages_Custom_Private_Name'),
      shortDescription: shouldSplitCustomPageTranslations
        ? t('ManageMemberPages_V3_Custom_Private_Shortdesc')
        : t('ManageMemberPages_Custom_Private_Shortdesc'),
      description: shouldSplitCustomPageTranslations
        ? t('ManageMemberPages_V3_Custom_Private_Desc')
        : t('ManageMemberPages_Custom_Private_Desc'),
      iconURL: './icons/Members Area.svg',
      origin: shouldSplitCustomPageTranslations
        ? t('ManageMemberPages_V3_Custom_Private_Origin')
        : t('ManageMemberPages_Custom_Private_Origin'),
      screenshots: [
        './img/split_blank_private.png',
        './img/split_blank_private-2.png',
        './img/split_blank_private_mobile.png',
        './img/split_blank_private_mobile-2.png',
      ],
      isNew: false,
    },
    social: false,
    pageId: 'custom_private',
    appDefinitionId: SANTA_MEMBERS_APP_ID,
    widgetId: '' as WidgetId,
    isRemovable: true,
    isInstalled: false,
  };
};
