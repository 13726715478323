import { GetAppManifest } from '@wix/platform-editor-sdk';

import { getTranslationFunction } from '../../../../i18n';
import { getAppDescriptor } from './app-descriptor';
import { getPagesManifest } from './page-manifest';
import { getControllersStageDataManifest } from './controllers-stage-data-manifest';

export const getAppManifest: GetAppManifest<unknown> = async (_, editorSDK) => {
  const t = await getTranslationFunction(editorSDK, true);

  return {
    appDescriptor: await getAppDescriptor(t),
    pages: await getPagesManifest(editorSDK, t),
    controllersStageData: getControllersStageDataManifest(t),
  };
};
