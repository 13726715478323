import type { EditorSDK, PageData } from '@wix/platform-editor-sdk';
import { AppDefinitionId, InstallLocation, MA_APP_IDS, PageId, WidgetId } from '@wix/members-area-app-definitions';
import type { TranslationFunction } from 'i18next';

import { toUnifiedManagerApplications, toIntegrationApplication } from '../../../../panels/utils/applicationsProcessor';
import type { UnifiedManagerApplication, UnifiedManagerData } from '../../../../types';
import { getTranslationFunction } from '../../../../i18n';
import { getUnifiedManagerPrivateCustomPageDefinitions } from '../../../../panels/split-ma/addPages/utils';
import { shouldSplitCustomPageTranslations } from '../../../../utils/experiments';
import { PageTypes } from '../../../../types/page';
import { getDefinitions } from '../../../wrappers/definitions';
import { getAllIntegratedApps } from '../../../services/applicationState';
import { getPageRefByTPAPageId, isMemberProfilePageInstalled } from '../../../wrappers/tpa';
import { getInstalledApplications, isApplicationInstalled } from '../integration/get-apps';

type GroupedApplicationPages = {
  applicationPagesWithDefinitions: PageData[];
  customPages: PageData[];
};

type CustomPagesToApplicationsProps = {
  editorSDK: EditorSDK;
  customPages: PageData[];
  t: TranslationFunction;
};

type GroupedUnifiedManagerApplications = {
  standalonePages: UnifiedManagerApplication[];
  memberPageTabs: UnifiedManagerApplication[];
  settingsPageTabs: UnifiedManagerApplication[];
};

const UNREMOVABLE_APP_DEFINITION_IDS = {
  [AppDefinitionId.Notifications]: true,
  [AppDefinitionId.MyAccount]: true,
  [AppDefinitionId.Settings]: true,
  [AppDefinitionId.About]: true,
};

const UNREMOVABLE_TPA_PAGE_IDS = {
  [PageId.Notifications]: true,
  [PageId.SettingsPage]: true,
};

const groupApplicationPages = (applicationPages: PageData[]) => {
  return applicationPages.reduce<GroupedApplicationPages>(
    (currentGroup, page) => {
      if (page.appDefinitionId) {
        return {
          ...currentGroup,
          applicationPagesWithDefinitions: [...currentGroup.applicationPagesWithDefinitions, page],
        };
      }
      return {
        ...currentGroup,
        customPages: [...currentGroup.customPages, page],
      };
    },
    {
      applicationPagesWithDefinitions: [],
      customPages: [],
    },
  );
};

const customPagesToApplications = ({ editorSDK, customPages, t }: CustomPagesToApplicationsProps) => {
  const customPageApplications = customPages.map<Promise<UnifiedManagerApplication>>(async (customPage) => {
    const pageRef = await editorSDK.components.getById('', { id: customPage.id ?? '' });

    return {
      id: PageTypes.BLANK_PRIVATE,
      page: {
        name: customPage.title,
        shortDescription: t('UnifiedManagerPanel_V3_CustomPage_Title'),
        description: '',
        origin: '',
        screenshots: [],
        iconURL: `./icons/Members Area.svg`,
      },
      pageId: customPage.tpaPageId ?? '',
      pageRef,
      appDefinitionId: customPage.appDefinitionId ?? '',
      widgetId: '' as WidgetId,
      isInstalled: true,
      isRemovable: true,
    };
  });
  return Promise.all(customPageApplications);
};

const getPagesMaAppIds = (pages: Pick<PageData, 'appDefinitionId' | 'tpaPageId'>[]) =>
  pages.map((page) => ({
    appDefinitionId: page.appDefinitionId ?? '',
    pageId: page.tpaPageId,
  }));

export const getUnifiedManagerPanelData = async (editorSDK: EditorSDK): Promise<UnifiedManagerData> => {
  const applicationPages = await editorSDK.document.pages.getApplicationPages('');
  const { applicationPagesWithDefinitions, customPages } = groupApplicationPages(applicationPages);

  const applicationPagesIds = getPagesMaAppIds(applicationPagesWithDefinitions);
  const applicationPagesDefinitions = await getDefinitions(editorSDK, applicationPagesIds);
  const pageApplicationsWithoutRules = toUnifiedManagerApplications(applicationPagesDefinitions);
  const pageApplications = await Promise.all(
    pageApplicationsWithoutRules.map<Promise<UnifiedManagerApplication>>(async (application) => {
      const applicationPageTitle = applicationPagesWithDefinitions.find(
        (page) => page.tpaPageId === application.pageId,
      )!.title;

      if (UNREMOVABLE_TPA_PAGE_IDS[application.pageId]) {
        return {
          ...application,
          page: {
            ...application.page,
            name: applicationPageTitle,
          },
          pageRef: await getPageRefByTPAPageId(editorSDK, application.pageId ?? ''),
          isRemovable: false,
          isInstalled: true,
        };
      }
      return {
        ...application,
        page: {
          ...application.page,
          name: applicationPageTitle,
        },
        pageRef: await getPageRefByTPAPageId(editorSDK, application.pageId ?? ''),
        isRemovable: true,
        isInstalled: true,
      };
    }),
  );

  const t = await getTranslationFunction(editorSDK, true);
  const splitCustomPageTranslations = await shouldSplitCustomPageTranslations();
  const customPageApplications = [
    ...(await customPagesToApplications({ editorSDK, customPages, t })),
    await getUnifiedManagerPrivateCustomPageDefinitions(t, splitCustomPageTranslations),
  ];

  const allIntegratedApps = toIntegrationApplication(await getAllIntegratedApps());
  const unifiedManagerApplications = toUnifiedManagerApplications(allIntegratedApps);
  const installedApplications = await getInstalledApplications(editorSDK);

  const {
    memberPageTabs,
    settingsPageTabs,
    standalonePages: nonInstalledPageApplications,
  } = unifiedManagerApplications.reduce<GroupedUnifiedManagerApplications>(
    (groupedApplications, application) => {
      const isInstalled = isApplicationInstalled(application, installedApplications);

      switch (application.installLocation) {
        case InstallLocation.Standalone: {
          if (isInstalled) {
            // We get installed pages from editorSDK above
            return groupedApplications;
          }
          groupedApplications.standalonePages.push({
            ...application,
            isRemovable: !UNREMOVABLE_TPA_PAGE_IDS[application.pageId],
            isInstalled,
          });
          break;
        }
        case InstallLocation.Settings: {
          groupedApplications.settingsPageTabs.push({
            ...application,
            isRemovable: !UNREMOVABLE_APP_DEFINITION_IDS[application.appDefinitionId],
            isInstalled,
          });
          break;
        }
        case InstallLocation.Profile: {
          groupedApplications.memberPageTabs.push({
            ...application,
            isRemovable: !UNREMOVABLE_APP_DEFINITION_IDS[application.appDefinitionId],
            isInstalled,
          });
          break;
        }
        case InstallLocation.Lightbox: {
          return groupedApplications;
        }
      }
      return groupedApplications;
    },
    { standalonePages: [], memberPageTabs: [], settingsPageTabs: [] },
  );

  const shouldAddProfilePageDefinition = !(await isMemberProfilePageInstalled(editorSDK));
  const [memberProfilePageDefinition, profileWidgetDefinitions, myAccountWidgetDefinitions] = await getDefinitions(
    editorSDK,
    [MA_APP_IDS.MEMBER_PAGE, MA_APP_IDS.ABOUT, MA_APP_IDS.MY_ACCOUNT],
  );
  const profileWidgetAsProfilePage: UnifiedManagerApplication = {
    ...profileWidgetDefinitions,
    isInstalled: false,
    isRemovable: true,
    page: memberProfilePageDefinition.page!,
  };

  return {
    pages: {
      title: t('UnifiedManagerPanel_PagesTab_V3_Title'),
      description: t('UnifiedManagerPanel_PagesTab_V3_Description'),
      applications: [
        ...pageApplications,
        ...customPageApplications,
        ...nonInstalledPageApplications,
        ...(shouldAddProfilePageDefinition ? [profileWidgetAsProfilePage] : []),
      ],
    },
    settingsPageTabs: {
      title: t('UnifiedManagerPanel_SettingsTab_V3_Title'),
      description: t('UnifiedManagerPanel_SettingsTab_V3_Description'),
      applications: settingsPageTabs,
      applicationToNavigateOnRemoval: myAccountWidgetDefinitions,
    },
    ...(shouldAddProfilePageDefinition
      ? {}
      : {
          memberPageTabs: {
            title: t('UnifiedManagerPanel_ProfileTab_V3_Title'),
            description: t('UnifiedManagerPanel_ProfileTab_V3_Description'),
            applications: memberPageTabs,
            applicationToNavigateOnRemoval: profileWidgetDefinitions,
          },
        }),
  };
};
