import { EditorSDK } from '@wix/platform-editor-sdk';

import { APP_TOKEN } from '../../../constants';
import { getCurrentPage, getMembersAreaPage } from '../../../wrappers/pages';

export const navigateToMembersAreaPage = async (editorSDK: EditorSDK) => {
  const currentPage = await getCurrentPage({ editorSDK });
  const membersAreaPage = await getMembersAreaPage(editorSDK);

  if (currentPage.id === membersAreaPage.id) {
    return;
  }

  if (membersAreaPage) {
    await editorSDK.pages.navigateTo(APP_TOKEN, { pageRef: { id: membersAreaPage.id!, type: 'DESKTOP' } });
  }
};
