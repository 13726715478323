import type { EditorSDK, TPAPublicDataValue } from '@wix/platform-editor-sdk';

import { APP_TOKEN } from '../../../constants';

type PublicAppData<TKey extends string, TValue> = Record<TKey, TValue>;

export const getAppData = async <TKey extends string, TValue>(editorSDK: EditorSDK, key: TKey) => {
  let data: TValue | null = null;

  try {
    const response = await editorSDK.document.tpa.data.getAppPublicData(APP_TOKEN, { key });
    const publicData: PublicAppData<TKey, TValue> = response as unknown as PublicAppData<TKey, TValue>;
    data = publicData[key] ?? null;
  } catch {}

  return data;
};

export const setAppData = <TKey extends string, TValue>(editorSDK: EditorSDK, key: TKey, value: TValue) => {
  return editorSDK.document.tpa.data.setAppPublicData(APP_TOKEN, { key, value: value as TPAPublicDataValue });
};
