import { EditorSDK } from '@wix/platform-editor-sdk';

import { getMembersPageRef } from './members-area-page';

type Section = {
  appDefinitionId: string;
  appPageId: string;
};

export const navigateToSection = async (editorSDK: EditorSDK, section: Section) => {
  const pageRef = await getMembersPageRef(editorSDK, section);

  if (!pageRef) {
    throw new Error('[MA V1] PublicAPI.navigateToSection could not find page ref');
  }

  return editorSDK.document.pages.navigateTo('', { pageRef });
};
