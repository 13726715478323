import { MEMBERS_AREA } from '@wix/app-definition-ids';
import { EditorSDK } from '@wix/platform-editor-sdk';

import { APP_TOKEN } from '../constants';

const getAssetsBaseUrlFromEditorScriptUrl = (editorScriptUrl: string) => {
  const [baseUrl] = editorScriptUrl.split('/editorAppModule.bundle.min.js');
  return baseUrl;
};

const getAssetsBaseUrl = async (editorSDK: EditorSDK) => {
  if (process.env.NODE_ENV === 'development') {
    return 'https://localhost:5200';
  }

  const appData = await editorSDK.tpa.app.getDataByAppDefId(APP_TOKEN, MEMBERS_AREA);
  const editorScriptUrl = appData?.appFields?.platform?.editorScriptUrl;
  if (editorScriptUrl) {
    return getAssetsBaseUrlFromEditorScriptUrl(editorScriptUrl);
  }

  return '.';
};

export const buildPanelUrl = async (editorSDK: EditorSDK, panelFileName: string, query?: string) => {
  const baseUrl = await getAssetsBaseUrl(editorSDK);
  return `${baseUrl}/assets/${panelFileName}${query ? query : ''}`;
};
