import {
  COMMON_LOGIN_BAR_COMP_DATA_OLD,
  COMMON_LOGIN_BAR_STYLE_PROPERTIES,
  COMMON_LOGIN_COMP_CONNECTIONS,
  COMMON_LOGIN_COMP_CONNECTIONS_V2,
  COMMON_LOGIN_COMP_PROPS,
} from './common-login-bar-layouts';

export const LOGIN_BAR_CONTROLLER_TYPE = 'members-login-bar';

const LOGIN_COMP_APP_WIDGET_LAYOUT = {
  width: 120,
  height: 40,
  x: 850,
  y: 56,
  scale: 1,
  rotationInDegrees: 0,
  fixedPosition: false,
};

const LOGIN_COMP_APP_WIDGET_ECOM_LAYOUT = {
  x: 818,
  y: 56,
  fixedPosition: false,
  width: 120,
  height: 40,
  scale: 1,
  rotationInDegrees: 0,
};

const LOGIN_COMP_APP_WIDGET = {
  componentType: 'platform.components.AppWidget',
  style: {
    skin: 'platform.components.skins.AppWidgetSkin',
    style: {},
  },
  type: 'Container',
  layout: LOGIN_COMP_APP_WIDGET_LAYOUT,
  data: {
    type: 'AppController',
    applicationId: '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9',
    name: 'login-bar-controller',
    controllerType: LOGIN_BAR_CONTROLLER_TYPE,
    id: 'login-bar-controller-id',
  },
};

const LOGIN_COMP_APP_WIDGET_ECOM = {
  ...LOGIN_COMP_APP_WIDGET,
  layout: LOGIN_COMP_APP_WIDGET_ECOM_LAYOUT,
};

const LOGIN_COMP_STYLE = {
  type: 'TopLevelStyle',
  style: {
    properties: COMMON_LOGIN_BAR_STYLE_PROPERTIES,
    propertiesSource: {},
    groups: {},
  },
  componentClassName: 'wysiwyg.viewer.components.LoginSocialBar',
  pageId: '',
  styleType: 'custom',
  skin: 'wysiwyg.viewer.skins.LoginSocialBarSkin',
};

const LOGIN_COMP_LAYOUT = {
  width: 120,
  height: 40,
  scale: 1,
  rotationInDegrees: 0,
  fixedPosition: false,
  x: 0,
  y: 0,
};

const APP_WIDGET_COMPONENTS = [
  {
    type: 'Component',
    skin: 'wysiwyg.viewer.skins.LoginSocialBarSkin',
    componentType: 'wysiwyg.viewer.components.LoginSocialBar',
    data: COMMON_LOGIN_BAR_COMP_DATA_OLD,
    props: COMMON_LOGIN_COMP_PROPS,
    style: LOGIN_COMP_STYLE,
    layout: LOGIN_COMP_LAYOUT,
    connections: COMMON_LOGIN_COMP_CONNECTIONS,
  },
];

const APP_WIDGET_COMPONENTS_V2 = [
  {
    ...APP_WIDGET_COMPONENTS[0],
    connections: COMMON_LOGIN_COMP_CONNECTIONS_V2,
  },
];

const LOGIN_COMP_APP_WIDGET_DEF = {
  ...LOGIN_COMP_APP_WIDGET,
  components: APP_WIDGET_COMPONENTS,
};

const LOGIN_COMP_APP_WIDGET_DEF_V2 = {
  ...LOGIN_COMP_APP_WIDGET,
  components: APP_WIDGET_COMPONENTS_V2,
};

const LOGIN_COMP_APP_WIDGET_DEF_ECOM = {
  ...LOGIN_COMP_APP_WIDGET_ECOM,
  components: APP_WIDGET_COMPONENTS,
};

const LOGIN_COMP_APP_WIDGET_DEF_ECOM_V2 = {
  ...LOGIN_COMP_APP_WIDGET_ECOM,
  components: APP_WIDGET_COMPONENTS_V2,
};

export {
  LOGIN_COMP_APP_WIDGET_DEF,
  LOGIN_COMP_APP_WIDGET_DEF_ECOM,
  LOGIN_COMP_APP_WIDGET_DEF_V2,
  LOGIN_COMP_APP_WIDGET_DEF_ECOM_V2,
};
