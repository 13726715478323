import { AppManifest } from '@wix/platform-editor-sdk';
import { TranslationFunction } from 'i18next';

import { AppManagerAction } from '../../types';
import { shouldUseCustomizeMemberPageCTA } from '../../../../utils/experiments';

type CustomAppActions = NonNullable<AppManifest['appDescriptor']>['customActions'];

type MainAppActions = NonNullable<AppManifest['appDescriptor']>['mainActions'];

const getMainAppActions = async (t: TranslationFunction): Promise<MainAppActions> => {
  const shouldUseNewCTA = await shouldUseCustomizeMemberPageCTA();

  if (shouldUseNewCTA) {
    return [
      {
        actionId: AppManagerAction.OpenCustomizeMemberPage,
        icon: 'appManager_settingsAction',
        title: t('Apps_Manager_Customize_Member_Page'),
      },
    ];
  }

  return [
    {
      actionId: AppManagerAction.OpenMembersAccountBmDashboard,
      icon: 'appManager_settingsAction',
      title: t('Apps_Manager_Customize_Members_Profiles'),
    },
  ];
};

const getCustomAppActions = async (t: TranslationFunction): Promise<CustomAppActions> => [
  {
    actionId: AppManagerAction.OpenMembersAreaPagesPanel,
    type: 'editorActions',
    icon: 'appManager_pagesAction',
    title: t('Apps_Manager_Manage_Member_Pages'),
  },
  {
    actionId: AppManagerAction.OpenMembersAddPanel,
    type: 'editorActions',
    icon: 'appManager_addElementsAction',
    title: t('Apps_Manager_Open_Members_Add_Panel'),
  },
  {
    actionId: AppManagerAction.OpenBadgesDashboard,
    type: 'dashboard',
    icon: 'appManager_settingsAction',
    title: t('Apps_Manager_Open_Badges_Page'),
  },
  {
    actionId: AppManagerAction.OpenSiteMembersDashboard,
    type: 'dashboard',
    icon: 'appManager_settingsAction',
    title: t('Apps_Manager_Engage_With_Your_Members'),
  },
];

export const getAppDescriptor = async (t: TranslationFunction): Promise<AppManifest['appDescriptor']> => {
  return {
    mainActions: await getMainAppActions(t),
    defaultActions: { learnMoreKB: 'b74bcbba-91c8-4951-a3de-a1dc698f6356' },
    customActions: await getCustomAppActions(t),
  };
};
