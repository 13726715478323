import type { EditorSDK } from '@wix/platform-editor-sdk';

import { log } from '../../../../utils/monitoring';
import { APP_TOKEN } from '../../../constants';
import { createController, getGlobalControllerRef } from '../../../wrappers/controllers';
import { updateGlobalController } from '../../../services/controllers';

export const addGlobalController = async (editorSDK: EditorSDK) => {
  const masterRef = await editorSDK.siteSegments.getSiteStructure(APP_TOKEN);

  if (!masterRef) {
    return;
  }

  const controllerRef = await createController(editorSDK, masterRef);

  await updateGlobalController({
    editorSDK,
    controllerRef,
    newConfig: { isMembersAreaSeoEnabled: true },
  });
};

export const removeGlobalControllerIfExists = async (editorSDK: EditorSDK) => {
  try {
    const controllerRef = await getGlobalControllerRef(editorSDK);

    if (controllerRef) {
      log('[SplitMA] removing global controller');
      await editorSDK.components.remove(APP_TOKEN, { componentRef: controllerRef });
    }
  } catch (e) {
    log('[SplitMA] Error occurred in removeGlobalControllerIfExists', { extra: { error: JSON.stringify(e) } });
  }
};
