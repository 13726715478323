import type { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';

import { APP_TOKEN } from '../constants';
import type {
  AnyRecord,
  OldMemberMenuSettings,
  RootStyleData,
  VerticalMemberMenuMigrationData,
} from './v2-migration-types';
import { removeUndefinedProps } from './v2-migration-utils';

const skinsWithoutBorders = new Set(['wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuTextSkin']);

const skinsWithVerticalSeparators = new Set([
  'wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSolidColorSkin',
  'wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuTextWithSeparatorsSkin',
]);

const skinsWithoutBoxShadow = new Set([
  'wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSeparatedButtonFixedWidthSkin',
  'wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuTextSkin',
  'wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSeparatedButtonSkin',
  'wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuTextWithSeparatorsSkin',
]);

export const getStylePropsOverrides = (oldStylePropsOverride: Record<string, object>) => {
  const overrides: {
    fnt?: object;
    fntSubMenu?: object;
  } = {};

  if (oldStylePropsOverride.fnt) {
    overrides.fnt = {
      ...oldStylePropsOverride.fnt,
    };
  }

  if (oldStylePropsOverride.SKINS_fntSubmenu) {
    overrides.fntSubMenu = {
      ...oldStylePropsOverride.SKINS_fntSubmenu,
    };
  }

  return overrides;
};

const getBoxShadowProps = (oldSkin: string, oldStyleProps: AnyRecord) => {
  if (skinsWithoutBoxShadow.has(oldSkin)) {
    return {};
  }

  return {
    'boxShadowToggleOn-shd': oldStyleProps['boxShadowToggleOn-shd'],
    shd: oldStyleProps.shd,
  };
};

const getBorderWidth = (oldSkin: string, oldStyleProps: AnyRecord) => {
  const noBorderSettings = skinsWithoutBorders.has(oldSkin);
  const hasSeparatorSettings = skinsWithVerticalSeparators.has(oldSkin);
  const skipBorder = noBorderSettings || (hasSeparatorSettings && parseInt(oldStyleProps.sepw, 10) === 0);

  if (skipBorder) {
    return '0px';
  }

  if (hasSeparatorSettings) {
    return `0px 0px ${oldStyleProps.sepw}px 0px`;
  }

  const borderWidth = oldStyleProps.brw ?? 0;

  return `${borderWidth}px`;
};

const getSubMenuSpacing = (oldStyleProps: AnyRecord) => {
  const spacing = parseInt(oldStyleProps.textSpacing, 10) - 5;
  return spacing < 0 ? 0 : spacing;
};

const getStylePropsToMigrate = (oldStyle: RootStyleData) => {
  const oldSkin = oldStyle.skin;
  const oldStyleProps = oldStyle.style.properties;

  const boxShadowProps = getBoxShadowProps(oldSkin, oldStyleProps);

  const borderProps = {
    brd: oldStyleProps.brd,
    'alpha-brd': oldStyleProps['alpha-brd'],
    brw: getBorderWidth(oldSkin, oldStyleProps),
  };

  const textProps = {
    txt: oldStyleProps.txt,
    txtexpanded: oldStyleProps.txt,
    fnt: oldStyleProps.fnt,
    fntSubMenu: oldStyleProps.SKINS_fntSubmenu,
  };

  const spacingProps = {
    menuSpacing: oldStyleProps.textSpacing,
    subMenuSpacing: getSubMenuSpacing(oldStyleProps),
  };

  const regularStateColors = {
    bg: oldStyleProps.bg,
    'alpha-bg': oldStyleProps['alpha-bg'],
    bgexpanded: oldStyleProps.SKINS_bgSubmenu,
    'alpha-bgexpanded': oldStyleProps['alpha-SKINS_bgSubmenu'],
    arrowColor: oldStyleProps.txt,
  };

  const selectedStateColors = {
    bgs: oldStyleProps.bgs,
    'alpha-bgs': oldStyleProps['alpha-bg'],
    bgsSub: oldStyleProps.bgs,
    'alpha-bgsSub': oldStyleProps['alpha-bg'],
    txts: oldStyleProps.txts,
    txtsSub: oldStyleProps.txts,
  };

  return removeUndefinedProps({
    ...regularStateColors,
    ...borderProps,
    ...boxShadowProps,
    ...textProps,
    ...spacingProps,
    ...selectedStateColors,
  });
};

const migrateProps = async (
  editorSDK: EditorSDK,
  { componentRef, props }: { componentRef: ComponentRef; props: OldMemberMenuSettings['props'] },
) => {
  await editorSDK.components.properties.update(APP_TOKEN, {
    componentRef,
    props: {
      itemsAlignment: props.itemsAlignment,
      menuArrowDirection: props.subMenuOpenSide,
    },
  });
};

const migrateStyles = async (
  editorSDK: EditorSDK,
  {
    componentRef,
    oldStyle,
    newStyle,
  }: {
    componentRef: ComponentRef;
    oldStyle: RootStyleData;
    newStyle: RootStyleData;
  },
) => {
  const newStyleProps = newStyle.style.properties;

  const stylePropsToMigrate = getStylePropsToMigrate(oldStyle);
  const stylePropsOverridesToMigrate = getStylePropsOverrides(oldStyle.style.propertiesOverride || {});

  await editorSDK.components.style.updateFull(APP_TOKEN, {
    componentRef,
    style: {
      ...newStyle,
      style: {
        ...newStyle.style,
        properties: {
          ...newStyleProps,
          ...stylePropsToMigrate,
        },
        propertiesOverride: stylePropsOverridesToMigrate,
      },
    },
  });
};

export const migrateVerticalMemberMenuComponent = async (
  editorSDK: EditorSDK,
  { oldMemberMenuComponentSettings, newMemberMenuComponentData }: VerticalMemberMenuMigrationData,
) => {
  const { componentRef } = newMemberMenuComponentData;

  await migrateProps(editorSDK, {
    componentRef,
    props: oldMemberMenuComponentSettings.props,
  });

  await migrateStyles(editorSDK, {
    componentRef,
    oldStyle: oldMemberMenuComponentSettings.style,
    newStyle: newMemberMenuComponentData.style,
  });
};
