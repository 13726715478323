import { EventType } from '@wix/platform-editor-sdk';

import { MembersAreaEvent, MembersAreaOnEventFn } from '../types';
import { ReferralInfo } from '../../../types/bi';
import { toMonitored } from '../../../utils/monitoring';
import { APP_TOKEN, RENAME_ROUTER_PANEL_HEIGHT, SANTA_MEMBERS_APP_ID } from '../../constants';
import { BADGES_BM_URL, MEMBERS_ACCOUNT_BM_URL, SITE_MEMBERS_BM_URL } from '../../constants/routes';
import enforceSequentiality from '../../enforceSequentiality';
import * as applicationState from '../../applicationState';
import { openRemovePagePanel, openUninstallPanel } from '../../wrappers/platformPanels';
import {
  openGeneralSettingsPanel,
  openManagePagesPanel,
  openMemberPrivacySettingsDashboard,
} from '../../wrappers/panels';
import { handleCompAddedToStage } from '../../wrappers/components';
import { publishSettingsForMembersAreaApps } from '../../services/members-area';
import { handleVerticalSectionDeletion } from '../../services/integration';
import { hasSocialPages } from '../../services/pages';
import { buildPanelUrl } from '../../services/panel-url-builder';

export const onEvent: MembersAreaOnEventFn = async ({ eventType, eventPayload }, editorSDK) => {
  enforceSequentiality('onEventHandler', async () => {
    const isReady = await applicationState.isApplicationReady(editorSDK, { shouldLog: false });
    const isUninstallEvent = eventType === MembersAreaEvent.Uninstall;
    const shouldHandleEvent = isUninstallEvent || isReady;

    if (!shouldHandleEvent) {
      return;
    }

    try {
      switch (eventType) {
        case MembersAreaEvent.GeneralSettings: {
          openGeneralSettingsPanel({
            eventPayload: {
              ...eventPayload,
              referralInfo: ReferralInfo.ActionsMenu,
            },
            editorSDK,
          });
          break;
        }
        case MembersAreaEvent.MemberPrivacyDashboard: {
          openMemberPrivacySettingsDashboard(editorSDK);
          break;
        }
        case MembersAreaEvent.CreateBlankPage: {
          openManagePagesPanel({ eventPayload, editorSDK });
          break;
        }
        case MembersAreaEvent.ManagePages: {
          openManagePagesPanel({ eventPayload: undefined, editorSDK });
          break;
        }
        case EventType.pageDeleted: {
          // Handling when a verticals TPA section is being deleted - removes menu items and router patterns, the page is removed by platform
          // Separate applications' deletion (added with "addApplication") is handled by handleVerticalDeletion
          // To do: ask Editor Platform to do this on their side when deleting
          // Seems like we can't return the promise because it then does not execute on e.g. Bookings deletion..
          enforceSequentiality('handleVerticalSectionDeletion', () =>
            toMonitored('handleVerticalSectionDeletion', () =>
              handleVerticalSectionDeletion(editorSDK, eventPayload.pageRole),
            ),
          );
          break;
        }
        case MembersAreaEvent.Uninstall: {
          openUninstallPanel(editorSDK);
          break;
        }
        case MembersAreaEvent.RemovePage: {
          openRemovePagePanel(editorSDK, eventPayload.pageRef);
          break;
        }
        case MembersAreaEvent.RenameRouter: {
          hasSocialPages(editorSDK).then(async (containsSocialPages) => {
            const height = containsSocialPages ? RENAME_ROUTER_PANEL_HEIGHT + 150 : RENAME_ROUTER_PANEL_HEIGHT;
            const _routers = await editorSDK.routers.getAll(APP_TOKEN);

            editorSDK.editor.openModalPanel(APP_TOKEN, {
              url: await buildPanelUrl(editorSDK, 'renameRouter.html'),
              width: 744,
              height,
              shouldHideHeader: true,
              initialData: { routers: _routers, ...eventPayload },
            });
          });
          break;
        }
        case EventType.componentAddedToStage: {
          await handleCompAddedToStage(editorSDK, eventPayload.compRef);
          break;
        }
        case EventType.siteWasPublished: {
          publishSettingsForMembersAreaApps(editorSDK);
          break;
        }

        // Apps manager
        case EventType.appActionClicked: {
          switch (eventPayload && eventPayload.actionId) {
            case 'openMembersAreaPagesPanel':
              editorSDK.editor.deeplink.show(APP_TOKEN, {
                type: 'pagesPanel',
                params: [SANTA_MEMBERS_APP_ID],
              });
              break;
            case 'addMemberPage': {
              openManagePagesPanel({ eventPayload, editorSDK });
              break;
            }
            case 'openMembersAddPanel':
              editorSDK.editor.deeplink.show(APP_TOKEN, {
                type: 'addPanel',
                params: [SANTA_MEMBERS_APP_ID],
              });
              break;
            case 'openMembersAccountBmDashboard':
              editorSDK.editor.openDashboardPanel(APP_TOKEN, { url: MEMBERS_ACCOUNT_BM_URL, closeOtherPanels: true });
              break;
            case 'openBadgesPage':
              editorSDK.editor.openDashboardPanel(APP_TOKEN, { url: BADGES_BM_URL, closeOtherPanels: true });
              break;
            case 'openSiteMembersDashboard':
              editorSDK.editor.openDashboardPanel(APP_TOKEN, { url: SITE_MEMBERS_BM_URL, closeOtherPanels: true });
              break;
            default:
              break;
          }
          break;
        }
        /* end of possibly unused events */
        default: {
          console.log(eventType, eventPayload);
        }
      }
    } catch (e) {
      throw e;
    }
  });
};
