import { InstallLocation, IntegrationApplication } from '@wix/members-area-app-definitions';

import { IntegrationApplicationWithoutWidgetId } from '../../../../types/general-settings';

export const groupDefinitionsByMethod = (
  definitions: IntegrationApplication[] | IntegrationApplicationWithoutWidgetId[],
) => {
  const definitionsGroupedByMethod = {
    widgetPlugins: [] as IntegrationApplication[],
    lightboxes: [] as IntegrationApplication[],
    siteApps: [] as IntegrationApplicationWithoutWidgetId[],
  };

  definitions.forEach((definition) => {
    if (definition.method === 'addApplicationToSite') {
      definitionsGroupedByMethod.siteApps.push(definition);
    } else if (definition.installLocation === InstallLocation.Lightbox) {
      definitionsGroupedByMethod.lightboxes.push(definition as IntegrationApplication);
    } else {
      definitionsGroupedByMethod.widgetPlugins.push(definition as IntegrationApplication);
    }
  });

  return definitionsGroupedByMethod;
};
