import { type EditorSDK, EventType } from '@wix/platform-editor-sdk';

import { HistoryLabel } from '../../../wrappers/history';
import { uninstallMembersArea } from '../services/uninstall';

export const addUndoListener = async (editorSDK: EditorSDK) => {
  return editorSDK.addEventListener(EventType.undo, async ({ detail }) => {
    if (detail.label === HistoryLabel.InstallV2) {
      await uninstallMembersArea(editorSDK);
    }
  });
};
