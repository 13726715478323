import { EditorSDK } from '@wix/platform-editor-sdk';

import { RouteConfiguration } from '../../../../types/controllers';
import { APP_TOKEN } from '../../../constants';

const ROUTES_KEY = 'routes';

type PublicAppData = {
  routes?: RouteConfiguration[];
};

export const getRoutesFromAppData = async (editorSDK: EditorSDK) => {
  let routes: RouteConfiguration[] | null = null;

  try {
    const publicData: PublicAppData = await editorSDK.document.tpa.data.getAppPublicData(APP_TOKEN, {
      key: ROUTES_KEY,
    });

    routes = publicData.routes ?? null;
  } catch {}

  return routes;
};

export const setRoutesInAppData = (editorSDK: EditorSDK, routes: RouteConfiguration[]) => {
  return editorSDK.document.tpa.data.setAppPublicData(APP_TOKEN, {
    key: ROUTES_KEY,
    // @ts-expect-error
    value: routes,
  });
};
