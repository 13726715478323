import type { RouterData, MenuItem, EditorSDK } from '@wix/platform-editor-sdk';
import type { TpaPageLink, DynamicPageLink } from '@wix/document-services-types';
import { AppDefinitionId, MA_APP_IDS, WidgetId } from '@wix/members-area-app-definitions';
import { PageId } from '@wix/members-area-app-definitions/src/members-area-apps/ids/page';
import { HttpClient } from '@wix/http-client';

import { type MembersAreaMenus, updateMenuItems, getMembersAreaMenus } from '../wrappers/menus';
import { APP_TOKEN, MENU_IDS, MY_ACCOUNT_WIDGET_ID } from '../constants';

export type MigrateMenuItemsProps = {
  routers: RouterData[];
  oldMenuItems: MenuItem[];
  newMenuItems: MenuItem[];
  menuId: string;
  editorSDK: EditorSDK;
  translatedMenuItemsLabels: Record<string, string>;
};

export type RouteAppData = {
  appDefinitionId: AppDefinitionId;
  appPageId: PageId;
};

export type MenuItemLabelData = {
  translationKey: string;
  labelToTrim?: string;
};

const MEMBERS_AREA_APPS_IDS = [
  MA_APP_IDS.ABOUT,
  MA_APP_IDS.MY_ACCOUNT,
  MA_APP_IDS.BLOG_LIKES,
  MA_APP_IDS.BLOG_COMMENTS,
  MA_APP_IDS.BLOG_POSTS,
  MA_APP_IDS.FILE_SHARE,
  MA_APP_IDS.FOLLOWERS,
  MA_APP_IDS.FORUM_COMMENTS,
  MA_APP_IDS.FORUM_POSTS,
  MA_APP_IDS.MY_ADDRESSES,
  MA_APP_IDS.MY_BOOKINGS,
  MA_APP_IDS.MY_DRAFTS,
  MA_APP_IDS.MY_POSTS,
  MA_APP_IDS.MY_EVENTS,
  MA_APP_IDS.MY_ORDERS,
  MA_APP_IDS.MY_WALLET,
  MA_APP_IDS.MY_WISHLIST,
  MA_APP_IDS.NOTIFICATIONS,
  MA_APP_IDS.SETTINGS,
  MA_APP_IDS.SHARED_GALLERY,
  MA_APP_IDS.MY_SUBSCRIPTIONS,
  MA_APP_IDS.MY_CHALLENGES,
  MA_APP_IDS.MY_PROGRAMS,
  MA_APP_IDS.MY_REWARDS,
] as const;

const menuItemsLabelsByWidgetId = {
  [WidgetId.MyAccount]: {
    translationKey: 'member-pages.my-account.name',
    labelToTrim: 'My Account',
  },
  [WidgetId.About]: {
    translationKey: 'member-pages.about.name',
  },
  [WidgetId.FollowingFollowers]: {
    translationKey: 'member-pages.followers.name',
  },
  [WidgetId.ForumComments]: {
    translationKey: 'comments_page_name',
  },
  [WidgetId.ForumPosts]: {
    translationKey: 'posts_page_name',
  },
  [WidgetId.Notifications]: {
    translationKey: 'member-pages.notifications.name',
  },
  [WidgetId.Settings]: {
    translationKey: 'member-pages.settings.name',
  },
  [WidgetId.BlogComments]: {
    translationKey: 'member-pages.comments.name',
  },
  [WidgetId.BlogLikes]: {
    translationKey: 'member-pages.likes.name',
  },
  [WidgetId.BlogPosts]: {
    translationKey: 'member-pages.posts.name',
  },
  [WidgetId.FileShare]: {
    translationKey: 'fileshare_page_name',
  },
  [WidgetId.MyAddresses]: {
    translationKey: 'memberPages.addModal.memberPagesTab.addresses.title',
    labelToTrim: 'My Addresses',
  },
  [WidgetId.MyBookings]: {
    translationKey: 'members-area.my-bookings.page.title',
    labelToTrim: 'My Bookings',
  },
  [WidgetId.BlogDrafts]: {
    translationKey: 'member-pages.drafts.name',
    labelToTrim: 'My Drafts',
  },
  [WidgetId.MyPosts]: {
    translationKey: 'member-pages.my-posts.name',
    labelToTrim: 'My Posts',
  },
  [WidgetId.Events]: {
    translationKey: 'membersEventsPageName',
  },
  [WidgetId.MyOrders]: {
    translationKey: 'memberPages.addModal.memberPagesTab.orders.title',
    labelToTrim: 'My Orders',
  },
  [WidgetId.MyWallet]: {
    translationKey: 'members-area.my-wallet.page.title',
    labelToTrim: 'My Wallet',
  },
  [WidgetId.MyWishlist]: {
    translationKey: 'memberPages.addModal.memberPagesTab.wishlist.title',
    labelToTrim: 'My Wishlist',
  },
  [WidgetId.SharedGallery]: {
    translationKey: 'gallery_page_name',
  },
  [WidgetId.MySubscriptions]: {
    translationKey: 'editor.my-subscriptions-page.name',
    labelToTrim: 'My Subscriptions',
  },
  [WidgetId.MyChallenges]: {
    translationKey: 'members-area.challenges.name',
    labelToTrim: 'My Programs',
  },
  [WidgetId.MyPrograms]: {
    translationKey: 'members-area.challenges.name',
    labelToTrim: 'My Programs',
  },
  [WidgetId.MyRewards]: {
    translationKey: 'editor.my-rewards-page.name',
    labelToTrim: 'My Rewards',
  },
} satisfies Record<string, MenuItemLabelData>;

export const getWidgetIdFromAppDefIdAndPageId = ({
  pageId,
  appDefinitionId,
}: {
  pageId: PageId;
  appDefinitionId: AppDefinitionId;
}) => {
  const membersAreaAppWidgetId = MEMBERS_AREA_APPS_IDS.find((appIds) => {
    return appIds.pageId === pageId && appIds.appDefinitionId === appDefinitionId;
  })?.widgetId;

  if (!membersAreaAppWidgetId) {
    throw new Error('MA templates migration - app widgetId not found');
  }

  return membersAreaAppWidgetId;
};

export const getArtifactUrl = async (editorSDK: EditorSDK) => {
  const artifactsUrls = await editorSDK.info.getArtifactsUrls(APP_TOKEN, ['members-area-app-definitions']);
  return artifactsUrls['members-area-app-definitions'];
};

export const getLocale = (editorSDK: EditorSDK) => {
  return editorSDK.environment.getLocale();
};

export const getTranslationsUrl = async (editorSDK: EditorSDK) => {
  const baseUrl = await getArtifactUrl(editorSDK);
  const locale = await getLocale(editorSDK);
  const translationsFilePath = `assets/translations/messages_${locale}.json`;

  return `${baseUrl}/${translationsFilePath}`;
};

export const getTranslatedMenuItemsLabels = async (editorSDK: EditorSDK) => {
  const httpClient = new HttpClient();
  const translationsUrl = await getTranslationsUrl(editorSDK);

  try {
    const { data: translationsData } = await httpClient.get<Record<string, string>>(translationsUrl);
    const filteredTranslations: Record<string, string> = {};

    Object.values(menuItemsLabelsByWidgetId).forEach(({ translationKey: key }) => {
      if (translationsData[key]) {
        filteredTranslations[key] = translationsData[key];
      }
    });

    return filteredTranslations;
  } catch {
    return {};
  }
};

export const getRouteAppData = ({
  routers,
  routerId,
  innerRoute,
}: {
  routers: RouterData[];
  routerId: string;
  innerRoute: string;
}) => {
  const router = routers.find(({ id }) => id === routerId);

  if (!router) {
    throw new Error('MA templates migration - router id not found');
  }

  const routeAppData = router.config.patterns[`/${innerRoute}`].appData;

  if (!routeAppData) {
    throw new Error('MA templates migration - route appData not found');
  }

  return routeAppData as RouteAppData;
};

export const findNewMenuItemLinkByItemId = ({
  itemId,
  newMenuItems,
}: {
  itemId: WidgetId;
  newMenuItems: MenuItem[];
}) => {
  const menuItemLink = newMenuItems.find(({ link }) => (link as TpaPageLink).itemId === itemId)?.link;

  if (itemId === WidgetId.FollowingFollowers) {
    return null;
  }

  if (!menuItemLink) {
    throw new Error('MA templates migration - new menu item link not found');
  }

  return menuItemLink as TpaPageLink;
};

export const getUpdatedLinkData = ({
  routers,
  oldMenuItem,
  newMenuItems,
}: {
  routers: RouterData[];
  oldMenuItem: MenuItem;
  newMenuItems: MenuItem[];
}) => {
  const { appDefinitionId, appPageId } = getRouteAppData({
    routers,
    routerId: (oldMenuItem.link as DynamicPageLink).routerId,
    innerRoute: (oldMenuItem.link as DynamicPageLink).innerRoute,
  });

  const membersAreaAppWidgetId = getWidgetIdFromAppDefIdAndPageId({
    appDefinitionId,
    pageId: appPageId,
  });

  return findNewMenuItemLinkByItemId({
    itemId: membersAreaAppWidgetId,
    newMenuItems,
  });
};

export const maybeUpdateMenuItemLabel = ({
  oldLabel,
  widgetId,
  translatedMenuItemsLabels,
}: {
  oldLabel: string;
  widgetId: string;
  translatedMenuItemsLabels: Record<string, string>;
}) => {
  const targetLabelData = menuItemsLabelsByWidgetId[widgetId] as MenuItemLabelData | undefined;

  if (!targetLabelData) {
    return oldLabel;
  }

  const { translationKey: key, labelToTrim } = targetLabelData;

  if (translatedMenuItemsLabels[key]) {
    return translatedMenuItemsLabels[key];
  }

  if (labelToTrim && oldLabel === labelToTrim) {
    return oldLabel.replace('My ', '');
  }

  return oldLabel;
};

export const getUpdatedMenuItems = (props: Omit<MigrateMenuItemsProps, 'editorSDK' | 'menuId'>, nestLevel = 0) => {
  const { routers, oldMenuItems, newMenuItems, translatedMenuItemsLabels } = props;

  return oldMenuItems
    .map((oldMenuItem) => {
      if (oldMenuItem.link?.type !== 'DynamicPageLink') {
        return oldMenuItem;
      }

      const updatedLinkData = getUpdatedLinkData({
        routers,
        oldMenuItem,
        newMenuItems,
      });

      if (!updatedLinkData) {
        return null;
      }

      let subMenuItems: MenuItem[] = [];

      if (nestLevel === 0 && oldMenuItems.length) {
        subMenuItems = getUpdatedMenuItems(
          {
            ...props,
            oldMenuItems: oldMenuItem.items,
          },
          ++nestLevel,
        );
      }

      const label = maybeUpdateMenuItemLabel({
        oldLabel: oldMenuItem.label,
        widgetId: updatedLinkData.itemId,
        translatedMenuItemsLabels,
      });

      return {
        ...oldMenuItem,
        label,
        link: updatedLinkData,
        items: subMenuItems,
      };
    })
    .filter((item) => !!item) as MenuItem[];
};

export const migrateMenuItems = ({
  routers,
  oldMenuItems,
  newMenuItems,
  menuId,
  editorSDK,
  translatedMenuItemsLabels,
}: MigrateMenuItemsProps) => {
  const updatedMenuItems = getUpdatedMenuItems({
    routers,
    oldMenuItems,
    newMenuItems,
    translatedMenuItemsLabels,
  });

  // My Account menu item is missing in some templates while it's not a valid scenario in V2
  const isMyAccountInMenu = updatedMenuItems.find((menuItem) => {
    const menuItemLink = menuItem!.link as TpaPageLink;
    return menuItemLink.itemId === MY_ACCOUNT_WIDGET_ID;
  });

  if (!isMyAccountInMenu && menuId === MENU_IDS.SUB_MENU_ID) {
    const newMyAccountMenuItem = newMenuItems.find((menuItem) => {
      const menuItemLink = menuItem!.link as TpaPageLink;
      return menuItemLink.itemId === MY_ACCOUNT_WIDGET_ID;
    });

    if (!newMyAccountMenuItem) {
      throw new Error('Menu did not have "My Account" and an error occured while readding it');
    }

    updatedMenuItems.push(newMyAccountMenuItem);
  }

  return updateMenuItems({
    editorSDK,
    menuId,
    items: updatedMenuItems,
  });
};

export const composeMigrateMenuItems = async (editorSDK: EditorSDK, oldRouters: RouterData[]) => {
  const translatedMenuItemsLabels = await getTranslatedMenuItemsLabels(editorSDK);

  return (props: Omit<MigrateMenuItemsProps, 'editorSDK' | 'routers' | 'translatedMenuItemsLabels'>) => {
    return migrateMenuItems({
      editorSDK,
      routers: oldRouters,
      translatedMenuItemsLabels,
      ...props,
    });
  };
};

export const migrateMenus = async ({
  editorSDK,
  oldRouters,
  oldMenus,
}: {
  editorSDK: EditorSDK;
  oldRouters: RouterData[];
  oldMenus: MembersAreaMenus;
}) => {
  const newMenus = await getMembersAreaMenus(editorSDK);

  const initMenuItemsMigration = await composeMigrateMenuItems(editorSDK, oldRouters);

  return Promise.all([
    initMenuItemsMigration({
      oldMenuItems: oldMenus.logInMenu.items,
      newMenuItems: newMenus.logInMenu.items,
      menuId: MENU_IDS.LOGIN_MENU_ID,
    }),

    initMenuItemsMigration({
      oldMenuItems: oldMenus.subMenu.items,
      newMenuItems: newMenus.subMenu.items,
      menuId: MENU_IDS.SUB_MENU_ID,
    }),

    initMenuItemsMigration({
      oldMenuItems: oldMenus.logInIconsMenu.items,
      newMenuItems: newMenus.logInIconsMenu.items,
      menuId: MENU_IDS.LOGIN_ICONS_MENU_ID,
    }),
  ]);
};
