import { EditorSDK } from '@wix/platform-editor-sdk';

import { MA_PUBLIC_API_NOT_FOUND_ERROR, uninstallProfilePageBoB } from './members-area-page';
import * as history from '../../../wrappers/history';
import { toMonitored } from '../../../../utils/monitoring';

export const uninstallMembersArea = async (editorSDK: EditorSDK) => {
  try {
    await uninstallProfilePageBoB(editorSDK);
  } catch (e) {
    // No need to throw an error if the app is not installed
    if ((e as Error).message === MA_PUBLIC_API_NOT_FOUND_ERROR) {
      return;
    }
    throw e;
  }

  await toMonitored('ma-on-msb.uninstallMembersArea#editorSDK.application.uninstall', () =>
    editorSDK.application.uninstall('', { openConfirmation: false }),
  );

  await toMonitored('ma-on-msb.uninstallMembersArea#history.add', () =>
    history.add(editorSDK, history.HistoryLabel.UninstallV2),
  );
};
