import { log } from '../utils/monitoring';
import { getIsSuccessfulEditorReady, setIsSuccessfulEditorReady } from './services/applicationState';

let resolveInitialPromise: (value?: unknown) => void;

const initialPromise = new Promise((resolve) => {
  resolveInitialPromise = resolve;
});

let lastAction: Promise<unknown> = initialPromise;
let stopCause: any;

// Actions wrapped with enforceSequentiality are executed only after startSequentialPromises is called
export const startSequentialPromises = () => {
  setIsSuccessfulEditorReady(true);
  resolveInitialPromise();
};

// Actions wrapped with enforceSequentiality are not executed after stopSequentialPromises is called
export const stopSequentialPromises = (cause?: any) => {
  stopCause = cause;
  setIsSuccessfulEditorReady(false);
  resolveInitialPromise();
};

export default async function next<T>(methodName: string, nextAction: () => Promise<T>) {
  return new Promise<T>((resolve, reject) => {
    lastAction = lastAction.then(async () => {
      if (getIsSuccessfulEditorReady()) {
        try {
          resolve(await nextAction());
        } catch (e) {
          const tags = { methodName };
          const extra = { error: JSON.stringify(e), stack: JSON.stringify(e && (e as Error).stack) };
          log('Rejected promise in enforceSequentiality', { tags, extra });
          reject(e);
        }
      } else {
        const message = `Error occurred in members area platform. ${methodName} was not executed.`;
        const extra = {
          error: typeof stopCause === 'object' ? JSON.stringify(stopCause) : stopCause,
          ...(stopCause ? { stack: JSON.stringify(stopCause && (stopCause as Error)?.stack) } : {}),
        };

        log(message, { extra });
        reject(new Error(message));
      }
    });
  });
}
