import { IntegrationApplicationWithoutWidgetId } from './general-settings';

export type Page = IntegrationApplicationWithoutWidgetId['page'] & {
  parent: IntegrationApplicationWithoutWidgetId;
  id?: PageTypes;
};

export enum PageTypes {
  BLANK_PUBLIC,
  BLANK_PRIVATE,
}
