import type { EditorSDK } from '@wix/platform-editor-sdk';
import type { IntegrationApplication } from '@wix/members-area-app-definitions';

import { WidgetId } from '@wix/members-area-app-definitions';
import { WIX_BLOG } from '@wix/app-definition-ids';
import { getDefinition as getAboutDefinition } from '@wix/members-area-app-definitions/dist/esm/members-area-apps/definitions/about';

import type { BiData } from '../../../../types/bi';
import type { IntegrationApplicationWithoutWidgetId } from '../../../../types/general-settings';
import type { RouteConfiguration } from '../../../../types/controllers';
import type { Lightbox } from '../../types';
import type { AddApplicationItemsToMenuOptions } from './menu-items';

import { ProfileType } from '../../../../types/blog-writer';
import { toMonitored } from '../../../../utils/monitoring';
import { createBIService } from '../../../../utils/bi';
import { shouldEnableMyAccountParallelInstall } from '../../../../utils/experiments';
import { getMyAccountInstallDefinition } from '../../../constants';
import { navigateToPageRef } from '../../../wrappers/pages';
import { getApplicationPopupPages } from '../../../wrappers/popupPages';
import { getIsADI, getIsBlogWriterProfilesOnly, getVerticalsApps } from '../../../services/applicationState';
import { mergeGlobalSettings } from '../../../services/members-area';
import { addApplicationsToSlots, addLightboxes } from '../services/members-blocks-platform';
import { setStateForPages } from '../services/pages';
import { getProfileType, isProfileRegisteredOnlyByBlog, setProfileType } from '../services/blog-writer';
import { getMemberSettingsPageRoutes, getProfilePageRoutes } from '../services/routes';
import { groupDefinitionsByMethod } from './definitions';
import { createStandalonePrivatePages } from './standalone-private-pages';
import { installSiteApplications } from './site-applications';
import { registerAlwaysAvailableApps } from './register-apps';
import { addMenuItemsToMemberMenus } from './menu-items';

type InstalledWidgetsMap = Partial<Record<WidgetId, string>>;

type InstallAppsOptions = {
  editorSDK: EditorSDK;
  definitions: IntegrationApplication[] | IntegrationApplicationWithoutWidgetId[];
  biData?: BiData;
  shouldNavigate?: boolean;
};

const PROFILE_WIDGETS_TO_EXCLUDE_FROM_MENU = [WidgetId.FollowingFollowers];

const getNotInstalledWidgetPlugins = (definitions: IntegrationApplication[], routes: RouteConfiguration[]) => {
  const installedWidgetsMap = routes.reduce<InstalledWidgetsMap>((map, route) => {
    return { ...map, [route.widgetId]: true };
  }, {});

  return definitions.filter(({ widgetId }) => !installedWidgetsMap[widgetId]);
};

const getSettingsNotInstalledWidgetPlugins = async (editorSDK: EditorSDK, definitions: IntegrationApplication[]) => {
  const routes = await getMemberSettingsPageRoutes(editorSDK);
  const containsMyAccount = definitions.some(({ widgetId }) => widgetId === WidgetId.MyAccount);

  if ((await shouldEnableMyAccountParallelInstall()) && !containsMyAccount) {
    return getNotInstalledWidgetPlugins([getMyAccountInstallDefinition(), ...definitions], routes);
  }

  return getNotInstalledWidgetPlugins(definitions, routes);
};

const maybeGetDefinitionsWithAboutWidget = (definitions: IntegrationApplication[]) => {
  const shouldAddAboutDefinition =
    definitions.length && !definitions.some(({ widgetId }) => widgetId === WidgetId.About);

  if (shouldAddAboutDefinition) {
    const aboutDefinition: IntegrationApplication = getAboutDefinition('', (text: string) => text);
    return [aboutDefinition, ...definitions];
  }

  return definitions;
};

const getProfileNotInstalledWidgetPlugins = async (editorSDK: EditorSDK, definitions: IntegrationApplication[]) => {
  const routes = await getProfilePageRoutes(editorSDK);
  // Not every public vertical passes about
  // In split, about is must-have
  const widgetsDefinitions = maybeGetDefinitionsWithAboutWidget(definitions);

  return getNotInstalledWidgetPlugins(widgetsDefinitions, routes);
};

const getNotInstalledLightboxes = async (editorSDK: EditorSDK, definitions: IntegrationApplication[]) => {
  if (definitions.length === 0) {
    return [];
  }

  const applicationPopupPages = await getApplicationPopupPages(editorSDK);
  const installedTpaPageIdMap = applicationPopupPages.reduce<Record<string, true>>(
    (map, { tpaPageId }) => (tpaPageId ? { ...map, [tpaPageId]: true } : map),
    {},
  );

  return definitions.filter(({ pageId }) => !installedTpaPageIdMap[pageId]);
};

const installLightboxes = async (editorSDK: EditorSDK, lighboxes: Lightbox[]) => {
  const willInstallFFlightbox = lighboxes.some(({ widgetId }) => widgetId === WidgetId.FollowingFollowersLightbox);

  await addLightboxes(editorSDK, lighboxes);

  if (willInstallFFlightbox) {
    mergeGlobalSettings(editorSDK, { ffLightboxInstalled: true });
  }
};

export const installApps = async ({ editorSDK, definitions, biData, shouldNavigate = false }: InstallAppsOptions) => {
  const definitionsToInstall = groupDefinitionsByMethod(definitions);
  const { settingsWidgetPlugins, profileWidgetPlugins, standalonePrivatePages, siteApps, lightboxes } =
    definitionsToInstall;
  const [settingsWidgetPluginsToInstall, profileWidgetPluginsToInstall, lightboxesToInstall] = await Promise.all([
    getSettingsNotInstalledWidgetPlugins(editorSDK, settingsWidgetPlugins),
    getProfileNotInstalledWidgetPlugins(editorSDK, profileWidgetPlugins),
    getNotInstalledLightboxes(editorSDK, lightboxes),
  ]);

  const [standalonePrivatePagesCreated] = await Promise.all([
    standalonePrivatePages.length > 0
      ? createStandalonePrivatePages(editorSDK, standalonePrivatePages, biData)
      : Promise.resolve([]),
    settingsWidgetPluginsToInstall.length > 0 || profileWidgetPluginsToInstall.length > 0
      ? addApplicationsToSlots(
          editorSDK,
          [...settingsWidgetPluginsToInstall, ...profileWidgetPluginsToInstall],
          shouldNavigate,
        )
      : Promise.resolve(),
    siteApps.length > 0
      ? toMonitored('v3.installApps.installSiteApplications', () => installSiteApplications(editorSDK, siteApps))
      : Promise.resolve([]),
    lightboxesToInstall.length > 0
      ? installLightboxes(editorSDK, lightboxesToInstall as Lightbox[])
      : Promise.resolve(),
  ]);

  const addMenuItemsOptions = {
    standalonePrivatePages: standalonePrivatePagesCreated,
    settingsPageDefinitions: settingsWidgetPluginsToInstall,
    profilePageDefinitions: profileWidgetPluginsToInstall.filter(
      ({ widgetId }) => !PROFILE_WIDGETS_TO_EXCLUDE_FROM_MENU.includes(widgetId),
    ),
  } as AddApplicationItemsToMenuOptions;

  await addMenuItemsToMemberMenus(editorSDK, addMenuItemsOptions);
  await registerAlwaysAvailableApps(editorSDK);

  if (standalonePrivatePagesCreated.length > 0 || profileWidgetPluginsToInstall.length > 0) {
    setStateForPages(editorSDK);
  }

  if (
    shouldNavigate &&
    standalonePrivatePagesCreated.length === 1 &&
    !profileWidgetPluginsToInstall.length &&
    !settingsWidgetPluginsToInstall.length &&
    'pageRef' in standalonePrivatePagesCreated[0]
  ) {
    const pageRef = standalonePrivatePagesCreated[0].pageRef;

    return navigateToPageRef({ editorSDK, pageRef });
  }
};

const maybeSetBlogWriterProfileType = async (editorSDK: EditorSDK, verticalAppDefId: string) => {
  const hasBlogTriggeredInstall = verticalAppDefId === WIX_BLOG;
  if (!hasBlogTriggeredInstall || getIsADI() || !(await isProfileRegisteredOnlyByBlog(editorSDK))) {
    return;
  }

  const profileType = getIsBlogWriterProfilesOnly() ? ProfileType.BWP_ONLY : ProfileType.BWP_ALL;
  const currentProfileType = await getProfileType(editorSDK);

  if (profileType !== currentProfileType) {
    await setProfileType(editorSDK, profileType);
  }
};

export const installRegisteredApps = async (editorSDK: EditorSDK, verticalAppDefId: string, biData?: BiData) => {
  const biService = await createBIService({ editorSDK });
  biService.verticalTriggeredMaInstallInitiated({ originAppId: verticalAppDefId });

  const integrationApps = getVerticalsApps(verticalAppDefId) as IntegrationApplication[];
  const integrationAppsToInstall = integrationApps.filter((app) => {
    return app.shouldInstallInitially !== false;
  });

  if (integrationAppsToInstall.length > 0) {
    await installApps({ editorSDK, biData, definitions: integrationAppsToInstall });
  }

  await maybeSetBlogWriterProfileType(editorSDK, verticalAppDefId);
  biService.verticalTriggeredMaInstallSuccess({ originAppId: verticalAppDefId });
};
