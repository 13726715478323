import type { EditorSDK } from '@wix/platform-editor-sdk';
import type { WidgetId } from '@wix/members-area-app-definitions';

import type { RouteConfiguration } from '../../../../types/controllers';

import { getAppData, setAppData } from './app-data';

const SETTINGS_ROUTES_KEY = 'settingsRoutes';

type SettingsRoutesKey = typeof SETTINGS_ROUTES_KEY;

const PROFILE_ROUTES_KEY = 'routes';

type ProfileRoutesKey = typeof PROFILE_ROUTES_KEY;

export const getMemberSettingsPageRoutes = async (editorSDK: EditorSDK) => {
  const routes = await getAppData<SettingsRoutesKey, RouteConfiguration[]>(editorSDK, SETTINGS_ROUTES_KEY);
  return routes ?? [];
};

export const setMemberSettingsPageRoutes = async (editorSDK: EditorSDK, routes: RouteConfiguration[]) => {
  await setAppData<SettingsRoutesKey, RouteConfiguration[]>(editorSDK, SETTINGS_ROUTES_KEY, routes);
};

export const getProfilePageRoutes = async (editorSDK: EditorSDK) => {
  const routes = await getAppData<ProfileRoutesKey, RouteConfiguration[]>(editorSDK, PROFILE_ROUTES_KEY);
  return routes ?? [];
};

export const setProfilePageRoutes = async (editorSDK: EditorSDK, routes: RouteConfiguration[]) => {
  await setAppData<ProfileRoutesKey, RouteConfiguration[]>(editorSDK, PROFILE_ROUTES_KEY, routes);
};

export const updateProfilePageRoute = async (
  editorSDK: EditorSDK,
  routeToUpdate: Partial<RouteConfiguration> & { widgetId: WidgetId },
) => {
  const routes = await getProfilePageRoutes(editorSDK);
  const updatedRoutes = routes.map((route) => {
    if (route.widgetId === routeToUpdate.widgetId) {
      return { ...route, ...routeToUpdate };
    }

    return route;
  });

  await setProfilePageRoutes(editorSDK, updatedRoutes);
};
