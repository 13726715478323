import { MEMBERS_AREA } from '@wix/app-definition-ids';
import { EditorSDK } from '@wix/platform-editor-sdk';

import { MembersAreaContext } from '../../../types/context';
import { toMonitored } from '../../../utils/monitoring';
import { shouldEnableFollowersAsLightbox } from '../../../utils/experiments';
import { getMembersAreaContext } from '../../services/context';
import { mergeGlobalSettings } from '../../services/members-area';
import { fixGlobalControllerIfMissing } from './services/controllers';
import { verifyMembersAreaInstallation } from './services/installation-verification';
import { uninstallMembersArea } from './services/uninstall';

const withNoThrowUninstallMembersArea = async (editorSDK: EditorSDK) => {
  try {
    await uninstallMembersArea(editorSDK);
  } catch (e) {}
};

const withNoThrowFixGlobalControllerIfMissing = async (editorSDK: EditorSDK) => {
  try {
    await fixGlobalControllerIfMissing(editorSDK);
  } catch (e) {}
};

const verifyInstallation = async (editorSDK: EditorSDK) => {
  try {
    await withNoThrowFixGlobalControllerIfMissing(editorSDK);

    await verifyMembersAreaInstallation(editorSDK);
  } catch (e) {
    await withNoThrowUninstallMembersArea(editorSDK);
    throw e;
  }
};

const shouldHandleMAInstalledEvent = async (editorSDK: EditorSDK) => {
  const membersAreaContext = await getMembersAreaContext(editorSDK);
  return membersAreaContext === MembersAreaContext.V2;
};

const shouldEnableFFLightbox = async (editorSDK: EditorSDK) => {
  if (await shouldEnableFollowersAsLightbox()) {
    const membersAreaContext = await getMembersAreaContext(editorSDK);
    return membersAreaContext !== MembersAreaContext.V1;
  }

  return false;
};

export const handleAppInstalled = async (editorSDK: EditorSDK, appDefinitionId: string) => {
  const isMembersAreaInstalled = appDefinitionId === MEMBERS_AREA;

  if (isMembersAreaInstalled && (await shouldHandleMAInstalledEvent(editorSDK))) {
    await toMonitored('v2.editor.appInstalled.verifyInstallation', () => verifyInstallation(editorSDK));
  }

  if (isMembersAreaInstalled && (await shouldEnableFFLightbox(editorSDK))) {
    await toMonitored('editor.appInstalled.mergeGlobalSettings', () => {
      return mergeGlobalSettings(editorSDK, { ffLightboxEnabled: true });
    });
  }
};
