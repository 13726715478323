import type { EditorSDK, PageData } from '@wix/platform-editor-sdk';

import type { MembersAreaPrivateApi } from '../types';

import { monitoredTransactionFactory, toMonitored } from '../../../utils/monitoring';
import { addMenuItem } from '../../wrappers/menus';
import { isCurrentLanguageOriginal } from '../../wrappers/language';
import { getPageData, rename as renamePage, setPagesState } from '../../wrappers/pages';
import { MENU_IDS } from '../../constants';
import { createBlankPrivatePage, removePage, updatePageUriSEO } from './services/pages';
import { getProfilePageRoutes } from './services/routes';
import { uninstallMembersArea } from './services/uninstall';
import { openSeoRedirectDashboard } from '../../wrappers/panels';
import { shouldShowPageRedirectNote, shouldSplitCustomPageTranslations } from '../../../utils/experiments';
import { getUnifiedManagerPanelData } from './services/panels';
import { deleteApps } from './integration/delete-apps';
import { refreshMembersBlocksPlatform } from './services/members-blocks-platform';

export const createPrivateAPI = (editorSDK: EditorSDK): MembersAreaPrivateApi => {
  const monitoredTransaction = monitoredTransactionFactory(editorSDK);

  return {
    uninstall: () => {
      const action = () => uninstallMembersArea(editorSDK);
      return monitoredTransaction('private-api.v3.uninstall', action);
    },
    hasSocialPages: () => {
      const action = async () => {
        const profilePageRoutes = await getProfilePageRoutes(editorSDK);
        return profilePageRoutes.length > 0;
      };
      return monitoredTransaction('private-api.v3.hasSocialPages', action);
    },
    renamePage: (title, pageRef) => {
      const action = async () => renamePage({ editorSDK, pageRef, title });
      return monitoredTransaction('private-api.v3.renamePage', action);
    },
    updatePageData: ({ pageRef, pageData }) => {
      const action = async () => updatePageUriSEO(editorSDK, pageRef, pageData as Pick<PageData, 'pageUriSEO'>);
      return monitoredTransaction('private-api.v3.updatePageData', action);
    },
    createPrivateMemberPage: () => {
      const action = async () => {
        const { pageTitle, pageRef } = await toMonitored(
          'private-api.v3.createPrivateMemberPage.createBlankPrivatePage',
          () => createBlankPrivatePage(editorSDK),
        );

        const { id: pageId } = await toMonitored('private-api.v3.createPrivateMemberPage.getPageData', () =>
          getPageData({ editorSDK, pageRef }),
        );

        await toMonitored('private-api.v3.createPrivateMemberPage.addMenuItem', () =>
          addMenuItem({
            editorSDK,
            menuId: MENU_IDS.LOGIN_MENU_ID,
            menuItem: {
              label: pageTitle,
              link: {
                pageId: `#${pageId!}`,
                target: '_self',
                type: 'PageLink',
              },
            },
          }),
        );

        await toMonitored('private-api.v3.createPrivateMemberPage.setPagesState', () =>
          setPagesState({ editorSDK, state: { custom: [pageRef] } }),
        );

        return { pageRef, pageTitle };
      };

      return monitoredTransaction('private-api.v3.createPrivateMemberPage', action);
    },
    removePage: (pageData) => {
      const action = async () => removePage(editorSDK, pageData as PageData);
      return monitoredTransaction('private-api.v3.removePage', action);
    },
    shouldAllowUpdatingPagePrefix: () => {
      return isCurrentLanguageOriginal(editorSDK.language);
    },
    openSeoRedirectDashboard: () => {
      return openSeoRedirectDashboard(editorSDK);
    },
    shouldShowPageRedirectNote: () => {
      return shouldShowPageRedirectNote();
    },
    shouldSplitCustomPageTranslations: () => {
      return shouldSplitCustomPageTranslations();
    },
    refreshMembersBlocksPlatform: async () => {
      const action = async () => refreshMembersBlocksPlatform(editorSDK);
      return monitoredTransaction('private-api.v3.refreshMembersBlocksPlatform', action);
    },
    deleteApps: async (definitions) => {
      const action = async () => deleteApps(editorSDK, definitions);
      return monitoredTransaction('private-api.v3.deleteApps', action);
    },
    getUnifiedManagerPanelData: async () => {
      const action = async () => getUnifiedManagerPanelData(editorSDK);
      return monitoredTransaction('private-api.v3.getUnifiedManagerPanelData', action);
    },
    /** @deprecated */
    getAllRouters: () => {
      throw new Error('[SplitMA] PrivateAPI.getAllRouters is not implemented');
    },
    /** @deprecated */
    getRouterDataByPage: (pageRef) => {
      throw new Error('[SplitMA] PrivateAPI.getRouterDataByPage is not implemented');
    },
    /** @deprecated */
    updatePageDataInRouter: (pageRef, dataFieldToUpdate, updatedData) => {
      throw new Error('[SplitMA] PrivateAPI.updatePageDataInRouter is not implemented');
    },
    /** @deprecated */
    saveInnerRoute: (newInnerRoute, pageRef, pageRouterData) => {
      throw new Error('[SplitMA] PrivateAPI.saveInnerRoute is not implemented');
    },
    /** @deprecated */
    savePasswordPermissions: (pageRef, privacy, plainPassword) => {
      throw new Error('[SplitMA] PrivateAPI.savePasswordPermissions is not implemented');
    },
    /** @deprecated Only for templates migration to V2 */
    getIsMigrating: () => {
      throw new Error('[SplitMA] PrivateAPI.getIsMigrating is only available for MA V2');
    },
  };
};
