import type { EditorSDK } from '@wix/platform-editor-sdk';
import { TPAComponentType } from '@wix/platform-editor-sdk';
import type { IntegrationApplication } from '@wix/members-area-app-definitions';
import { MEMBERS_AREA } from '@wix/app-definition-ids';

import type { BiData } from '../../../../types/bi';

import { withTimeoutMonitor } from '../../../../utils/promise-timeout';
import { toMonitored } from '../../../../utils/monitoring';
import { getAllPages, updatePageData } from '../../../wrappers/pages';
import { isApplicationInstalled } from '../../../wrappers/application';
import { addApplication, addComponent, isAppSectionInstalled } from '../../../wrappers/tpa';
import { shouldEnableV3ReAddPageWorkaround } from '../../../../utils/experiments';

type AddPagesResponse = Awaited<ReturnType<typeof addPages>>;
type PageDefinition = ReturnType<typeof getPageDefinitions>[0];

const TPA_PAGES_IDS_TO_RE_ADD = ['My Subscriptions', 'My Rewards', 'notifications_app'];

export const getInstalledStandalonePageIds = async (editorSDK: EditorSDK) => {
  const allPagesData = await getAllPages({ editorSDK });
  const membersAreaPagesData = allPagesData.reduce<string[]>((tpaPageIds, pageData) => {
    const { managingAppDefId, tpaPageId } = pageData;
    return managingAppDefId === MEMBERS_AREA && tpaPageId ? [...tpaPageIds, tpaPageId] : tpaPageIds;
  }, []);

  return membersAreaPagesData;
};

const getNotInstalledStandalonePages = async (editorSDK: EditorSDK, definitions: IntegrationApplication[]) => {
  const notInstalledApplications = await Promise.all(
    definitions.map(async (definition) => {
      const { appDefinitionId, pageId } = definition;
      if (!pageId) {
        return { isInstalled: false, definition };
      }

      return {
        isInstalled: await isAppSectionInstalled({ editorSDK, appDefinitionId, sectionId: pageId }),
        definition,
      };
    }),
  );

  return notInstalledApplications.reduce<IntegrationApplication[]>((_definitions, { isInstalled, definition }) => {
    return isInstalled ? _definitions : [..._definitions, definition];
  }, []);
};

const getPageDefinitions = (appDefinitions: IntegrationApplication[], biData?: BiData) => {
  return appDefinitions.map((definition) => ({
    method: definition.method,
    appDefinitionId: definition.appDefinitionId,
    managingAppDefId: MEMBERS_AREA,
    componentType: TPAComponentType.Page,
    shouldNavigate: !!definition.shouldNavigate,
    page: {
      pageId: definition.pageId,
      shouldNavigate: !!definition.shouldNavigate,
      showInLoginMenu: !!definition.showInLoginMenu,
    },
    biData,
  }));
};

const ADD_APP_TIMEOUT_AMOUNT = 15000;

const shouldReAddPage = async (tpaPageId: string, installedAsApplication: boolean) =>
  installedAsApplication && TPA_PAGES_IDS_TO_RE_ADD.includes(tpaPageId) && shouldEnableV3ReAddPageWorkaround();

const addApplicationOrComponent = async (editorSDK: EditorSDK, _definition: PageDefinition) => {
  const definition = { ..._definition };
  const appDefinitionId = definition.appDefinitionId;
  const tpaPageId = definition.page.pageId;
  const addingMethod = definition.method;
  const isApplication = addingMethod === 'addApplication';
  delete definition.method;

  const timeoutMonitorName = isApplication ? 'v3.sdk.tpa.add.application' : 'v3.sdk.tpa.add.component';

  const action = async () => {
    // TODO: remove when uninstall is handled gracefully with all MA apps (currently DM's 'addApps' hang if you try to reinstall active app`)
    const isApplicationActive = await isApplicationInstalled(editorSDK, definition.appDefinitionId);

    const page =
      isApplication && !isApplicationActive
        ? { ...(await addApplication(editorSDK, definition)), appDefinitionId, tpaPageId }
        : { ...(await addComponent(editorSDK, definition)), appDefinitionId, tpaPageId };

    // TODO:
    //  remove this after https://jira.wixpress.com/browse/EP-4481
    //  is resolved and when verticals starts to use the new flag
    //  Issue - These pages are added under site menu and under us
    try {
      const installedAsApplication = isApplication && !isApplicationActive;
      if ((await shouldReAddPage(tpaPageId, installedAsApplication)) && 'pageRef' in page) {
        await editorSDK.pages.remove('', { pageRef: page.pageRef });

        return { ...(await addComponent(editorSDK, definition)), appDefinitionId, tpaPageId };
      }
    } catch (e) {
      console.error('[MA V3] Page re-add failed', e);
    }

    return page;
  };

  const monitoringPayload = {
    extra: { definition },
    tags: { pageId: definition.page.pageId },
  };

  return withTimeoutMonitor(timeoutMonitorName, ADD_APP_TIMEOUT_AMOUNT, action, monitoringPayload);
};

const addPages = async (editorSDK: EditorSDK, pageDefinitions: PageDefinition[]) => {
  const addApplicationsOrComponentsPromises = pageDefinitions.map((definition) => {
    return addApplicationOrComponent(editorSDK, definition);
  });
  const addedApplicationsOrComponents = await Promise.all(addApplicationsOrComponentsPromises);
  // TODO: Handle verification same as in V1?
  return addedApplicationsOrComponents;
};

const setPagesData = (editorSDK: EditorSDK, pagesData: AddPagesResponse) => {
  const pageUpdates = pagesData.map((pageData) => {
    return 'pageRef' in pageData
      ? updatePageData({
          editorSDK,
          pageRef: pageData.pageRef,
          pageData: { indexable: false, pageSecurity: { requireLogin: true } },
        })
      : Promise.resolve();
  });

  return Promise.all(pageUpdates);
};

export const createStandalonePrivatePages = async (
  editorSDK: EditorSDK,
  definitions: IntegrationApplication[],
  biData?: BiData,
) => {
  const applicationDefinitions = await getNotInstalledStandalonePages(editorSDK, definitions);

  if (applicationDefinitions.length === 0) {
    return [];
  }

  const pages = await addPages(editorSDK, getPageDefinitions(applicationDefinitions, biData));

  await toMonitored('v3.createStandalonePrivatePages.setPagesData', () => setPagesData(editorSDK, pages));

  return pages;
};
