import type { EditorSDK } from '@wix/platform-editor-sdk';
import type { IntegrationApplication, IntegrationApplicationMap } from '@wix/members-area-integration-kit';

import { InstallLocation, WidgetId } from '@wix/members-area-app-definitions';

import { asyncFilter } from '../../../../utils/promises';
import { arePagesEqual } from '../../../../utils/pages';
import { APP_TOKEN, MEMBERS_LIST_APP_DEF_ID, MEMBERS_LIST_PAGE_ID } from '../../../constants';
import { getAllIntegratedApps, getVerticalsApps } from '../../../services/applicationState';
import { isMyWishlist } from '../../../services/myWishlistIntegration';
import { getMemberSettingsPageRoutes, getProfilePageRoutes } from '../services/routes';
import { getInstalledStandalonePageIds } from './standalone-private-pages';
import { groupDefinitionsByMethod } from './definitions';

type WidgetPluginMap = Partial<Record<WidgetId, true>>;

const isVerticalInstalled = (editorSDK: EditorSDK) => (appDefinitionId: string) => {
  return editorSDK.application.isApplicationInstalled(APP_TOKEN, { appDefinitionId });
};

const getInstalledVerticalIds = async (editorSDK: EditorSDK): Promise<string[]> => {
  const allIntegratedAppsMap = getAllIntegratedApps();
  return asyncFilter(Object.keys(allIntegratedAppsMap), isVerticalInstalled(editorSDK));
};

export const getInstalledApplications = async (editorSDK: EditorSDK) => {
  const [standalonePrivatePages, profilePageRoutes, settingsPageRoutes] = await Promise.all([
    getInstalledStandalonePageIds(editorSDK),
    getProfilePageRoutes(editorSDK),
    getMemberSettingsPageRoutes(editorSDK),
  ]);

  const profileWidgetPluginMap = profilePageRoutes.reduce<WidgetPluginMap>((map, { widgetId }) => {
    return { ...map, [widgetId]: true };
  }, {});

  const settingsWidgetPluginMap = settingsPageRoutes.reduce<WidgetPluginMap>((map, { widgetId }) => {
    return { ...map, [widgetId]: true };
  }, {});

  return { standalonePrivatePages, profileWidgetPluginMap, settingsWidgetPluginMap };
};

type InstalledApplicationsMap = Awaited<ReturnType<typeof getInstalledApplications>>;

export const isApplicationInstalled = (
  application: IntegrationApplication,
  installedApplicationsMap: InstalledApplicationsMap,
) => {
  const { standalonePrivatePages, settingsWidgetPluginMap, profileWidgetPluginMap } = installedApplicationsMap;

  switch (application.installLocation) {
    case InstallLocation.Standalone: {
      return standalonePrivatePages.includes(application.pageId);
    }
    case InstallLocation.Settings: {
      return settingsWidgetPluginMap[application.widgetId] ?? false;
    }
    case InstallLocation.Profile: {
      return profileWidgetPluginMap[application.widgetId] ?? false;
    }
    default: {
      return false;
    }
  }
};

export const getRegisteredApps = async (editorSDK: EditorSDK) => {
  const allIntegratedAppsMap = getAllIntegratedApps();
  const installedVerticalIds = await getInstalledVerticalIds(editorSDK);
  const installedApplications = await getInstalledApplications(editorSDK);

  return installedVerticalIds.reduce((integrationApplicationMap, appDefId) => {
    const definitions = (allIntegratedAppsMap[appDefId] ?? []) as IntegrationApplication[];

    return definitions.length > 0
      ? {
          ...integrationApplicationMap,
          [appDefId]: definitions.filter((definition) => {
            return !isMyWishlist(definition) && !isApplicationInstalled(definition, installedApplications);
          }),
        }
      : integrationApplicationMap;
  }, {} as IntegrationApplicationMap);
};

export const getRegisteredApplicationOfInstalledVerticals = async (editorSDK: EditorSDK) => {
  const allVerticals = getAllIntegratedApps();
  const installedVerticalIds = await getInstalledVerticalIds(editorSDK);

  return installedVerticalIds.reduce<{ [key: string]: IntegrationApplication[] }>(
    (verticals, verticalId) => ({
      ...verticals,
      [verticalId]: allVerticals[verticalId] as IntegrationApplication[],
    }),
    {},
  );
};

const getAppsOfOtherVerticals = async (editorSDK: EditorSDK, currentVerticalAppDefId: string) => {
  const installedVerticalIds = await getInstalledVerticalIds(editorSDK);
  const allIntegratedAppsMap = getAllIntegratedApps();

  return installedVerticalIds.reduce<IntegrationApplication[]>((appsOfOtherVerticals, appDefId) => {
    if (currentVerticalAppDefId === appDefId) {
      return appsOfOtherVerticals;
    }
    const verticalDefinitions = (allIntegratedAppsMap[appDefId] ?? []) as IntegrationApplication[];
    return [...appsOfOtherVerticals, ...verticalDefinitions];
  }, []);
};

const getStandalonePrivatePagesToDeleteWithVertical = (
  installedPageIds: string[],
  verticalPagesDefinitions: IntegrationApplication[],
  pagesOfOtherApps: IntegrationApplication[],
) => {
  const installedPageIdMap = installedPageIds.reduce<Record<string, true>>((pageIdMap, pageId) => {
    return { ...pageIdMap, [pageId]: true };
  }, {});
  const protectedPageIdMap = pagesOfOtherApps.reduce<Record<string, true>>((pageIdMap, definition) => {
    return { ...pageIdMap, [definition.pageId]: true };
  }, {});

  return verticalPagesDefinitions.reduce<IntegrationApplication[]>((definitionsToDelete, definition) => {
    return installedPageIdMap[definition.pageId] && !protectedPageIdMap[definition.pageId]
      ? [...definitionsToDelete, definition]
      : definitionsToDelete;
  }, []);
};

const getWidgetPluginsToDelete = (
  installedWidgetPluginMap: WidgetPluginMap,
  verticalWidgetPlugins: IntegrationApplication[],
  widgetPluginsOfOtherApps: IntegrationApplication[],
) => {
  const protectedWidgetIdMap = widgetPluginsOfOtherApps.reduce<WidgetPluginMap>((widgetIdMap, definition) => {
    return { ...widgetIdMap, [definition.widgetId]: true };
  }, {});

  return verticalWidgetPlugins.reduce<IntegrationApplication[]>((definitionsToDelete, definition) => {
    return installedWidgetPluginMap[definition.widgetId] && !protectedWidgetIdMap[definition.widgetId]
      ? [...definitionsToDelete, definition]
      : definitionsToDelete;
  }, []);
};

const getSiteAppsToDeleteWithVertical = (
  verticalApps: IntegrationApplication[],
  appsOfOtherVerticals: IntegrationApplication[],
) => {
  const verticalSiteApps = verticalApps.filter(({ method }) => method === 'addApplicationToSite');
  const siteAppsToDeleteWithVertical = verticalSiteApps
    .filter((verticalApp) => {
      return appsOfOtherVerticals.some((otherVerticalsApp) => !arePagesEqual(verticalApp, otherVerticalsApp));
    })
    .map((verticalApp) => ({
      ...verticalApp,
      pageId: verticalApp.appDefinitionId === MEMBERS_LIST_APP_DEF_ID ? MEMBERS_LIST_PAGE_ID : verticalApp.pageId,
    }));

  return asyncFilter(siteAppsToDeleteWithVertical, isVerticalInstalled) as Promise<IntegrationApplication[]>;
};

export const getAppsToDeleteWithVertical = async (
  editorSDK: EditorSDK,
  verticalAppDefId: string,
): Promise<IntegrationApplication[]> => {
  const verticalApps = getVerticalsApps(verticalAppDefId) as IntegrationApplication[];
  const groupedVerticalApps = groupDefinitionsByMethod(verticalApps);
  const [appsOfOtherVerticals, installedApplications] = await Promise.all([
    getAppsOfOtherVerticals(editorSDK, verticalAppDefId),
    getInstalledApplications(editorSDK),
  ]);
  const groupedAppsOfOtherVerticals = groupDefinitionsByMethod(appsOfOtherVerticals);

  const standalonePrivatePagesToDelete = getStandalonePrivatePagesToDeleteWithVertical(
    installedApplications.standalonePrivatePages,
    groupedVerticalApps.standalonePrivatePages,
    groupedAppsOfOtherVerticals.standalonePrivatePages,
  );
  const settingsWidgetPluginsToDelete = getWidgetPluginsToDelete(
    installedApplications.settingsWidgetPluginMap,
    groupedVerticalApps.settingsWidgetPlugins,
    groupedAppsOfOtherVerticals.settingsWidgetPlugins,
  );
  const profileWidgetPluginsToDelete = getWidgetPluginsToDelete(
    installedApplications.profileWidgetPluginMap,
    groupedVerticalApps.profileWidgetPlugins,
    groupedAppsOfOtherVerticals.profileWidgetPlugins,
  );
  const siteAppsToDelete = await getSiteAppsToDeleteWithVertical(verticalApps, appsOfOtherVerticals);

  return [
    ...standalonePrivatePagesToDelete,
    ...settingsWidgetPluginsToDelete,
    ...profileWidgetPluginsToDelete,
    ...siteAppsToDelete,
  ];
};
