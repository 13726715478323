import { EditorSDK } from '@wix/platform-editor-sdk';

import { findPageRefByAppData } from '../wrappers/routers';

type Page = {
  appDefinitionId: string;
  appPageId: string;
};

export const getMembersPageRef = (editorSDK: EditorSDK, page: Page) => {
  if (!page) {
    return Promise.resolve(undefined);
  }
  const { appDefinitionId, appPageId } = page;
  return findPageRefByAppData(editorSDK, appDefinitionId, appPageId);
};
