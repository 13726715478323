import type { EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';

import {
  monitoredAddGlobalController,
  monitoredAddInitialMenuItemsToMemberMenus,
  monitoredAddLoginMenus,
  monitoredAddMemberSettingsSubMenu,
  monitoredAddProfileSubMenu,
  monitoredInstallMembersBlocksPlatform,
  monitoredNavigateToMemberSettingsPage,
} from './monitored-methods';
import { getMyAccountInstallDefinition, SOCIAL_APPS_IDS } from '../../../constants';
import { isStandaloneInstallation } from '../../../util';
import { shouldEnableMyAccountParallelInstall } from '../../../../utils/experiments';
import { installApps } from '../integration';
import { uninstallMembersArea } from '../services/uninstall';

const isSocialInstallation = (options: EditorReadyOptions) => {
  const appTriggeredInstall = options.origin.info?.appDefinitionId;
  return appTriggeredInstall ? SOCIAL_APPS_IDS.includes(appTriggeredInstall) : false;
};

export const installMembersArea = async (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  const enableMyAccountParallelInstall = await shouldEnableMyAccountParallelInstall();

  try {
    await monitoredAddGlobalController(editorSDK);
    await Promise.all([
      monitoredAddMemberSettingsSubMenu(editorSDK),
      monitoredAddProfileSubMenu(editorSDK),
      monitoredAddLoginMenus(editorSDK, options),
      monitoredInstallMembersBlocksPlatform(editorSDK, isSocialInstallation(options)),
    ]);

    if (enableMyAccountParallelInstall && isStandaloneInstallation(options)) {
      await installApps({ editorSDK, definitions: [getMyAccountInstallDefinition(options.origin)] });
    }

    await monitoredAddInitialMenuItemsToMemberMenus(editorSDK, options);

    if (isStandaloneInstallation(options)) {
      monitoredNavigateToMemberSettingsPage(editorSDK);
    }
  } catch (e) {
    try {
      await uninstallMembersArea(editorSDK);
    } catch (err) {}

    throw e;
  }
};
