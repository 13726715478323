import type { AnyRecord } from './v2-migration-types';

// https://github.com/wix-private/editor-elements/blob/master/packages/editor-elements-common-utils/src/commons/getScaledFont.ts

const getMobileFontSize = (desktopFontSize: number) => {
  const MOBILE_DEFAULT_MIN_FONT_SIZE = 12;
  const MOBILE_MAX_FONT_SIZE = 50;
  const SCALING_FACTOR = 3;
  const MIN_SCALED_FONT_SIZE = 14;
  const MEDIUM_SCALED_FONT_SIZE = 26;
  const MAX_SCALED_FONT_SIZE = 100;

  let mobileFontSize: number;

  const intDesktopFontSize = Math.round(desktopFontSize);

  if (intDesktopFontSize < MOBILE_DEFAULT_MIN_FONT_SIZE) {
    mobileFontSize = MOBILE_DEFAULT_MIN_FONT_SIZE;
  } else if (intDesktopFontSize <= MIN_SCALED_FONT_SIZE) {
    mobileFontSize = intDesktopFontSize + 1;
  } else if (intDesktopFontSize <= MEDIUM_SCALED_FONT_SIZE - 1) {
    mobileFontSize = intDesktopFontSize;
  } else if (intDesktopFontSize <= MAX_SCALED_FONT_SIZE) {
    mobileFontSize = Math.floor(
      (intDesktopFontSize - MEDIUM_SCALED_FONT_SIZE) / SCALING_FACTOR + MEDIUM_SCALED_FONT_SIZE,
    );
  } else {
    mobileFontSize = MOBILE_MAX_FONT_SIZE;
  }

  return mobileFontSize;
};

export const convertFontSizeToMobile = (fontSize: number, scale: number) => {
  const mobileFontSize = getMobileFontSize(fontSize);
  return scale * mobileFontSize;
};

export const removeUndefinedProps = (props: Readonly<AnyRecord>) => {
  const _props = {
    ...props,
  };

  for (const key in _props) {
    if (_props[key] === undefined) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete _props[key];
    }
  }

  return _props;
};

export const parseIntFromPx = (val: string) => {
  return parseInt(val.replace('px', ''), 10);
};
