import { ComponentDefinition, ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';

import {
  LOGIN_BAR_CONTROLLER_TYPE,
  LOGIN_COMP_APP_WIDGET_DEF_ECOM_V2,
  LOGIN_COMP_APP_WIDGET_DEF_V2,
} from '../../../constants/layouts/login-bar/classic-login-bar-layouts';
import { LOGIN_COMP_RESPONSIVE_APP_WIDGET_DEF_V2 } from '../../../constants/layouts/login-bar/responsive-login-bar-layouts';
import * as menus from '../../../wrappers/menus';
import { getSiteLocale, isRtlLocale } from '../../../../utils/locale';
import { APP_TOKEN } from '../../../constants';
import { getIsResponsiveEditor } from '../../../services/applicationState';
import { COMMON_LOGIN_BAR_COMP_DATA } from '../../../constants/layouts/login-bar/common-login-bar-layouts';
import { CompStructure } from '@wix/document-services-types';

const updateLoginBarItemsAlignment = async (editorSDK: EditorSDK, loginBarRef: ComponentRef) => {
  const locale = await getSiteLocale(editorSDK);

  return editorSDK.components.properties.update(APP_TOKEN, {
    componentRef: loginBarRef,
    props: { itemsAlignment: isRtlLocale(locale) ? 'right' : 'left' },
  });
};

export const handleLoginBarAdded = async (editorSDK: EditorSDK, eventPayload?: any) => {
  if (!eventPayload?.componentRef) {
    return;
  }

  const [controllerConnection] = await editorSDK.controllers.getControllerConnections('', {
    controllerRef: eventPayload.componentRef,
  });

  if (controllerConnection?.connection.role !== LOGIN_BAR_CONTROLLER_TYPE) {
    return;
  }

  const loginBarRef = controllerConnection.componentRef;

  return Promise.all([
    updateLoginBarItemsAlignment(editorSDK, loginBarRef),
    connectLoginButton(editorSDK, loginBarRef),
  ]);
};

const getLoginBarAppWidgetCompDef = (isResponsiveEditor: boolean, isStoresInstallation: boolean) => {
  const appWidgetCompDef = (isResponsiveEditor
    ? LOGIN_COMP_RESPONSIVE_APP_WIDGET_DEF_V2
    : isStoresInstallation
    ? LOGIN_COMP_APP_WIDGET_DEF_ECOM_V2
    : LOGIN_COMP_APP_WIDGET_DEF_V2) as unknown as ComponentDefinition;

  const component = appWidgetCompDef.components?.[0] as CompStructure;
  const componentWithMenuRefs = { ...component, data: COMMON_LOGIN_BAR_COMP_DATA };

  return { ...appWidgetCompDef, components: [componentWithMenuRefs] };
};

export async function addLoginButton(
  editorSDK: EditorSDK,
  headerRef: ComponentRef,
  isStoresInstallation: boolean = true,
) {
  const isResponsiveEditor = getIsResponsiveEditor();
  const appWidgetCompDef = getLoginBarAppWidgetCompDef(isResponsiveEditor, isStoresInstallation);

  await editorSDK.components.add(APP_TOKEN, {
    pageRef: headerRef,
    componentDefinition: appWidgetCompDef,
    // @ts-expect-error
    showAddToHeaderPanel: false,
  });
}

export async function connectLoginButton(editorSDK: EditorSDK, componentRef: ComponentRef) {
  const menusIds = menus.getMenuIds();
  const compData = (await editorSDK.components.data.get('', { componentRef })) as { iconItemsRef; menuItemsRef };

  if (compData.iconItemsRef && compData.menuItemsRef) {
    return;
  }

  // To do: It should be possible to have this data in the definiton itself, so less handling would happen here
  const dataToUpdate = {
    menuItemsRef: {
      menuRef: '#' + menusIds.login,
      type: 'CustomMenuDataRef',
    },
    iconItemsRef: {
      menuRef: '#' + menusIds.icons,
      type: 'CustomMenuDataRef',
    },
  };

  await editorSDK.components.data.update(APP_TOKEN, { componentRef, data: dataToUpdate });
}
