import { EditorSDK } from '@wix/platform-editor-sdk';

import { RouteConfiguration } from '../../../../types/controllers';
import { IsSectionInstalledProps } from '../../types';
import { getRoutesFromAppData, setRoutesInAppData } from './app-data';
import { getRoutesFromGlobalController } from './controllers';

export const getRoutes = async (editorSDK: EditorSDK) => {
  const [routesInAppData, routesInGlobalController] = await Promise.all([
    getRoutesFromAppData(editorSDK),
    getRoutesFromGlobalController(editorSDK),
  ]);

  return routesInAppData ?? routesInGlobalController;
};

export const setRoutes = async (editorSDK: EditorSDK, routes: RouteConfiguration[]) => {
  await setRoutesInAppData(editorSDK, routes);
};

export const routeExists = async (editorSDK: EditorSDK, { widgetId }: IsSectionInstalledProps) => {
  const routes = await getRoutes(editorSDK);
  return routes.some((route) => route.widgetId === widgetId);
};
