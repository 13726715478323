import type { EditorSDK, MenuItem } from '@wix/platform-editor-sdk';
import type { TpaPageLink } from '@wix/document-services-types';

import type {
  MenuItemsData,
  GetMenuItemsWithFixedLinks,
  GetCorruptedMenuItemsDataOptions,
  GetFixedMenuItemsDataOptions,
  ConfirmMenuItemsContainInvalidLinksOptions,
} from './types';

import { getMenuById, updateMenuItems } from '../../../wrappers/menus';
import { log } from '../../../../utils/monitoring';

const updatePageUriSEO = (path: string, pageUriSEO: string) => {
  const splitPath = path.split('/').filter(Boolean);
  splitPath[0] = pageUriSEO;
  const newPath = splitPath.join('/');
  return `/${newPath}`;
};

const getMenuItemsWithFixedLinks = (params: GetMenuItemsWithFixedLinks): MenuItem[] => {
  const { menuItems, applicationPages, getIsTpaLinkValid, getPageUriSEO } = params;

  return menuItems.map((menuItem) => {
    const { items } = menuItem;

    const childItems = items.length ? getMenuItemsWithFixedLinks({ ...params, menuItems: items }) : items;

    const isLinkValid = getIsTpaLinkValid(menuItem.link, applicationPages);

    if (isLinkValid) {
      return {
        ...menuItem,
        items: childItems,
      };
    }

    const link = menuItem.link as TpaPageLink;
    const { path, itemTypeIdentifier } = link;
    const pageUriSEO = getPageUriSEO(applicationPages, itemTypeIdentifier);
    const correctPath = updatePageUriSEO(path, pageUriSEO);

    return {
      ...menuItem,
      items: childItems,
      link: {
        ...link,
        path: correctPath,
      },
    };
  });
};

export const confirmMenuItemsContainInvalidLinks = ({
  menuItems,
  applicationPages,
  getIsTpaLinkValid,
}: ConfirmMenuItemsContainInvalidLinksOptions): boolean => {
  return menuItems.some(({ link, items: childItems }) => {
    if (childItems.length) {
      return confirmMenuItemsContainInvalidLinks({
        menuItems: childItems,
        applicationPages,
        getIsTpaLinkValid,
      });
    }

    return !getIsTpaLinkValid(link, applicationPages);
  });
};

export const getCorruptedMenuItemsData = async ({
  editorSDK,
  menuIds,
  applicationPages,
  getIsTpaLinkValid,
}: GetCorruptedMenuItemsDataOptions) => {
  const corruptedMenuItemsData = await Promise.all(
    menuIds.map(async (menuId) => {
      const menuData = await getMenuById({ editorSDK, menuId });

      if (!menuData) {
        return null;
      }

      const { items: menuItems } = menuData;

      const hasCorruptedLinks = confirmMenuItemsContainInvalidLinks({
        menuItems,
        applicationPages,
        getIsTpaLinkValid,
      });

      if (!hasCorruptedLinks) {
        return null;
      }

      return {
        menuId,
        menuItems,
      };
    }),
  );

  return corruptedMenuItemsData.filter((itemData): itemData is MenuItemsData => !!itemData);
};

export const getFixedMenuItemsData = ({ corruptedMenuItemsData, ...rest }: GetFixedMenuItemsDataOptions) => {
  return corruptedMenuItemsData.map(({ menuId, menuItems }) => {
    const menuItemsWithFixedLinks = getMenuItemsWithFixedLinks({ menuItems, ...rest });

    return {
      menuId,
      menuItems: menuItemsWithFixedLinks,
    };
  });
};

export const updateMenus = (editorSDK: EditorSDK, menuItemsData: MenuItemsData[]) => {
  return Promise.all(
    menuItemsData.map(({ menuId, menuItems }) => {
      return updateMenuItems({ editorSDK, menuId, items: menuItems });
    }),
  );
};

// TODO: remove. https://jira.wixpress.com/browse/MA-3357
export const logMenuItemsFix = (fixedMenuIds: string[]) => {
  log('Found menus with incorrect TpaPageLinks', {
    extra: {
      fixedMenuIds,
    },
  });
};
