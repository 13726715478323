import type { ApplicationAction, SimplePageAction } from '@wix/platform-editor-sdk';
import type { TranslationFunction } from 'i18next';

import { MembersAreaEvent } from '../../types';

const getOpenGeneralSettingsAction = (t: TranslationFunction): ApplicationAction => ({
  title: t('Pages_Panel_MemberMenu_Open_GeneralSettings'),
  event: MembersAreaEvent.GeneralSettings,
  icon: 'settingsAction',
});

const getOpenMemberPrivacyDashboardAction = (t: TranslationFunction): ApplicationAction => ({
  title: t('Pages_Panel_MemberMenu_Open_MemberPrivacyDashboard'),
  event: MembersAreaEvent.MemberPrivacyDashboard,
  icon: 'unhideAction',
});

const viewSiteMembersAction: ApplicationAction = {
  // @ts-expect-error
  type: 'view_site_members',
};

const openSignUpSettingsAction: ApplicationAction = {
  // @ts-expect-error
  type: 'open_signup_settings',
};

const getDeleteMembersAreaAction = (t: TranslationFunction): SimplePageAction & ApplicationAction => ({
  title: t('Pages_Panel_MemberMenu_Delete_Link'),
  event: MembersAreaEvent.Uninstall,
  icon: 'delete_icon',
});

const getAddPageAction = (t: TranslationFunction): ApplicationAction => ({
  title: t('Pages_Panel_MemberMenu_Add_Page_V3'),
  type: 'add_page',
  event: MembersAreaEvent.AddPages,
  icon: 'addPagePagesPanel',
});

const getUnifiedManagerAction = (t: TranslationFunction): ApplicationAction => ({
  title: t('UnifiedManagerPanel_EntryPoint_V3_PagesPanel'),
  type: 'add_page',
  event: MembersAreaEvent.OpenUnifiedManager,
  icon: 'addPagePagesPanel',
});

export const applicationActions = {
  getAddPageAction,
  getUnifiedManagerAction,
  viewSiteMembersAction,
  openSignUpSettingsAction,
  getOpenGeneralSettingsAction,
  getOpenMemberPrivacyDashboardAction,
  getDeleteMembersAreaAction,
};
