import type { EditorSDK, CustomMenuData } from '@wix/platform-editor-sdk';
import { MEMBERS_AREA } from '@wix/app-definition-ids';

import { log } from '../../utils/monitoring';
import { shouldEnableMenusDataFixer } from '../../utils/experiments';
import { getMenuById } from '../wrappers/menus';
import { APP_TOKEN, MENU_IDS } from '../constants';

const logMenusWithBrokenData = (message: string, brokenMenus: CustomMenuData[]) => {
  const menuIds = brokenMenus.map((menu) => menu.id);

  log(message, {
    extra: {
      menus: JSON.stringify(menuIds),
    },
  });
};

const getMembersAreaMenus = async (editorSDK: EditorSDK) => {
  const menus = await Promise.all([
    getMenuById({ editorSDK, menuId: MENU_IDS.SUB_MENU_ID }),
    getMenuById({ editorSDK, menuId: MENU_IDS.LOGIN_MENU_ID }),
    getMenuById({ editorSDK, menuId: MENU_IDS.LOGIN_ICONS_MENU_ID }),
  ]);

  return menus.filter(Boolean);
};

const getBrokenMenus = async (editorSDK: EditorSDK) => {
  const menus = await getMembersAreaMenus(editorSDK);
  return menus.filter(({ appDefinitionId }) => !appDefinitionId);
};

const verifyMenusUpdated = async (editorSDK: EditorSDK) => {
  try {
    const brokenMenus = await getBrokenMenus(editorSDK);
    if (brokenMenus.length) {
      logMenusWithBrokenData('Error fixing broken members menus. Not all menus were updated.', brokenMenus);
    }
  } catch (e) {
    log('An error was thrown while verifying broken members menus fix.', {
      extra: {
        error: e,
      },
    });
  }
};

const fixBrokenMenusData = async (editorSDK: EditorSDK, menus: CustomMenuData[]) => {
  const membersAppId = (await editorSDK.tpa.app.getDataByAppDefId(APP_TOKEN, MEMBERS_AREA)).applicationId;

  const updaterPromises: Promise<void>[] = [];

  menus.forEach((menu) => {
    const updaterPromise = editorSDK.menu.update(APP_TOKEN, {
      menuId: menu.id,
      menuData: {
        appId: membersAppId,
        appDefinitionId: MEMBERS_AREA,
      },
    });

    updaterPromises.push(updaterPromise);
  });

  await Promise.all(updaterPromises);
};

export const maybeFixBrokenMenusData = async (editorSDK: EditorSDK) => {
  const enableFixer = await shouldEnableMenusDataFixer();
  if (!enableFixer) {
    return;
  }

  try {
    const brokenMenus = await getBrokenMenus(editorSDK);
    if (brokenMenus.length) {
      logMenusWithBrokenData('Found members menus with broken data.', brokenMenus);
      await fixBrokenMenusData(editorSDK, brokenMenus);
      setTimeout(() => verifyMenusUpdated(editorSDK), 5000);
    }
  } catch (e) {
    log('An error was thrown while fixing broken members menus data', {
      extra: {
        error: e,
      },
    });
  }
};
