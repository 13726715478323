import { flatten } from 'lodash';
import { IntegrationApplication, IntegrationApplicationMap } from '@wix/members-area-integration-kit';
import { InstallLocation, MA_APP_IDS, MembersAreaAppId } from '@wix/members-area-app-definitions';

import { getUniqueApplications } from '../../utils/pages';
import type { Page } from '../../types/page';
import type { UnifiedManagerApplication } from '../../types';

const PUBLIC_APPLICATIONS_IDS = [MA_APP_IDS.ABOUT.pageId];

export const toIntegrationApplication = (integrationApplicationMap: IntegrationApplicationMap) => {
  const applications = flatten<IntegrationApplication | MembersAreaAppId>(
    Object.values(integrationApplicationMap),
  ).filter((app) => {
    if ('page' in app) {
      return app.page && app.installLocation !== InstallLocation.Lightbox;
    }

    return false;
  });

  return applications as IntegrationApplication[];
};

export const toPanelApplications = (integrationApplicationMap?: IntegrationApplicationMap): Page[] => {
  if (!integrationApplicationMap) {
    return [];
  }
  const applications = toIntegrationApplication(integrationApplicationMap);

  const uniqueApplications = getUniqueApplications(applications as IntegrationApplication[]);
  const transformedApplications = uniqueApplications.map((app) => ({
    parent: app,
    ...app.page,
  }));

  return transformedApplications as Page[];
};

export const toSettingsApplications = (integrationApplicationMap?: IntegrationApplicationMap) => {
  const applications = toPanelApplications(integrationApplicationMap);

  return applications.filter((app) => app.parent.installLocation === InstallLocation.Settings);
};

export const toProfileApplications = (integrationApplicationMap?: IntegrationApplicationMap) => {
  const applications = toPanelApplications(integrationApplicationMap);

  return applications.filter((app) => app.parent.installLocation === InstallLocation.Profile);
};

export const toAddPagesApplications = (integrationApplicationMap?: IntegrationApplicationMap) => {
  const applications = toPanelApplications(integrationApplicationMap);
  const privateApplications = applications.filter((app) => app.parent.installLocation === InstallLocation.Standalone);
  const publicApplication = applications.find((app) => PUBLIC_APPLICATIONS_IDS.includes(app.parent.pageId));
  const publicApplications = publicApplication ? [publicApplication] : [];

  return { private: privateApplications, public: publicApplications };
};

export const toUnifiedManagerApplications = (integrationApplications: IntegrationApplication[]) => {
  const uniqueApplications = getUniqueApplications(integrationApplications);
  return uniqueApplications as UnifiedManagerApplication[];
};
