import { ContextParams, EditorReadyFn, EditorSDK, GetAppManifest } from '@wix/platform-editor-sdk';

import { MembersAreaContext } from '../types/context';
import { initializeMonitoring } from '../utils/monitoring';
import { initExperiments, initV3Experiments, shouldEnableMigration } from '../utils/experiments';
import { parseStaticsUrlFromEditorScriptUrl } from './services/urls';
import * as applicationState from './services/applicationState';
import { getMembersAreaContext } from './services/context';
import { migrateToV2OnTemplates } from './services/v2-migration';
import { MembersAreaOnEventFn, MembersAreaPlatformApp } from './platform-app/types';
import {
  currentMembersArea,
  exportsFn,
  getControllerPresets,
  membersAreaOnMSB,
  splitMembersArea,
} from './platform-app';

const editorPlatformAppMap: { [key in MembersAreaContext]: MembersAreaPlatformApp } = {
  [MembersAreaContext.V1]: currentMembersArea,
  [MembersAreaContext.V2]: membersAreaOnMSB,
  [MembersAreaContext.V3]: splitMembersArea,
};

const getEditorPlatformApp = async (editorSDK: EditorSDK, options?: ContextParams) => {
  const membersAreaContext = await getMembersAreaContext(editorSDK, options);
  return editorPlatformAppMap[membersAreaContext];
};

const editorReady: EditorReadyFn = async (editorSDK, _appToken, options) => {
  const appInstance = await editorSDK.info.getAppInstancePayload('');
  initExperiments(appInstance);
  initV3Experiments(appInstance);

  applicationState.setEditorSDK(editorSDK);
  applicationState.setStaticsBaseUrl(parseStaticsUrlFromEditorScriptUrl(options.initialAppData.editorScriptUrl));
  // @ts-expect-error - blogWriterProfilesOnly does not exist on Origin.info type in Editor platform sdk types
  applicationState.setIsBlogWriterProfilesOnly(!!options.origin.info?.blogWriterProfilesOnly);
  applicationState.setIsClassicEditor(options.origin.type === 'CLASSIC');
  applicationState.setIsResponsiveEditor(options.origin.type === 'RESPONSIVE');
  applicationState.setIsADI(options.origin.type.indexOf('ADI') === 0);

  try {
    await initializeMonitoring(editorSDK, options);
  } catch (e) {}

  const enableMigration = await shouldEnableMigration();
  const membersAreaContext = await getMembersAreaContext(editorSDK);
  if (enableMigration && membersAreaContext === MembersAreaContext.V1) {
    await migrateToV2OnTemplates(editorSDK, options);
  }

  const platformApp = await getEditorPlatformApp(editorSDK, options);
  return platformApp.editorReady(editorSDK, _appToken, options);
};

const onEvent: MembersAreaOnEventFn = async (event, editorSDK) => {
  const platformApp = await getEditorPlatformApp(editorSDK);
  return platformApp.onEvent(event, editorSDK);
};

const getAppManifest: GetAppManifest = async (options, editorSDK, contextParams) => {
  const platformApp = await getEditorPlatformApp(editorSDK);
  return platformApp.getAppManifest(options, editorSDK, contextParams);
};

export { editorReady, onEvent, getAppManifest, getControllerPresets, exportsFn as exports };
