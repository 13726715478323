import type { EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';
import type { TpaPageLink } from '@wix/document-services-types';

import { WIX_STORES } from '@wix/app-definition-ids';

import { log } from '../../../../utils/monitoring';
import { APP_TOKEN, MENU_IDS, MenuItemIdentifier } from '../../../constants';
import {
  createLoginIconsMenu,
  createLoginMenu,
  getMenuItems,
  updateMenuItems,
  updatePagePathInMenuItems,
} from '../../../wrappers/menus';
import { addLoginButton } from './login-bar';

export const addLoginMenus = async (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  const isStoresInstallation = options.origin.info?.appDefinitionId === WIX_STORES;
  const headerRef = await editorSDK.siteSegments.getHeader(APP_TOKEN);

  await Promise.all([createLoginMenu(editorSDK), createLoginIconsMenu(editorSDK)]);

  try {
    await addLoginButton(editorSDK, headerRef, isStoresInstallation);
  } catch (e) {
    log('Failed to add login button', { extra: { error: (e as Error).toString() } });
  }
};

export const addMemberSettingsSubMenu = async (editorSDK: EditorSDK) => {
  await editorSDK.menu.create(APP_TOKEN, {
    menuData: { name: 'Member Settings Menu', items: [] },
    customId: MENU_IDS.SETTINGS_SUB_MENU_ID,
  });
};

export const addProfileSubMenu = async (editorSDK: EditorSDK) => {
  await editorSDK.menu.create(APP_TOKEN, {
    menuData: { name: 'Member Menu', items: [] },
    customId: MENU_IDS.SUB_MENU_ID,
  });
};

export const updateSettingsPagePathInMenus = async (
  editorSDK: EditorSDK,
  currentPageUriSEO: string,
  newPageUriSEO: string,
) => {
  return Promise.all([
    updatePagePathInMenuItems({
      editorSDK,
      menuId: MENU_IDS.LOGIN_MENU_ID,
      currentPageUriSEO,
      newPageUriSEO,
    }),
    updatePagePathInMenuItems({ editorSDK, menuId: MENU_IDS.LOGIN_ICONS_MENU_ID, currentPageUriSEO, newPageUriSEO }),
    updatePagePathInMenuItems({ editorSDK, menuId: MENU_IDS.SETTINGS_SUB_MENU_ID, currentPageUriSEO, newPageUriSEO }),
  ]);
};

export const updateProfilePagePathInMenus = async (
  editorSDK: EditorSDK,
  currentPageUriSEO: string,
  newPageUriSEO: string,
) => {
  return Promise.all([
    updatePagePathInMenuItems({
      editorSDK,
      menuId: MENU_IDS.LOGIN_MENU_ID,
      currentPageUriSEO,
      newPageUriSEO,
    }),
    updatePagePathInMenuItems({ editorSDK, menuId: MENU_IDS.SUB_MENU_ID, currentPageUriSEO, newPageUriSEO }),
  ]);
};

const removeProfilePageMenuItemsFromLoginBar = async (editorSDK: EditorSDK) => {
  const currentItems = await getMenuItems({ editorSDK, menuId: MENU_IDS.LOGIN_MENU_ID });

  const updatedMenuItems = currentItems.filter((menuItem) => {
    if (menuItem.link?.type === 'TpaPageLink') {
      const link = menuItem.link as TpaPageLink;
      return link.itemTypeIdentifier !== MenuItemIdentifier.SubPage;
    }

    return true;
  });

  return updateMenuItems({ editorSDK, menuId: MENU_IDS.LOGIN_MENU_ID, items: updatedMenuItems });
};

export const removeProfilePageMenuItemsFromMenus = async (editorSDK: EditorSDK) => {
  return Promise.all([
    updateMenuItems({ editorSDK, menuId: MENU_IDS.SUB_MENU_ID, items: [] }),
    removeProfilePageMenuItemsFromLoginBar(editorSDK),
  ]);
};
