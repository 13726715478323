import type { EditorReadyOptions, EditorSDK, MenuItem, PageData, PageLink, PageRef } from '@wix/platform-editor-sdk';
import type { TpaPageLink } from '@wix/document-services-types';
import type { IntegrationApplication } from '@wix/members-area-app-definitions';
import { MA_APP_IDS } from '@wix/members-area-app-definitions';

import { MEMBERS_AREA_V2 as MEMBERS_AREA_BLOCKS_PLATFORM } from '@wix/app-definition-ids';

import {
  getMyAccountInstallDefinition,
  MENU_IDS,
  MY_ACCOUNT_APP_DEF_ID,
  MY_ACCOUNT_WIDGET_ID,
  MenuItemIdentifier,
} from '../../../constants';
import { getPageData } from '../../../wrappers/pages';
import { getDataByAppDefId, getMemberProfilePageRef, getMemberSettingsPageRef } from '../../../wrappers/tpa';
import { addMenuItems } from '../../../wrappers/menus';
import { shouldEnableMyAccountParallelInstall } from '../../../../utils/experiments';

type PageLinkMenuItemData = {
  title: string;
  pageRef: PageRef;
  pageUriSEO: string;
};

type PageLinkMenuItemDataWithAppData = PageLinkMenuItemData & {
  appDefinitionId: string;
  tpaPageId: string;
};

export type AddApplicationItemsToMenuOptions = {
  standalonePrivatePages: PageLinkMenuItemDataWithAppData[];
  profilePageDefinitions: IntegrationApplication[];
  settingsPageDefinitions: IntegrationApplication[];
};

const getAppsData = (editorSDK: EditorSDK, definitions: IntegrationApplication[]) => {
  return Promise.all(
    definitions.map((definition) => getDataByAppDefId({ editorSDK, appDefinitionId: definition.appDefinitionId })),
  );
};

const convertPageIdToLinkPageId = (pageId: string | undefined) => {
  if (!pageId) {
    return '';
  }

  return `#${pageId}`;
};

const createProfilePageTpaPageLinkMenuItem = (
  definition: IntegrationApplication,
  pageData: PageData,
  label: string,
): MenuItem => {
  if (!definition.urlOverride) {
    throw new Error(`Split MA: urlOverride is missing for ${definition.appDefinitionId}`);
  }

  const pageId = convertPageIdToLinkPageId(pageData.id);
  const link: TpaPageLink = {
    type: 'TpaPageLink',
    itemTypeIdentifier: MenuItemIdentifier.SubPage,
    itemId: definition.widgetId,
    pageId,
    appDefinitionId: MEMBERS_AREA_BLOCKS_PLATFORM,
    path: `/${pageData.pageUriSEO}/my/${definition.urlOverride}`,
  };

  return {
    id: '',
    type: 'BasicMenuItem',
    label,
    link,
    isVisible: true,
    isVisibleMobile: true,
    items: [],
  };
};

const getProfilePageMenuItems = async (editorSDK: EditorSDK, definitions: IntegrationApplication[]) => {
  const profilePageRef = await getMemberProfilePageRef(editorSDK);

  if (!profilePageRef) {
    throw new Error('Profile page is missing while adding menu items');
  }

  const profilePageData = await getPageData({ editorSDK, pageRef: profilePageRef });

  if (!profilePageData) {
    throw new Error('Profile page data is missing while adding menu items');
  }

  const appsData = await getAppsData(editorSDK, definitions);

  return appsData.map((appData, index) => {
    const definition = definitions[index];

    if (!definition) {
      throw new Error('Page definition is missing when creating the login menu item');
    }

    // @ts-expect-error: Platform types are missing
    const appDataComponent = appData.components.find((comp) => comp.componentId === definition.widgetId);

    if (!appDataComponent) {
      throw new Error('App data for widget is missing when creating the login menu item');
    }

    return createProfilePageTpaPageLinkMenuItem(definition, profilePageData, appDataComponent.name);
  });
};

const createSettingsPageTpaPageLinkMenuItem = (
  definition: IntegrationApplication,
  pageData: PageData,
  label: string,
): MenuItem => {
  if (!definition.urlOverride) {
    throw new Error(`Split MA: urlOverride is missing for ${definition.appDefinitionId}`);
  }

  const pageId = convertPageIdToLinkPageId(pageData.id);
  const link: TpaPageLink = {
    type: 'TpaPageLink',
    itemTypeIdentifier: MenuItemIdentifier.SettingsSubPage,
    itemId: definition.widgetId,
    pageId,
    appDefinitionId: MEMBERS_AREA_BLOCKS_PLATFORM,
    path: `/${pageData.pageUriSEO}/${definition.urlOverride}`,
  };

  return {
    id: '',
    type: 'BasicMenuItem',
    label,
    link,
    isVisible: true,
    isVisibleMobile: true,
    items: [],
  };
};

const getSettingsPageMenuItems = async (editorSDK: EditorSDK, definitions: IntegrationApplication[]) => {
  const settingsPageRef = await getMemberSettingsPageRef(editorSDK);

  if (!settingsPageRef) {
    throw new Error('Settings page is missing while adding menu items');
  }

  const settingsPageData = await getPageData({ editorSDK, pageRef: settingsPageRef });

  if (!settingsPageData) {
    throw new Error('Settings page data is missing while adding menu items');
  }

  const appsData = await getAppsData(editorSDK, definitions);

  return appsData.map((appData, index) => {
    const definition = definitions[index];

    if (!definition) {
      throw new Error('Page definition is missing when creating the login menu item');
    }

    // @ts-expect-error: Platform types are missing
    const appDataComponent = appData.components.find((comp) => comp.componentId === definition.widgetId);

    if (!appDataComponent) {
      throw new Error('App data for widget is missing when creating the login menu item');
    }

    return createSettingsPageTpaPageLinkMenuItem(definition, settingsPageData, appDataComponent.name);
  });
};

const getPageLinkMenuItems = (pages: PageLinkMenuItemData[]) => {
  return pages.map<MenuItem>((page) => ({
    id: '',
    type: 'BasicMenuItem',
    label: page.title,
    link: {
      type: 'PageLink',
      target: '_self',
      pageId: `#${page.pageRef.pageId ?? page.pageRef.id}`,
      rel: [],
    } as PageLink,
    isVisible: true,
    isVisibleMobile: true,
    items: [],
  }));
};

const getMenuItemsForLoginBar = async (
  editorSDK: EditorSDK,
  options: Omit<AddApplicationItemsToMenuOptions, 'settingsPageDefinitions'>,
) => {
  const { profilePageDefinitions, standalonePrivatePages } = options;
  const profilePageDefinitionsToAdd = profilePageDefinitions.filter(({ showInLoginMenu }) => showInLoginMenu);
  const profileMenuItems =
    profilePageDefinitionsToAdd.length > 0 ? await getProfilePageMenuItems(editorSDK, profilePageDefinitionsToAdd) : [];

  return [...profileMenuItems, ...getPageLinkMenuItems(standalonePrivatePages)];
};

const getMenuItemsForLoginBarIconsMenu = async (pages: PageLinkMenuItemDataWithAppData[]) => {
  const notificationsPage = pages.find(({ tpaPageId }) => tpaPageId === MA_APP_IDS.NOTIFICATIONS.pageId);

  if (!notificationsPage) {
    return [];
  }

  const [notificationsMenuItem] = getPageLinkMenuItems([notificationsPage]);

  return notificationsMenuItem
    ? [{ ...notificationsMenuItem, iconRef: { type: 'VectorImage', svgId: '2c36dc006cb94853a49daee7e821f642.svg' } }]
    : [];
};

const getMenuItemsForProfilePageSubMenu = (editorSDK: EditorSDK, definitions: IntegrationApplication[]) => {
  const definitionsToAdd = definitions.filter(({ showInMemberMenu }) => showInMemberMenu);
  return definitionsToAdd.length > 0 ? getProfilePageMenuItems(editorSDK, definitions) : Promise.resolve([]);
};

const getMenuItemsForSettingsPageSubMenu = (editorSDK: EditorSDK, definitions: IntegrationApplication[]) => {
  const definitionsToAdd = definitions.filter(({ showInMemberMenu }) => showInMemberMenu);
  return definitionsToAdd.length > 0 ? getSettingsPageMenuItems(editorSDK, definitions) : Promise.resolve([]);
};

export const addMenuItemsToMemberMenus = async (editorSDK: EditorSDK, options: AddApplicationItemsToMenuOptions) => {
  const [
    menuItemsForLoginBar,
    menuItemsForLoginBarIconsMenu,
    menuItemsForProfilePageSubMenu,
    menuItemsForSettingsPageSubMenu,
  ] = await Promise.all([
    getMenuItemsForLoginBar(editorSDK, options),
    getMenuItemsForLoginBarIconsMenu(options.standalonePrivatePages),
    getMenuItemsForProfilePageSubMenu(editorSDK, options.profilePageDefinitions),
    getMenuItemsForSettingsPageSubMenu(editorSDK, options.settingsPageDefinitions),
  ]);

  await Promise.all([
    menuItemsForLoginBar.length > 0
      ? addMenuItems({ editorSDK, menuId: MENU_IDS.LOGIN_MENU_ID, items: menuItemsForLoginBar })
      : Promise.resolve(),
    menuItemsForLoginBarIconsMenu.length > 0
      ? addMenuItems({ editorSDK, menuId: MENU_IDS.LOGIN_ICONS_MENU_ID, items: menuItemsForLoginBarIconsMenu })
      : Promise.resolve(),
    menuItemsForProfilePageSubMenu.length > 0
      ? addMenuItems({ editorSDK, menuId: MENU_IDS.SUB_MENU_ID, items: menuItemsForProfilePageSubMenu })
      : Promise.resolve(),
    menuItemsForSettingsPageSubMenu.length > 0
      ? addMenuItems({ editorSDK, menuId: MENU_IDS.SETTINGS_SUB_MENU_ID, items: menuItemsForSettingsPageSubMenu })
      : Promise.resolve(),
  ]);
};

const getSettingsPageMenuItem = async (editorSDK: EditorSDK): Promise<MenuItem> => {
  const settingsPageRef = await getMemberSettingsPageRef(editorSDK);

  if (!settingsPageRef) {
    throw new Error('Settings page is missing while adding menu items');
  }

  const settingsPageData = await getPageData({ editorSDK, pageRef: settingsPageRef });

  if (!settingsPageData) {
    throw new Error('Settings page data is missing while adding menu items');
  }

  const [settingsPageMenuItem] = getPageLinkMenuItems([
    {
      pageRef: settingsPageRef,
      title: settingsPageData.title,
      pageUriSEO: settingsPageData.pageUriSEO,
    },
  ]);

  return settingsPageMenuItem;
};

const getMyAccountMenuItem = async (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  const settingsPageRef = await getMemberSettingsPageRef(editorSDK);

  if (!settingsPageRef) {
    throw new Error('Settings page is missing while adding My Account menu item');
  }

  const settingsPageData = await getPageData({ editorSDK, pageRef: settingsPageRef });

  if (!settingsPageData) {
    throw new Error('Settings page data is missing while adding My Account menu item');
  }

  const myAccountDefinition = getMyAccountInstallDefinition(options.origin);
  const myAccountTPA = await getDataByAppDefId({ editorSDK, appDefinitionId: MY_ACCOUNT_APP_DEF_ID });
  // @ts-expect-error: Platform types are missing, and we have no other source for apps dev center texts
  const myAccountWidget = await myAccountTPA.widgets[MY_ACCOUNT_WIDGET_ID];

  if (!myAccountWidget) {
    throw new Error('My Account widget is not found');
  }

  return createSettingsPageTpaPageLinkMenuItem(myAccountDefinition, settingsPageData, myAccountWidget.appPage.name);
};

export const addInitialMenuItemsToMemberMenus = async (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  const shouldAddMyAccountMenuItem = !(await shouldEnableMyAccountParallelInstall());

  const [settingsPageMenuItem, myAccountMenuItem] = await Promise.all([
    getSettingsPageMenuItem(editorSDK),
    shouldAddMyAccountMenuItem ? getMyAccountMenuItem(editorSDK, options) : Promise.resolve(null),
  ]);

  await Promise.all([
    addMenuItems({ editorSDK, menuId: MENU_IDS.LOGIN_MENU_ID, items: [settingsPageMenuItem] }),
    shouldAddMyAccountMenuItem
      ? addMenuItems({ editorSDK, menuId: MENU_IDS.SETTINGS_SUB_MENU_ID, items: [myAccountMenuItem!] })
      : Promise.resolve(),
  ]);
};
