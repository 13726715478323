import _ from 'lodash';
import type { CustomMenuData, DynamicPageLink, EditorSDK, MenuItem } from '@wix/platform-editor-sdk';
import type { Link, TpaPageLink } from '@wix/document-services-types';

import { APP_TOKEN, MENU_IDS, MenuItemIdentifier } from '../constants';
import type { DeepPartial } from '../../types/utility';
import { log } from '../../utils/monitoring';

const BASIC_MENU_ITEM = { type: 'BasicMenuItem', items: [], isVisible: true, isVisibleMobile: true };
const { SUB_MENU_ID, LOGIN_MENU_ID, LOGIN_ICONS_MENU_ID } = MENU_IDS;

type UpdatePagePathInMenuItemsOptions = {
  editorSDK: EditorSDK;
  menuId: string;
  currentPageUriSEO: string;
  newPageUriSEO: string;
};

type UpdateLinkPathsOptions = {
  menuItems: MenuItem[];
  currentPageUriSEO: string;
  newPageUriSEO: string;
};

export type MembersAreaMenus = {
  subMenu: CustomMenuData;
  logInMenu: CustomMenuData;
  logInIconsMenu: CustomMenuData;
};

export const getIsTpaPageLink = (link?: Link): link is TpaPageLink => {
  return link?.type === 'TpaPageLink';
};

function createLoginMenu(editorSDK: EditorSDK) {
  return editorSDK.menu.create(APP_TOKEN, {
    menuData: { name: 'Login Menu', items: [] },
    customId: LOGIN_MENU_ID,
  });
}

function createLoginIconsMenu(editorSDK: EditorSDK) {
  return editorSDK.menu.create(APP_TOKEN, {
    menuData: { name: 'Login Icons', items: [] },
    customId: LOGIN_ICONS_MENU_ID,
  });
}

async function create(editorSDK: EditorSDK) {
  await clearAndRemoveMenus(editorSDK);
  const membersMenuIdPromise = editorSDK.menu.create(APP_TOKEN, {
    menuData: { name: 'Member Menu', items: [] },
    customId: SUB_MENU_ID,
  });
  const loginMenuIdPromise = createLoginMenu(editorSDK);
  const loginIconsMenuIdPromise = createLoginIconsMenu(editorSDK);

  const [members, login, icons] = await Promise.all([
    membersMenuIdPromise.catch(() => SUB_MENU_ID),
    loginMenuIdPromise.catch(() => LOGIN_MENU_ID),
    loginIconsMenuIdPromise.catch(() => LOGIN_ICONS_MENU_ID),
  ]);

  return { members, login, icons };
}

async function getMembersAreaMenus(editorSDK: EditorSDK): Promise<MembersAreaMenus> {
  const [subMenu, logInMenu, logInIconsMenu] = await Promise.all([
    editorSDK.menu.getById(APP_TOKEN, { menuId: SUB_MENU_ID }),
    editorSDK.menu.getById(APP_TOKEN, { menuId: LOGIN_MENU_ID }),
    editorSDK.menu.getById(APP_TOKEN, { menuId: LOGIN_ICONS_MENU_ID }),
  ]);

  return {
    subMenu,
    logInMenu,
    logInIconsMenu,
  };
}

async function removeMenuItems(editorSDK: EditorSDK, menuId: string) {
  const menuData = await editorSDK.menu.getById(APP_TOKEN, { menuId });
  menuData.items = [];
  await editorSDK.menu.update(APP_TOKEN, { menuId, menuData });
}

async function removeMenuItem(editorSDK: EditorSDK, menuId: string, innerRoute: string) {
  const menuData = await editorSDK.menu.getById(APP_TOKEN, { menuId });
  menuData.items = menuData.items.filter((item) => (item.link as DynamicPageLink)?.innerRoute !== innerRoute);
  return editorSDK.menu.update(APP_TOKEN, { menuId, menuData });
}

async function removeItemsFromMenus(editorSDK: EditorSDK, menus: MembersAreaMenus) {
  return Promise.all(
    [
      menus.subMenu && removeMenuItems(editorSDK, SUB_MENU_ID),
      menus.logInMenu && removeMenuItems(editorSDK, LOGIN_MENU_ID),
      menus.logInIconsMenu && removeMenuItems(editorSDK, LOGIN_ICONS_MENU_ID),
    ].filter((p) => !!p),
  );
}

async function removeMenus(editorSDK: EditorSDK, menus: MembersAreaMenus) {
  return Promise.all(
    [
      menus.subMenu && editorSDK.menu.remove(APP_TOKEN, { menuId: SUB_MENU_ID }),
      menus.logInMenu && editorSDK.menu.remove(APP_TOKEN, { menuId: LOGIN_MENU_ID }),
      menus.logInIconsMenu && editorSDK.menu.remove(APP_TOKEN, { menuId: LOGIN_ICONS_MENU_ID }),
    ].filter((p) => !!p),
  );
}

async function clearAndRemoveMenus(editorSDK: EditorSDK) {
  const menus = await getMembersAreaMenus(editorSDK);
  await removeItemsFromMenus(editorSDK, menus);
  await removeMenus(editorSDK, menus);
}

function createNewMenuItem(linkData: Partial<MenuItem>) {
  return _.assign({}, BASIC_MENU_ITEM, linkData) as MenuItem;
}

function getMenuIds() {
  return {
    members: SUB_MENU_ID,
    login: LOGIN_MENU_ID,
    icons: LOGIN_ICONS_MENU_ID,
  };
}

async function updateMenuItemInAllMenus({
  editorSDK,
  pageRouterData,
  updatedData,
}: {
  editorSDK: EditorSDK;
  pageRouterData: { innerRoute: string };
  updatedData: DeepPartial<MenuItem>;
}) {
  const menuIds = getMenuIds();
  const updateMembersMenu = updateMenuItem({ editorSDK, pageRouterData, updatedData, menuId: menuIds.members });
  const updateLoginMenu = updateMenuItem({ editorSDK, pageRouterData, updatedData, menuId: menuIds.login });
  const updateIconsMenu = updateMenuItem({ editorSDK, pageRouterData, updatedData, menuId: menuIds.icons });

  return Promise.all([updateMembersMenu, updateLoginMenu, updateIconsMenu]);
}

async function updateMenuItem({
  editorSDK,
  pageRouterData,
  updatedData,
  menuId,
}: {
  editorSDK: EditorSDK;
  menuId: string;
  updatedData: DeepPartial<MenuItem>;
  pageRouterData: { innerRoute: string };
}) {
  const innerRoute = pageRouterData.innerRoute;
  const menuData = await editorSDK.menu.getById(APP_TOKEN, { menuId });
  const menuItem = menuData.items.find((item) => '/' + (item.link as DynamicPageLink)?.innerRoute === innerRoute);

  if (menuItem) {
    _.merge(menuItem, updatedData);
    await editorSDK.menu.update(APP_TOKEN, { menuId, menuData });
  }
}

async function getMenuItemByRoute({
  editorSDK,
  menuId,
  innerRoute,
}: {
  editorSDK: EditorSDK;
  menuId: string;
  innerRoute: string;
}) {
  const menuData = await editorSDK.menu.getById(APP_TOKEN, { menuId });

  return menuData?.items?.find((item) => '/' + (item?.link as DynamicPageLink)?.innerRoute === innerRoute);
}

async function getMenuItems({ editorSDK, menuId }: { editorSDK: EditorSDK; menuId: string }) {
  const menu = await editorSDK.menu.getById(APP_TOKEN, { menuId });

  if (!menu) {
    throw new Error('Could not retrieve the menu ' + menuId);
  }

  return menu.items;
}

function getMenuById({ editorSDK, menuId }: { editorSDK: EditorSDK; menuId: string }) {
  return editorSDK.menu.getById(APP_TOKEN, { menuId });
}

async function removePatternFromMenu({
  editorSDK,
  menuId,
  pattern,
}: {
  editorSDK: EditorSDK;
  menuId: string;
  pattern: string;
}) {
  const menuData = await editorSDK.menu.getById(APP_TOKEN, { menuId });
  const newMenuItems: MenuItem[] = [];
  for (const item of menuData.items) {
    if ((item.link as DynamicPageLink)?.innerRoute !== pattern) {
      newMenuItems.push(item);
    }
  }
  menuData.items = newMenuItems;
  await editorSDK.menu.update(APP_TOKEN, { menuId, menuData });
}

async function removePatternFromAllMenus({ editorSDK, pattern }: { editorSDK: EditorSDK; pattern: string }) {
  const menuIds = getMenuIds();
  for (const menuId of Object.values(menuIds)) {
    await removePatternFromMenu({ editorSDK, menuId, pattern });
  }
}

async function addMenuItems({ editorSDK, menuId, items }: { editorSDK: EditorSDK; menuId: string; items: MenuItem[] }) {
  const menuData = await editorSDK.menu.getById(APP_TOKEN, { menuId });
  const newMenuItems = [...(menuData?.items || []), ...items];
  return updateMenuItems({ editorSDK, menuId, items: newMenuItems });
}

async function updateMenuItems(props: { editorSDK: EditorSDK; menuId: string; items: MenuItem[] }) {
  const { editorSDK, menuId, items } = props;
  const menuData = await editorSDK.menu.getById(APP_TOKEN, { menuId });
  menuData.items = items;

  await editorSDK.menu.update(APP_TOKEN, { menuId, menuData });
  setTimeout(() => confirmMenuItemsUpdate(props), 5000);
}

async function confirmMenuItemsUpdate({
  editorSDK,
  menuId,
  items,
}: {
  editorSDK: EditorSDK;
  menuId: string;
  items: MenuItem[];
}) {
  const updatedMenu = await editorSDK.menu.getById(APP_TOKEN, { menuId });
  const shouldLog = updatedMenu?.items?.length < items.length;

  if (shouldLog) {
    const extra = { beforeItemsCount: items.length, afterItemsCount: updatedMenu.items.length };
    const tags = { menuId };
    log('Menu items did not update, items count is less than it was provided', { tags, extra });
  }
}

function getDefaultMenuId({ editorSDK }: { editorSDK: EditorSDK }) {
  return editorSDK.document.menu.getDefaultMenuId(APP_TOKEN);
}

function addMenuItem({
  editorSDK,
  menuId,
  menuItem,
}: {
  editorSDK: EditorSDK;
  menuId: string;
  menuItem: Partial<MenuItem>;
}) {
  return editorSDK.menu.addItem('', {
    menuId,
    menuItem: createNewMenuItem(menuItem),
  });
}

const updateLinkPaths = (options: UpdateLinkPathsOptions) => {
  const { menuItems, currentPageUriSEO, newPageUriSEO } = options;

  const updatedMenuItems = menuItems.map((menuItem) => {
    const { items } = menuItem;

    const childItems = items.length
      ? updateLinkPaths({
          ...options,
          menuItems: items,
        })
      : items;

    const baseOutput = {
      ...menuItem,
      items: childItems,
    };

    if (!getIsTpaPageLink(menuItem.link)) {
      return baseOutput;
    }

    const tpaPageLink = menuItem.link;
    const { path: oldPath, itemTypeIdentifier } = tpaPageLink;

    if (
      itemTypeIdentifier !== MenuItemIdentifier.SubPage &&
      itemTypeIdentifier !== MenuItemIdentifier.SettingsSubPage
    ) {
      return baseOutput;
    }

    const newPath = oldPath?.replace(`/${currentPageUriSEO}/`, `/${newPageUriSEO}/`);
    const link = { ...tpaPageLink, path: newPath };

    return { ...baseOutput, link };
  });

  return updatedMenuItems;
};

const updatePagePathInMenuItems = async ({
  editorSDK,
  menuId,
  currentPageUriSEO,
  newPageUriSEO,
}: UpdatePagePathInMenuItemsOptions) => {
  const currentItems = await getMenuItems({ editorSDK, menuId });

  if (!currentItems.length) {
    return;
  }

  const updatedMenuItems = updateLinkPaths({
    menuItems: currentItems,
    currentPageUriSEO,
    newPageUriSEO,
  });

  return updateMenuItems({ editorSDK, menuId, items: updatedMenuItems });
};

export {
  create,
  createLoginMenu,
  createLoginIconsMenu,
  createNewMenuItem,
  getMembersAreaMenus,
  getMenuById,
  getMenuIds,
  getMenuItemByRoute,
  getMenuItems,
  removeItemsFromMenus,
  removeMenus,
  clearAndRemoveMenus,
  removeMenuItem,
  removePatternFromAllMenus,
  updateMenuItem,
  updateMenuItemInAllMenus,
  updateMenuItems,
  getDefaultMenuId,
  addMenuItems,
  addMenuItem,
  updatePagePathInMenuItems,
};
