import type { AppActionClicked, EditorSDK } from '@wix/platform-editor-sdk';

import { AppManagerAction } from '../../types';
import { APP_TOKEN, SANTA_MEMBERS_APP_ID } from '../../../constants';
import { BADGES_BM_URL, MEMBERS_ACCOUNT_BM_URL, SITE_MEMBERS_BM_URL } from '../../../constants/routes';
import { navigateAndOpenManageAndNavigatePanel } from '../services/members-blocks-platform';

export const handleAppManagerEvent = async (editorSDK: EditorSDK, { eventPayload }: AppActionClicked) => {
  if (!eventPayload?.actionId) {
    return;
  }

  switch (eventPayload.actionId) {
    // Members Area panels
    case AppManagerAction.OpenCustomizeMemberPage: {
      return navigateAndOpenManageAndNavigatePanel(editorSDK);
    }
    // Editor panels
    case AppManagerAction.OpenMembersAddPanel: {
      return editorSDK.editor.deeplink.show(APP_TOKEN, {
        type: 'addPanel',
        params: [SANTA_MEMBERS_APP_ID],
      });
    }
    case AppManagerAction.OpenMembersAreaPagesPanel: {
      return editorSDK.editor.deeplink.show(APP_TOKEN, {
        type: 'pagesPanel',
        params: [SANTA_MEMBERS_APP_ID],
      });
    }
    // Dashboards
    case AppManagerAction.OpenMembersAccountBmDashboard: {
      return editorSDK.editor.openDashboardPanel(APP_TOKEN, { url: MEMBERS_ACCOUNT_BM_URL, closeOtherPanels: true });
    }
    case AppManagerAction.OpenBadgesDashboard: {
      return editorSDK.editor.openDashboardPanel(APP_TOKEN, { url: BADGES_BM_URL, closeOtherPanels: true });
    }
    case AppManagerAction.OpenSiteMembersDashboard: {
      return editorSDK.editor.openDashboardPanel(APP_TOKEN, { url: SITE_MEMBERS_BM_URL, closeOtherPanels: true });
    }
  }
};
