import { IntegrationApplicationMap } from '@wix/members-area-integration-kit';
import { EditorSDK } from '@wix/platform-editor-sdk';

interface AppState {
  isDeletingSinglePage: boolean;
  isClassicEditor: boolean;
  isResponsiveEditor: boolean;
  isADI: boolean;
  integratedAppsMap: IntegrationApplicationMap;
  staticsBaseUrl: string;
  blogWriterProfilesOnly?: boolean;
  isUninstalling: boolean;
  editorSDK: EditorSDK | undefined;
  isSuccessfulEditorReady: boolean;
  isMigration: boolean;
}

let applicationState: AppState = {
  isDeletingSinglePage: false,
  isClassicEditor: false,
  isResponsiveEditor: false,
  isADI: false,
  integratedAppsMap: {},
  staticsBaseUrl: '',
  blogWriterProfilesOnly: false,
  isUninstalling: false,
  editorSDK: undefined,
  isSuccessfulEditorReady: true,
  isMigration: false,
};

const setIsMigration = (isMigration: boolean) => {
  applicationState = {
    ...applicationState,
    isMigration,
  };
};

const setIsBlogWriterProfilesOnly = (blogWriterProfilesOnly: boolean) => {
  applicationState = {
    ...applicationState,
    blogWriterProfilesOnly,
  };
};

const setIsResponsiveEditor = (isResponsiveEditor: boolean) => {
  applicationState = {
    ...applicationState,
    isResponsiveEditor,
  };
};

const setIsClassicEditor = (isClassicEditor: boolean) => {
  applicationState = {
    ...applicationState,
    isClassicEditor,
  };
};

const setIsADI = (isADI: boolean) => {
  applicationState = {
    ...applicationState,
    isADI,
  };
};

const setStaticsBaseUrl = (staticsBaseUrl: string) => {
  applicationState = {
    ...applicationState,
    staticsBaseUrl,
  };
};

const setIntegratedApps = (integratedAppsMap: IntegrationApplicationMap) => {
  applicationState = {
    ...applicationState,
    integratedAppsMap,
  };
};

const setIsUninstalling = (isUninstalling: boolean) => {
  applicationState = {
    ...applicationState,
    isUninstalling,
  };
};
const setEditorSDK = (editorSDK: EditorSDK) => {
  applicationState = {
    ...applicationState,
    editorSDK,
  };
};

const getEditorSDK = () => applicationState.editorSDK;
const getIsUninstalling = () => applicationState.isUninstalling;
const getIsResponsiveEditor = () => applicationState.isResponsiveEditor;
const getIsClassicEditor = () => applicationState.isClassicEditor;
const getIsADI = () => applicationState.isADI;
const getStaticsBaseUrl = () => applicationState.staticsBaseUrl;
const getAllIntegratedApps = () => applicationState.integratedAppsMap || {};
const getVerticalsApps = (verticalAppDefId: string) => applicationState.integratedAppsMap[verticalAppDefId] || [];
const getIsMigration = () => applicationState.isMigration;
const getIsBlogWriterProfilesOnly = () => applicationState.blogWriterProfilesOnly;
const removeVerticalsApps = (verticalAppDefId: string) => {
  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  delete applicationState.integratedAppsMap[verticalAppDefId];
};
const getIsSuccessfulEditorReady = () => applicationState.isSuccessfulEditorReady;
const setIsSuccessfulEditorReady = (isSuccessful: boolean) => {
  applicationState.isSuccessfulEditorReady = isSuccessful;
};

export {
  getIsClassicEditor,
  setIsClassicEditor,
  setIsResponsiveEditor,
  getIsResponsiveEditor,
  setIsADI,
  getIsADI,
  setStaticsBaseUrl,
  getStaticsBaseUrl,
  setIsBlogWriterProfilesOnly,
  getIsBlogWriterProfilesOnly,
  setIntegratedApps,
  setEditorSDK,
  getAllIntegratedApps,
  removeVerticalsApps,
  getVerticalsApps,
  setIsUninstalling,
  getIsUninstalling,
  getEditorSDK,
  getIsSuccessfulEditorReady,
  setIsSuccessfulEditorReady,
  setIsMigration,
  getIsMigration,
};
