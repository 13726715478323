import { EditorSDK } from '@wix/platform-editor-sdk';
import { IntegrationApplication } from '@wix/members-area-app-definitions';

import { showProfileCard } from './members-area-page';

export const maybeShowProfileCard = async (editorSDK: EditorSDK, definitions: IntegrationApplication[]) => {
  const hasSocialPage = definitions.some(({ social }) => social);
  if (hasSocialPage) {
    return showProfileCard(editorSDK);
  }
};
