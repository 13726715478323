import type { EditorSDK, PageData } from '@wix/platform-editor-sdk';
import type { Link, TpaPageLink } from '@wix/document-services-types';

import { log } from '../../../../utils/monitoring';
import { MEMBER_SETTINGS_TPA_PAGE_ID, MEMBER_TPA_PAGE_ID, MENU_IDS, MenuItemIdentifier } from '../../../constants';
import { getApplicationPages } from '../../../wrappers/pages';
import { getIsTpaPageLink } from '../../../wrappers/menus';

import {
  getCorruptedMenuItemsData,
  getFixedMenuItemsData,
  updateMenus,
  logMenuItemsFix,
} from '../../../data-fixers/utils/fix-tpa-page-links';

const menuIds = [MENU_IDS.SUB_MENU_ID, MENU_IDS.LOGIN_MENU_ID, MENU_IDS.SETTINGS_SUB_MENU_ID];

const getPageUriSEO = (applicationPages: PageData[], itemTypeIdentifier: string) => {
  const settingsPageUriSEO = applicationPages.find(
    ({ tpaPageId }) => tpaPageId === MEMBER_SETTINGS_TPA_PAGE_ID,
  )?.pageUriSEO;

  if (settingsPageUriSEO && itemTypeIdentifier === MenuItemIdentifier.SettingsSubPage) {
    return settingsPageUriSEO;
  }

  const profilePageUriSEO = applicationPages.find(({ tpaPageId }) => tpaPageId === MEMBER_TPA_PAGE_ID)?.pageUriSEO;

  if (profilePageUriSEO && itemTypeIdentifier === MenuItemIdentifier.SubPage) {
    return profilePageUriSEO;
  }

  throw new Error('Could not find neither profile nor settings page uri SEO');
};

const getIsTpaLinkValid = (link: Link | undefined, applicationPages: PageData[]) => {
  if (!getIsTpaPageLink(link)) {
    return true;
  }

  const { itemTypeIdentifier, path } = link as TpaPageLink;

  if (itemTypeIdentifier !== MenuItemIdentifier.SubPage && itemTypeIdentifier !== MenuItemIdentifier.SettingsSubPage) {
    return true;
  }

  const pageUriSEO = getPageUriSEO(applicationPages, itemTypeIdentifier);

  return path.startsWith(`/${pageUriSEO}`);
};

const validateAndFixTpaPageLinks = async (editorSDK: EditorSDK) => {
  const applicationPages = await getApplicationPages({ editorSDK });

  const corruptedMenuItemsData = await getCorruptedMenuItemsData({
    editorSDK,
    menuIds,
    applicationPages,
    getIsTpaLinkValid,
  });

  if (!corruptedMenuItemsData.length) {
    return;
  }

  const fixedMenuItemsData = getFixedMenuItemsData({
    corruptedMenuItemsData,
    applicationPages,
    getIsTpaLinkValid,
    getPageUriSEO,
  });

  await updateMenus(editorSDK, fixedMenuItemsData);

  const fixedMenuIds = corruptedMenuItemsData.map(({ menuId }) => menuId);
  logMenuItemsFix(fixedMenuIds);
};

export const fixTpaPageLinksIfCorrupted = async (editorSDK: EditorSDK) => {
  try {
    await validateAndFixTpaPageLinks(editorSDK);
  } catch (e) {
    log('Failed to fix TpaPageLinks in menu items (v3)', {
      extra: {
        error: e,
      },
    });
  }
};
