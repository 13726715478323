import type { AppManifest } from '@wix/platform-editor-sdk';
import type { TranslationFunction } from 'i18next';

import { MembersAreaEvent } from '../../../types';
import { shouldEnableUnifiedManager } from '../../../../../utils/experiments';

type PageActions = NonNullable<AppManifest['pages']>['pageActions'];

export const getPageActions = async (t: TranslationFunction): Promise<PageActions> => {
  const deleteAction = {
    title: t('Pages_Panel_MemberMenu_Actions_Page_Delete'),
    icon: 'deleteAction',
    event: MembersAreaEvent.RemovePage,
  };

  const unifiedManagerAction = {
    title: t('UnifiedManagerPanel_EntryPoint_V2_PageMoreActions'),
    icon: 'memberAction',
    event: MembersAreaEvent.OpenUnifiedManager,
  };

  const shouldShowUnifiedManagerAction = await shouldEnableUnifiedManager();

  return {
    default: [...(shouldShowUnifiedManagerAction ? [unifiedManagerAction] : [])],
    custom: [...(shouldShowUnifiedManagerAction ? [unifiedManagerAction] : []), deleteAction],
    lightbox: [],
  };
};
