import { EditorSDK, PageData, PageRef, PanelResolveType } from '@wix/platform-editor-sdk';
import { MEMBERS_AREA } from '@wix/app-definition-ids';

import { getTranslationFunction } from '../../i18n';
import { createBIService } from '../../utils/bi';
import { APP_TOKEN } from '../constants';
import { getAppDefIdByTpaAppId } from './tpa';
import { getStaticsBaseUrl } from '../services/applicationState';
import { shouldUseNewLoginBarDeleteModal } from '../../utils/experiments';

const getAppId = async (editorSDK: EditorSDK, pageData: PageData) => {
  if (pageData.tpaApplicationId) {
    return getAppDefIdByTpaAppId({
      editorSDK,
      tpaAppId: pageData.tpaApplicationId,
    });
  }

  return pageData.managingAppDefId;
};

const getPageId = (pageData: { tpaPageId?: string; pageSecurity?: { requireLogin: boolean } }) => {
  if (pageData?.tpaPageId) {
    return pageData.tpaPageId;
  }
  const type = pageData?.pageSecurity?.requireLogin ? 'private' : 'public';
  return 'custom_' + type;
};

enum DeleteStage {
  INTENT = 'intent',
  CONFIRM = 'confirm',
  CANCEL = 'cancel',
}

const logDeleteBIEvent = async (editorSDK: EditorSDK, pageRef: PageRef, pageData: PageData, stage: DeleteStage) => {
  const biService = await createBIService({ editorSDK, withClientId: true });
  const appId = await getAppId(editorSDK, pageData);
  const pageId = getPageId(pageData);
  biService?.managePagesActionDelete({
    pageId,
    pageName: pageData.title,
    value: stage,
    appId,
  });
};

export const openRemovePagePanel = async (editorSDK: EditorSDK, pageRef: PageRef) => {
  const pageData = await editorSDK.document.pages.data.get(APP_TOKEN, {
    pageRef,
  });
  const t = await getTranslationFunction(editorSDK);
  await logDeleteBIEvent(editorSDK, pageRef, pageData, DeleteStage.INTENT);
  editorSDK.editor
    .openErrorPanel(APP_TOKEN, {
      shouldShowIllustration: true,
      headerText: t('MemberPages_Delete_Page_Title'),
      topDescriptionText: t('MemberPages_Delete_Any_Page_Warning_New', { title: pageData?.title }),
      mainActionText: t('MemberPages_Delete_AccountInfo_Button_Delete'),
      secondaryActionText: t('MemberPages_Delete_AccountInfo_Button_Cancel'),
    })
    .then(async (action: PanelResolveType) => {
      if (action === PanelResolveType.MAIN_ACTION) {
        logDeleteBIEvent(editorSDK, pageRef, pageData, DeleteStage.CONFIRM);
        const appAPI = await editorSDK.editor.getAppAPI();
        appAPI.removePage(pageData);
      } else {
        logDeleteBIEvent(editorSDK, pageRef, pageData, DeleteStage.CANCEL);
      }
    });
};

export const openUninstallPanel = async (editorSDK: EditorSDK) => {
  const t = await getTranslationFunction(editorSDK);
  editorSDK.editor
    .openErrorPanel(APP_TOKEN, {
      shouldShowIllustration: true,
      headerText: t('MemberPages_Delete_AccountInfo_Title'),
      topDescriptionText: t('MemberPages_Delete_AccountInfo_Body1'),
      bottomDescriptionText:
        t('MemberPages_Delete_AccountInfo_Body2') + ' ' + t('MemberPages_Delete_AccountInfo_Sitemembers_link'),
      mainActionText: t('MemberPages_Delete_AccountInfo_Button_Delete'),
      secondaryActionText: t('MemberPages_Delete_AccountInfo_Button_Cancel'),
    })
    .then(async (action: PanelResolveType) => {
      if (action === PanelResolveType.MAIN_ACTION) {
        const appAPI = await editorSDK.editor.getAppAPI();
        appAPI.uninstall();
      }
    });
};

const openMembersAddPanel = async (editorSDK: EditorSDK) => {
  const { check, show } = editorSDK.editor.deeplink;

  const shouldShowAddPanel = await check(APP_TOKEN, {
    type: 'addPanel',
    params: [MEMBERS_AREA],
  });

  if (shouldShowAddPanel) {
    return show(APP_TOKEN, {
      type: 'addPanel',
      params: [MEMBERS_AREA],
    });
  }
};

export const openRemoveLoginBarConfirmationPanel = async (editorSDK: EditorSDK) => {
  const t = await getTranslationFunction(editorSDK);

  if (await shouldUseNewLoginBarDeleteModal()) {
    return editorSDK.editor
      .openConfirmationPanel('', {
        headerText: t('Remove_ConfirmationPanel_Login_Bar_Title'),
        descriptionText: t('Remove_ConfirmationPanel_Login_Bar_Description'),
        mainActionText: t('Remove_ConfirmationPanel_Login_Bar_Primary_Action'),
        secondaryActionText: t('Remove_ConfirmationPanel_Login_Bar_Secondary_Action'),
        illustration: `${getStaticsBaseUrl()}assets/icons/NewDeleteSectionIcon.svg`,
        shouldShowIllustration: true,
      })
      .then(async (action: PanelResolveType) => {
        if (action === PanelResolveType.MAIN_ACTION) {
          await openMembersAddPanel(editorSDK);
        }
      });
  }

  return editorSDK.editor.openConfirmationPanel('', {
    headerText: t('ConfirmationPanel_Remove_Login_Bar_Title'),
    shouldShowIllustration: true,
    illustration: `${getStaticsBaseUrl()}assets/icons/DeleteSectionIcon.svg`,
    descriptionText: [
      {
        tag: 'p',
        content: t('ConfirmationPanel_Remove_Login_Bar_Content'),
      },
      { tag: 'li', content: t('ConfirmationPanel_Remove_Login_Bar_List_Item_One') },
      { tag: 'li', content: t('ConfirmationPanel_Remove_Login_Bar_List_Item_Two') },
      { tag: 'li', content: t('ConfirmationPanel_Remove_Login_Bar_List_Item_Three') },
      { tag: 'p', content: '' },
      { tag: 'p', content: t('ConfirmationPanel_Remove_Login_Bar_Note') },
    ],
    mainActionText: t('ConfirmationPanel_Remove_Login_Bar_Main_Action_text'),
  });
};
