import type { TranslationFunction } from 'i18next';
import type { EditorSDK, PageData } from '@wix/platform-editor-sdk';
import { AppDefinitionId, PageId, WidgetId, MA_APP_IDS } from '@wix/members-area-app-definitions';

import { toIntegrationApplication, toUnifiedManagerApplications } from '../../../../panels/utils/applicationsProcessor';
import { getTranslationFunction } from '../../../../i18n';
import type { UnifiedManagerApplication, UnifiedManagerData } from '../../../../types';
import { PageTypes } from '../../../../types/page';
import { getDefinitions } from '../../../wrappers/definitions';
import { getAllIntegratedApps } from '../../../services/applicationState';
import { SANTA_MEMBERS_APP_ID } from '../../../constants';
import { isApplicationInstalled } from '../integration/get-apps';
import { getInstalledApplications } from './members-area-page';

type CustomPagesToApplicationsProps = {
  editorSDK: EditorSDK;
  customPages: PageData[];
  t: TranslationFunction;
};

const UNREMOVABLE_APP_DEFINITION_IDS = {
  [AppDefinitionId.MyAccount]: true,
  [AppDefinitionId.Settings]: true,
  [AppDefinitionId.Notifications]: true,
};

const getCustomPagesDefinitions = (t: TranslationFunction): UnifiedManagerApplication[] => [
  {
    id: PageTypes.BLANK_PRIVATE,
    social: false,
    pageId: 'custom_private',
    appDefinitionId: SANTA_MEMBERS_APP_ID,
    widgetId: '' as WidgetId,
    page: {
      name: t('ManageMemberPages_Custom_Private_Name'),
      shortDescription: t('ManageMemberPages_Custom_Private_Shortdesc'),
      description: t('ManageMemberPages_Custom_Private_Desc'),
      iconURL: './icons/Members Area.svg',
      origin: t('ManageMemberPages_Custom_Private_Origin'),
      isNew: false,
      screenshots: ['./img/blank_private.png', './img/blank_private_mobile.png'],
    },
    isInstalled: false,
    isRemovable: true,
  },
];

const customPagesToApplications = ({ editorSDK, customPages, t }: CustomPagesToApplicationsProps) => {
  const customPageApplicationsPromise = customPages.map<Promise<UnifiedManagerApplication>>(async (customPage) => {
    const pageRef = await editorSDK.components.getById('', { id: customPage.id ?? '' });

    return {
      page: {
        name: customPage.title,
        shortDescription: t('UnifiedManagerPanel_V2_CustomPage_Title'),
        description: '',
        origin: '',
        screenshots: [],
        iconURL: `./icons/Members Area.svg`,
      },
      id: PageTypes.BLANK_PRIVATE,
      pageId: customPage.tpaPageId ?? '',
      pageRef,
      appDefinitionId: customPage.appDefinitionId ?? '',
      widgetId: '' as WidgetId,
      isInstalled: true,
      isRemovable: true,
    };
  });
  return Promise.all(customPageApplicationsPromise);
};

export const getUnifiedManagerPanelData = async (editorSDK: EditorSDK): Promise<UnifiedManagerData> => {
  const applicationPages = await editorSDK.document.pages.getApplicationPages('');
  const customPages = applicationPages.filter((page) => !page.appDefinitionId);

  const t = await getTranslationFunction(editorSDK, true);
  const customPageApplications = [
    ...(await customPagesToApplications({ editorSDK, customPages, t })),
    ...getCustomPagesDefinitions(t),
  ];

  const allIntegratedApps = toIntegrationApplication(await getAllIntegratedApps());
  const unifiedManagerApplicationsWithoutRules = toUnifiedManagerApplications(allIntegratedApps);
  const installedApplications = await getInstalledApplications(editorSDK);

  const unifiedManagerApplications = unifiedManagerApplicationsWithoutRules.map((application) => {
    const isInstalled = isApplicationInstalled(application, installedApplications);
    return {
      ...application,
      isRemovable: !UNREMOVABLE_APP_DEFINITION_IDS[application.appDefinitionId],
      isInstalled,
      shouldShowPrivacyIndicator: true,
    };
  });

  const [myAccountWidgetDefinitions] = await getDefinitions(editorSDK, [MA_APP_IDS.MY_ACCOUNT]);

  return {
    memberPageTabs: {
      title: t('UnifiedManagerPanel_ProfileTab_V2_Title'),
      description: t('UnifiedManagerPanel_ProfileTab_V2_Description'),
      applications: unifiedManagerApplications,
      applicationToNavigateOnRemoval: myAccountWidgetDefinitions,
    },
    pages: {
      title: t('UnifiedManagerPanel_PagesTab_V2_Title'),
      description: t('UnifiedManagerPanel_PagesTab_V2_Description'),
      applications: customPageApplications,
    },
  };
};
