import type { EditorSDK, PageData, PopupData, PageRef } from '@wix/platform-editor-sdk';

import { toMonitored } from '../../../../utils/monitoring';
import { shouldEnableFollowersAsLightbox } from '../../../../utils/experiments';
import { getTranslationFunction } from '../../../../i18n';
import { APP_TOKEN, MEMBER_SETTINGS_TPA_PAGE_ID, MEMBER_TPA_PAGE_ID } from '../../../constants';
import { getMemberProfilePageRef, getMemberSettingsPageRef, getPageRefByTPAPageId } from '../../../wrappers/tpa';
import { addPage, getPageData, remove } from '../../../wrappers/pages';
import { getApplicationPopupPages } from '../../../wrappers/popupPages';
import { getById as getPageById } from '../../../wrappers/components';
import {
  removeProfilePageMenuItemsFromMenus,
  updateProfilePagePathInMenus,
  updateSettingsPagePathInMenus,
} from './menu';
import { setProfilePageRoutes } from './routes';

type StateTpaPageIdMap = {
  settings?: string;
  profile?: string;
  standalone: string[];
  custom: string[];
};

type PageStateMap = {
  settings?: PageRef[];
  profile?: PageRef[];
  standalone?: PageRef[];
  custom?: PageRef[];
};

const getPageStateMap = async (
  editorSDK: EditorSDK,
  pagesData: PageData[],
  popupData: PopupData[],
): Promise<PageStateMap> => {
  const stateTpaPageIdMap = pagesData.reduce<StateTpaPageIdMap>(
    (map, { tpaPageId, id }) => {
      if (tpaPageId === MEMBER_SETTINGS_TPA_PAGE_ID) {
        return { ...map, settings: tpaPageId };
      } else if (tpaPageId === MEMBER_TPA_PAGE_ID) {
        return { ...map, profile: tpaPageId };
      } else if (tpaPageId) {
        return { ...map, standalone: [...map.standalone, tpaPageId] };
      } else if (id) {
        return { ...map, custom: [...map.custom, id] };
      }

      return map;
    },
    { standalone: [], custom: [] },
  );

  return {
    ...(stateTpaPageIdMap.settings && {
      settings: [await getPageRefByTPAPageId(editorSDK, stateTpaPageIdMap.settings)],
    }),
    ...(stateTpaPageIdMap.profile && {
      profile: [await getPageRefByTPAPageId(editorSDK, stateTpaPageIdMap.profile)],
    }),
    ...(stateTpaPageIdMap.standalone.length > 0 && {
      standalone: await Promise.all(
        stateTpaPageIdMap.standalone.map((tpaPageId) => getPageRefByTPAPageId(editorSDK, tpaPageId)),
      ),
    }),
    ...(stateTpaPageIdMap.custom.length > 0 && {
      custom: await Promise.all(stateTpaPageIdMap.custom.map((pageId) => getPageById({ editorSDK, id: pageId }))),
    }),
    ...(popupData.length > 0 && {
      lightbox: await Promise.all(popupData.map(({ id }) => getPageById({ editorSDK, id: id! }))),
    }),
  };
};

export const setStateForPages = async (editorSDK: EditorSDK) => {
  const isFollowersAsLightboxEnabled = await shouldEnableFollowersAsLightbox();

  const [applicationPages, applicationPopupPages] = await Promise.all([
    editorSDK.document.pages.getApplicationPages(APP_TOKEN),
    isFollowersAsLightboxEnabled ? getApplicationPopupPages(editorSDK) : Promise.resolve([]),
  ]);

  await editorSDK.pages.setState(APP_TOKEN, {
    state: await getPageStateMap(editorSDK, applicationPages, applicationPopupPages),
  });
};

type UpdatePagePathInMenusOptions = {
  editorSDK: EditorSDK;
  tpaPageId: string | undefined;
  currentPageUriSEO: string;
  newPageUriSEO: string;
};

const updatePagePathInMenus = async ({
  editorSDK,
  tpaPageId,
  currentPageUriSEO,
  newPageUriSEO,
}: UpdatePagePathInMenusOptions) => {
  if (tpaPageId === MEMBER_SETTINGS_TPA_PAGE_ID) {
    return updateSettingsPagePathInMenus(editorSDK, currentPageUriSEO, newPageUriSEO);
  } else if (tpaPageId === MEMBER_TPA_PAGE_ID) {
    return updateProfilePagePathInMenus(editorSDK, currentPageUriSEO, newPageUriSEO);
  }
};

export const updatePageUriSEO = async (
  editorSDK: EditorSDK,
  pageRef: PageRef,
  newPageData: Pick<PageData, 'pageUriSEO'>,
) => {
  const pageData = await getPageData({ editorSDK, pageRef });
  if (pageData.pageUriSEO === newPageData.pageUriSEO) {
    return;
  }

  await Promise.all([
    editorSDK.pages.data.update(APP_TOKEN, { pageRef, data: { pageUriSEO: newPageData.pageUriSEO } }),
    updatePagePathInMenus({
      editorSDK,
      tpaPageId: pageData.tpaPageId,
      currentPageUriSEO: pageData.pageUriSEO,
      newPageUriSEO: newPageData.pageUriSEO,
    }),
  ]);
};

export const createBlankPrivatePage = async (editorSDK: EditorSDK) => {
  const t = await toMonitored('private-api.v3.createPrivateMemberPage.getTranslationFunction', () =>
    getTranslationFunction(editorSDK),
  );

  const pageTitle = t('Pages_Private_Member_Page_Title');
  const pageRef = await toMonitored('private-api.v3.createPrivateMemberPage.addPage', () =>
    addPage({
      editorSDK,
      pageTitle,
      pageSecurity: { requireLogin: true },
      shouldAddMenuItem: false,
    }),
  );

  return { pageTitle, pageRef };
};

export const removePage = async (editorSDK: EditorSDK, pageData: PageData) => {
  const memberSettingsPageRef = await getMemberSettingsPageRef(editorSDK).catch(() => undefined);

  if (pageData.tpaPageId === MEMBER_TPA_PAGE_ID) {
    const pageRef = await getMemberProfilePageRef(editorSDK);
    await Promise.all([
      setProfilePageRoutes(editorSDK, []),
      removeProfilePageMenuItemsFromMenus(editorSDK),
      remove({
        editorSDK,
        pageRef,
        shouldShowEditorRemovePanel: false,
        pageToNavigateAfterRemove: memberSettingsPageRef,
      }),
    ]);
    return;
  } else if (pageData.tpaPageId) {
    const pageRef = await getPageRefByTPAPageId(editorSDK, pageData.tpaPageId);
    return remove({
      editorSDK,
      pageRef,
      shouldShowEditorRemovePanel: false,
      pageToNavigateAfterRemove: memberSettingsPageRef,
    });
  } else if (pageData.id) {
    const pageRef = await getPageById({ editorSDK, id: pageData.id });
    return remove({
      editorSDK,
      pageRef,
      shouldShowEditorRemovePanel: false,
      pageToNavigateAfterRemove: memberSettingsPageRef,
    });
  }

  throw new Error(`[SplitMA] Failed to find pageRef for page removal`);
};
