import type { EditorSDK } from '@wix/platform-editor-sdk';

import type { MembersAreaPrivateApi } from '../../types';
import { APP_TOKEN } from '../../../constants';
import { buildPanelUrl } from '../../../services/panel-url-builder';
import { getIsClassicEditor } from '../../../services/applicationState';

export const handleCreateBlankPageEvent = async (editorSDK: EditorSDK) => {
  const memberAreaPrivateAPI: MembersAreaPrivateApi = await editorSDK.editor.getAppAPI();

  if (getIsClassicEditor()) {
    return editorSDK.editor.openModalPanel(APP_TOKEN, {
      url: await buildPanelUrl(editorSDK, 'privateMemberPage.html'),
      width: 600,
      height: 467,
      shouldHideHeader: true,
      initialData: null,
    });
  }

  return memberAreaPrivateAPI.createPrivateMemberPage();
};
