import { EditorSDK } from '@wix/platform-editor-sdk';

export const isCurrentLanguageOriginal = async (language: EditorSDK['language']) => {
  if (await language.multilingual.isEnabled('')) {
    const currentLanguageCode = await language.current.get('');
    const originalLanguage = await language.original.get('');

    return currentLanguageCode === originalLanguage.languageCode;
  }

  return true;
};
