import type { EditorSDK } from '@wix/platform-editor-sdk';
import type { IntegrationApplication } from '@wix/members-area-app-definitions';
import { WidgetId } from '@wix/members-area-app-definitions';

import { APP_TOKEN, PROFILE_PAGE_BOB_APP_DEF_ID, SANTA_MEMBERS_APP_ID } from '../../../constants';

import type { MembersAreaPagePublicApi as MembersAreaBlocksPlatformPublicAPI, Lightbox } from '../../types';

export const installMembersBlocksPlatform = async (editorSDK: EditorSDK, isSocialInstallation: boolean) => {
  return editorSDK.document.application.add(APP_TOKEN, {
    appDefinitionId: PROFILE_PAGE_BOB_APP_DEF_ID,
    managingAppDefId: SANTA_MEMBERS_APP_ID,
    isSilent: true,
    originInfo: { isSocialInstallation },
  });
};

export const PUBLIC_API_NOT_FOUND_ERROR = '[MAV3] Members Area Block Platform PublicAPI is not found';

export const getMembersAreaBlocksPlatformPublicAPI = async (editorSDK: EditorSDK) => {
  const membersAreaBlocksPlatformPublicAPI = await editorSDK.application.getPublicAPI(APP_TOKEN, {
    appDefinitionId: PROFILE_PAGE_BOB_APP_DEF_ID,
  });

  if (!membersAreaBlocksPlatformPublicAPI) {
    throw new Error(PUBLIC_API_NOT_FOUND_ERROR);
  }

  return membersAreaBlocksPlatformPublicAPI as MembersAreaBlocksPlatformPublicAPI;
};

export const uninstallMembersBlocksPlatform = async (editorSDK: EditorSDK) => {
  const membersAreaBlocksPlatformPublicAPI = await getMembersAreaBlocksPlatformPublicAPI(editorSDK);
  return membersAreaBlocksPlatformPublicAPI.uninstall();
};

export const addApplicationsToSlots = async (
  editorSDK: EditorSDK,
  applications: IntegrationApplication[],
  shouldNavigate: boolean = false,
) => {
  const membersAreaBlocksPlatformPublicAPI = await getMembersAreaBlocksPlatformPublicAPI(editorSDK);
  return membersAreaBlocksPlatformPublicAPI.addWidgetsPlugins(applications, shouldNavigate);
};

export const addLightboxes = async (editorSDK: EditorSDK, lightboxes: Lightbox[]) => {
  const membersAreaBlocksPlatformPublicAPI = await getMembersAreaBlocksPlatformPublicAPI(editorSDK);
  return membersAreaBlocksPlatformPublicAPI.addLightboxes(lightboxes);
};

export const removeApplicationsFromSlots = async (editorSDK: EditorSDK, widgetIds: WidgetId[]) => {
  const membersAreaBlocksPlatformPublicAPI = await getMembersAreaBlocksPlatformPublicAPI(editorSDK);
  return membersAreaBlocksPlatformPublicAPI.removeWidgets(widgetIds);
};

export const refreshMembersBlocksPlatform = async (editorSDK: EditorSDK) => {
  const membersAreaBlocksPlatformPublicAPI = await getMembersAreaBlocksPlatformPublicAPI(editorSDK);
  return membersAreaBlocksPlatformPublicAPI.refreshApp();
};

export const isMembersAreaBlocksPlatformValid = async (editorSDK: EditorSDK) => {
  try {
    const membersAreaBlocksPlatformPublicAPI = await getMembersAreaBlocksPlatformPublicAPI(editorSDK);
    return await membersAreaBlocksPlatformPublicAPI.isProfilePageBobValid();
  } catch (e) {
    return false;
  }
};

export const navigateToSection = async (editorSDK: EditorSDK, widgetId: WidgetId) => {
  const membersAreaBlocksPlatformPublicAPI = await getMembersAreaBlocksPlatformPublicAPI(editorSDK);
  return membersAreaBlocksPlatformPublicAPI.navigateToSection(widgetId);
};

export const navigateAndOpenManageAndNavigatePanel = async (editorSDK: EditorSDK) => {
  const membersAreaBlocksPlatformPublicAPI = await getMembersAreaBlocksPlatformPublicAPI(editorSDK);
  await membersAreaBlocksPlatformPublicAPI.navigateToSection(WidgetId.MyAccount);
  return membersAreaBlocksPlatformPublicAPI.openManageAndNavigatePanel();
};
