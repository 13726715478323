import { AppManifest } from '@wix/platform-editor-sdk';
import { TranslationFunction } from 'i18next';

import { applicationActions } from '../actions';
import { shouldEnableUnifiedManager } from '../../../../../utils/experiments';

type ApplicationActions = NonNullable<AppManifest['pages']>['applicationActions'];

export const getApplicationActions = async (t: TranslationFunction): Promise<ApplicationActions> => {
  const actions = [
    ...((await shouldEnableUnifiedManager())
      ? [applicationActions.getUnifiedManagerAction(t)]
      : [applicationActions.getAddPageAction(t)]),
    applicationActions.viewSiteMembersAction,
    applicationActions.getOpenMemberPrivacyDashboardAction(t),
    applicationActions.openSignUpSettingsAction,
    applicationActions.getDeleteMembersAreaAction(t),
  ];

  return { default: actions };
};
