import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';

export const updateGlobalController = async ({
  editorSDK,
  controllerRef,
  newConfig,
}: {
  editorSDK: EditorSDK;
  controllerRef: ComponentRef;
  newConfig: object;
}) => {
  const { config } = await editorSDK.controllers.getData('', {
    controllerRef,
    scope: 'APP',
  });

  return editorSDK.controllers.saveConfiguration('', {
    controllerRef,
    scope: 'APP',
    config: { ...config, ...newConfig },
  });
};
