import type { EditorSDK } from '@wix/platform-editor-sdk';

import type { RootStyleData, HorizontalMemberMenuMigrationData } from './v2-migration-types';
import { VERTICAL_MENU_TYPE, DROPDOWN_MENU_TYPE } from './v2-migration-member-menu-components';
import { removeUndefinedProps } from './v2-migration-utils';
import { getStylePropsOverrides as getVerticalMenuStylePropsOverrides } from './v2-migration-vertical-member-menu';
import { migrateHorizontalMemberMenuComponent } from './v2-migration-horizontal-member-menu';

const getVerticalMenuStylePropsToMigrate = async (oldStyle: RootStyleData) => {
  const oldStyleProps = oldStyle.style.properties;
  const oldStylePropsOverride = oldStyle.style.propertiesOverride;

  const properties = removeUndefinedProps({
    bg: oldStyleProps.bg,
    'alpha-bg': oldStyleProps['alpha-bg'],
    bgs: oldStyleProps.bgs,
    'alpha-bgs': oldStyleProps['alpha-bgs'],
    bgDrop: oldStyleProps.SKINS_bgSubmenu,
    'alpha-bgDrop': oldStyleProps['alpha-SKINS_bgSubmenu'],
    txt: oldStyleProps.txt,
    txts: oldStyleProps.txts,
    fnt: oldStyleProps.fnt,
    fntDrop: oldStyleProps.SKINS_fntSubmenu,
  });

  const propertiesOverride = getVerticalMenuStylePropsOverrides(oldStylePropsOverride || {});

  return {
    properties,
    propertiesOverride,
  };
};

const migrateMobileHorizontalMenuFromVerticalMenu = async (
  editorSDK: EditorSDK,
  migrationData: HorizontalMemberMenuMigrationData,
) => {
  const { oldMemberMenuComponentSettings } = migrationData;
  const pickedStyleProps = await getVerticalMenuStylePropsToMigrate(oldMemberMenuComponentSettings.style);

  const updatedMigrationData = {
    ...migrationData,
    oldMemberMenuComponentSettings: {
      ...oldMemberMenuComponentSettings,
      style: {
        ...oldMemberMenuComponentSettings.style,
        style: {
          ...oldMemberMenuComponentSettings.style.style,
          ...pickedStyleProps,
        },
      },
    },
  };

  return migrateHorizontalMemberMenuComponent({
    editorSDK,
    migrationData: updatedMigrationData,
    isMobile: true,
  });
};

export const migrateMobileMemberMenuComponent = async (
  editorSDK: EditorSDK,
  migrationData: HorizontalMemberMenuMigrationData,
) => {
  const { oldMemberMenuComponentSettings } = migrationData;

  if (oldMemberMenuComponentSettings.componentType === VERTICAL_MENU_TYPE) {
    return migrateMobileHorizontalMenuFromVerticalMenu(editorSDK, migrationData);
  }

  if (oldMemberMenuComponentSettings.componentType === DROPDOWN_MENU_TYPE) {
    return migrateHorizontalMemberMenuComponent({
      editorSDK,
      migrationData,
      isMobile: true,
    });
  }

  throw new Error('MA templates migration - received unexpected componentType');
};
