import { ContextParams, EditorSDK } from '@wix/platform-editor-sdk';
import { MEMBERS_AREA_V2 } from '@wix/app-definition-ids';
import { MA_APP_IDS } from '@wix/members-area-app-definitions';

import { MembersAreaContext } from '../../types/context';
import {
  shouldEnableMembersAreaContextCheck,
  shouldEnableV2SilentInstall,
  shouldInstallMembersAreaV2,
  shouldInstallMembersAreaV3,
} from '../../utils/experiments';
import { isApplicationInstalled } from '../wrappers/application';
import { getGlobalMembersAreaController } from '../wrappers/controllers';
import { isAppSectionInstalled, isMemberSettingsPageInstalled } from '../wrappers/tpa';
import { isApplicationReady as isV1Installed } from '../applicationState';
import { getIsADI } from './applicationState';

declare const self: {
  commonConfig: {
    brand: string;
  };
};

const getIsMembersAreaV1Installed = async (editorSDK: EditorSDK) => {
  return isAppSectionInstalled({
    editorSDK,
    appDefinitionId: MA_APP_IDS.MY_ACCOUNT.appDefinitionId,
    sectionId: MA_APP_IDS.MY_ACCOUNT.pageId,
  });
};

const getIsMembersAreaV2Installed = async (editorSDK: EditorSDK) => {
  return isApplicationInstalled(editorSDK, MEMBERS_AREA_V2);
};

const getIsMembersAreaV3Installed = async (editorSDK: EditorSDK) => {
  return isMemberSettingsPageInstalled(editorSDK);
};

const isMembersAreaV1Context = async (editorSDK: EditorSDK, options?: ContextParams) => {
  return getIsADI() || (options?.firstInstall && getIsMembersAreaV1Installed(editorSDK));
};

/** @deprecated */
const getIsMembersAreaV2 = async (editorSDK: EditorSDK, options?: ContextParams) => {
  if (self?.commonConfig?.brand === 'studio') {
    const isV1 = await isV1Installed(editorSDK);
    return !isV1;
  }

  const shouldOptOutFromSilentInstall = !!options?.silentInstallation && !(await shouldEnableV2SilentInstall());

  if (shouldOptOutFromSilentInstall || (await isMembersAreaV1Context(editorSDK, options))) {
    return false;
  }

  if (options?.firstInstall) {
    return shouldInstallMembersAreaV2();
  }

  return isApplicationInstalled(editorSDK, MEMBERS_AREA_V2);
};

const getMemberAreaContextFromInstalledApps = async (editorSDK: EditorSDK) => {
  const [isMembersAreaV1Installed, isMembersAreaV2Installed, isMembersAreaV3Installed] = await Promise.all([
    await isV1Installed(editorSDK),
    await getIsMembersAreaV2Installed(editorSDK),
    await getIsMembersAreaV3Installed(editorSDK),
  ]);

  if (isMembersAreaV1Installed) {
    return MembersAreaContext.V1;
  } else if (isMembersAreaV3Installed) {
    return MembersAreaContext.V3;
  } else if (isMembersAreaV2Installed) {
    return MembersAreaContext.V2;
  }

  const isMembersAreaV3Enabled = await shouldInstallMembersAreaV3();
  return isMembersAreaV3Enabled ? MembersAreaContext.V3 : MembersAreaContext.V2;
};

export const getMembersAreaContext = async (editorSDK: EditorSDK, options?: ContextParams) => {
  const shouldUseNewContextCheck = await shouldEnableMembersAreaContextCheck();
  if (shouldUseNewContextCheck) {
    const isADI = getIsADI();
    if (isADI) {
      return MembersAreaContext.V1;
    }

    return getMemberAreaContextFromInstalledApps(editorSDK);
  }

  const isMembersAreaV2 = await getIsMembersAreaV2(editorSDK, options);
  return isMembersAreaV2 ? MembersAreaContext.V2 : MembersAreaContext.V1;
};

export const getIsMembersAreaSeoEnabled = async (editorSDK: EditorSDK) => {
  try {
    const controllerConfig = await getGlobalMembersAreaController(editorSDK);

    return !!controllerConfig?.isMembersAreaSeoEnabled;
  } catch (e) {
    return false;
  }
};
