import Color from 'color';
import { APP_TOKEN, MEMBER_TPA_PAGE_ID } from '../constants';
import { ComponentRef, EditorSDK, StyleParam, StyleParams, TPAPublicDataScope } from '@wix/platform-editor-sdk';

const MEMBER_SETTINGS_TPA_PAGE_ID = 'member_settings_page';

const STYLE_PARAMS_TYPES_MAP = {
  booleans: 'boolean',
  numbers: 'number',
  fonts: 'font',
  colors: 'color',
  manuals: 'manual',
};

function addApplication(editorSDK: EditorSDK, widgetDef: Parameters<EditorSDK['tpa']['add']['application']>[1]) {
  return editorSDK.tpa.add.application(APP_TOKEN, widgetDef);
}

function addComponent(editorSDK: EditorSDK, options: Parameters<EditorSDK['tpa']['add']['component']>[1]) {
  return editorSDK.tpa.add.component(APP_TOKEN, options);
}

function isApplicationInstalled({ editorSDK, appDefinitionId }: { editorSDK: EditorSDK; appDefinitionId: string }) {
  return editorSDK.tpa.isApplicationInstalled(APP_TOKEN, { appDefinitionId });
}

function isAppSectionInstalled({
  editorSDK,
  appDefinitionId,
  sectionId,
}: {
  editorSDK: EditorSDK;
  appDefinitionId: string;
  sectionId: string;
}) {
  return editorSDK.tpa.isAppSectionInstalled(APP_TOKEN, { appDefinitionId, sectionId });
}

function getDataByAppDefId({ editorSDK, appDefinitionId }: { editorSDK: EditorSDK; appDefinitionId: string }) {
  return editorSDK.tpa.app.getDataByAppDefId(APP_TOKEN, appDefinitionId);
}

function getAllCompsByApplicationId({
  editorSDK,
  applicationId,
}: {
  editorSDK: EditorSDK;
  applicationId: Parameters<EditorSDK['tpa']['app']['getAllCompsByApplicationId']>[1];
}) {
  return editorSDK.tpa.app.getAllCompsByApplicationId(APP_TOKEN, applicationId);
}

async function getAppDefIdByTpaAppId({
  editorSDK,
  tpaAppId,
}: {
  editorSDK: EditorSDK;
  tpaAppId: Parameters<EditorSDK['tpa']['app']['getAllCompsByApplicationId']>[1];
}) {
  const components = await editorSDK.tpa.app.getAllCompsByApplicationId('', tpaAppId);
  return components?.[0]?.appDefinitionId;
}

function getStyleParams(editorSDK: EditorSDK, compRef: ComponentRef) {
  return editorSDK.tpa.getStyleParams(APP_TOKEN, { compRef });
}

function getTpaData(editorSDK: EditorSDK, compRef: ComponentRef) {
  return editorSDK.tpa.data.getAll(APP_TOKEN, { compRef });
}

function parseStyleParamForSetter(styleParams: StyleParams, styleType: string, styleParam: string) {
  if (styleType === 'colors') {
    const paramValue = styleParams[styleType][styleParam];

    if (!paramValue.themeName) {
      return { color: null, cssColor: paramValue.value };
    }

    const color = new Color(paramValue.value);
    const opacity = color.alpha();
    return { color: { value: paramValue.value, name: paramValue.themeName }, opacity };
  }

  return styleParams[styleType][styleParam];
}

function parseStyleParamsForSetter(styleParams: StyleParams): StyleParam[] {
  const compatibleStyles: StyleParam[] = [];

  for (const styleType of Object.keys(styleParams)) {
    const setterType = STYLE_PARAMS_TYPES_MAP[styleType];

    if (typeof setterType === 'undefined') {
      continue;
    }

    for (const styleParam of Object.keys(styleParams[styleType])) {
      try {
        const param = { value: parseStyleParamForSetter(styleParams, styleType, styleParam) };
        compatibleStyles.push({ key: styleParam, type: setterType, param });
      } catch (e: any) {
        console.error('Failed to copy a style param, error:' + e.message);
      }
    }
  }

  return compatibleStyles;
}

function parseTpaDataParamsForSetter(dataParams, compRef: ComponentRef) {
  return Object.keys(dataParams).map((key) => ({
    compRef,
    key,
    value: dataParams[key]!,
    scope: 'COMPONENT' as TPAPublicDataScope,
  }));
}

function getPageRefByTPAPageId(editorSDK: EditorSDK, tpaPageId: string) {
  return editorSDK.document.tpa.getPageRefByTPAPageId(APP_TOKEN, { tpaPageId });
}

function getMemberSettingsPageRef(editorSDK: EditorSDK) {
  return editorSDK.document.tpa.getPageRefByTPAPageId(APP_TOKEN, {
    tpaPageId: MEMBER_SETTINGS_TPA_PAGE_ID,
  });
}

async function isMemberSettingsPageInstalled(editorSDK: EditorSDK) {
  const pageRef = await getMemberSettingsPageRef(editorSDK);
  return Boolean(pageRef);
}

function getMemberProfilePageRef(editorSDK: EditorSDK) {
  return editorSDK.document.tpa.getPageRefByTPAPageId(APP_TOKEN, {
    tpaPageId: MEMBER_TPA_PAGE_ID,
  });
}

async function isMemberProfilePageInstalled(editorSDK: EditorSDK) {
  const pageRef = await getMemberProfilePageRef(editorSDK);
  return Boolean(pageRef);
}

export {
  addApplication,
  addComponent,
  isApplicationInstalled,
  isAppSectionInstalled,
  getDataByAppDefId,
  getAllCompsByApplicationId,
  getAppDefIdByTpaAppId,
  getStyleParams,
  getTpaData,
  getPageRefByTPAPageId,
  parseStyleParamsForSetter,
  parseTpaDataParamsForSetter,
  getMemberSettingsPageRef,
  isMemberSettingsPageInstalled,
  getMemberProfilePageRef,
  isMemberProfilePageInstalled,
};
