import { EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';

import { toMonitored } from '../../../../utils/monitoring';
import { installMembersBlocksPlatform } from '../services/members-blocks-platform';
import { navigateToMemberSettingsPage } from '../services/navigation';
import { addGlobalController } from '../services/global-controller';
import { addLoginMenus, addMemberSettingsSubMenu, addProfileSubMenu } from '../services/menu';
import { addInitialMenuItemsToMemberMenus } from '../integration/menu-items';

export const monitoredAddGlobalController = async (editorSDK: EditorSDK) => {
  try {
    await toMonitored('v3.install.addGlobalController', () => addGlobalController(editorSDK));
  } catch (error: any) {
    if (error?.message?.includes?.('already exists')) {
      return;
    }

    throw error;
  }
};

export const monitoredInstallMembersBlocksPlatform = (editorSDK: EditorSDK, isSocialInstallation: boolean) => {
  const action = () => installMembersBlocksPlatform(editorSDK, isSocialInstallation);
  return toMonitored('v3.install.installMembersBlocksPlatform', action);
};

export const monitoredAddMemberSettingsSubMenu = (editorSDK: EditorSDK) => {
  const action = () => addMemberSettingsSubMenu(editorSDK);
  return toMonitored('v3.install.addMemberSettingsSubMenu', action);
};

export const monitoredAddProfileSubMenu = (editorSDK: EditorSDK) => {
  const action = () => addProfileSubMenu(editorSDK);
  return toMonitored('v3.install.addProfileSubMenu', action);
};

export const monitoredAddLoginMenus = (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  const action = () => addLoginMenus(editorSDK, options);
  return toMonitored('v3.install.addLoginMenus', action);
};

export const monitoredAddInitialMenuItemsToMemberMenus = (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  const action = () => addInitialMenuItemsToMemberMenus(editorSDK, options);
  return toMonitored('v3.install.addInitialMenuItemsToMemberMenus', action);
};

export const monitoredNavigateToMemberSettingsPage = (editorSDK: EditorSDK) => {
  return toMonitored('v3.install.navigateAfterInstallation', () => navigateToMemberSettingsPage(editorSDK));
};
