import type { EditorSDK, PageData, PageRef } from '@wix/platform-editor-sdk';

import { toMonitored } from '../../../../utils/monitoring';
import { shouldEnableFollowersAsLightbox } from '../../../../utils/experiments';
import { getTranslationFunction } from '../../../../i18n';
import { APP_TOKEN, MEMBER_TPA_PAGE_ID } from '../../../constants';
import { addPage, getPageData, remove } from '../../../wrappers/pages';
import { getApplicationPopupPages } from '../../../wrappers/popupPages';
import { getById } from '../../../wrappers/components';
import { getMembersAreaPageRef } from './members-area-page';
import { updateMemberPagePathInMenus } from './menus';

export const createBlankPrivatePage = async (editorSDK: EditorSDK) => {
  const t = await toMonitored('private-api.v2.createPrivateMemberPage.getTranslationFunction', () =>
    getTranslationFunction(editorSDK),
  );

  const pageTitle = t('Pages_Private_Member_Page_Title');
  const pageRef = await toMonitored('private-api.v2.createPrivateMemberPage.addPage', () =>
    addPage({
      editorSDK,
      pageTitle,
      pageSecurity: { requireLogin: true },
      shouldAddMenuItem: false,
    }),
  );

  return { pageTitle, pageRef };
};

export const removePage = async (editorSDK: EditorSDK, { pageId }: { pageId: string }) => {
  const pageRef = await getById({ editorSDK, id: pageId });
  if (!pageRef) {
    throw new Error('Page not found');
  }

  const membersAreaPageRef = await getMembersAreaPageRef(editorSDK).catch(() => undefined);

  return remove({
    editorSDK,
    pageRef,
    pageToNavigateAfterRemove: membersAreaPageRef,
    shouldShowEditorRemovePanel: false,
  });
};

const setStateForCustomPages = async (editorSDK: EditorSDK) => {
  const applicationPages = await editorSDK.document.pages.getApplicationPages('');
  const customPages = applicationPages.filter((page) => page.tpaPageId !== MEMBER_TPA_PAGE_ID);

  customPages.forEach(async (pageData) =>
    editorSDK.pages.setState(APP_TOKEN, {
      state: {
        // @ts-expect-error
        custom: [{ id: pageData.id }],
      },
    }),
  );
};

const setStateForLightboxPopupPages = async (editorSDK: EditorSDK) => {
  const applicationPopupPages = await getApplicationPopupPages(editorSDK);

  applicationPopupPages.forEach(async (pageData) =>
    editorSDK.pages.setState(APP_TOKEN, {
      state: {
        // @ts-expect-error
        lightbox: [{ id: pageData.id }],
      },
    }),
  );
};

export const setStateForPages = async (editorSDK: EditorSDK) => {
  const isFollowersAsLightboxEnabled = await shouldEnableFollowersAsLightbox();

  await Promise.all([
    setStateForCustomPages(editorSDK),
    isFollowersAsLightboxEnabled ? setStateForLightboxPopupPages(editorSDK) : Promise.resolve(),
  ]);
};

export const updatePageUriSEO = async (
  editorSDK: EditorSDK,
  pageRef: PageRef,
  newPageData: Pick<PageData, 'pageUriSEO'>,
) => {
  const pageData = await getPageData({ editorSDK, pageRef });
  if (pageData.pageUriSEO === newPageData.pageUriSEO) {
    return;
  }

  await Promise.all([
    editorSDK.pages.data.update(APP_TOKEN, { pageRef, data: { pageUriSEO: newPageData.pageUriSEO } }),
    updateMemberPagePathInMenus(editorSDK, pageData.pageUriSEO, newPageData.pageUriSEO),
  ]);
};
