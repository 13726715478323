import { MembersAreaAppId } from '@wix/members-area-app-definitions';
import { PageRef } from '@wix/platform-editor-sdk';
import { IntegrationApplication } from '@wix/members-area-integration-kit';

const arePagesEqual = (page1: MembersAreaAppId, page2: MembersAreaAppId) =>
  page1.appDefinitionId === page2.appDefinitionId && page1.pageId === page2.pageId;

const isNotDuplicatedApp = (app: MembersAreaAppId, uniqueApps: MembersAreaAppId[]) =>
  !uniqueApps.some((app2) => app2.appDefinitionId === app.appDefinitionId && app2.pageId === app.pageId);

const getUniqueApplications = <T extends IntegrationApplication | MembersAreaAppId>(pages: T[]): T[] =>
  pages.reduce(
    (uniqueApps, app) => (isNotDuplicatedApp(app, uniqueApps) ? [...uniqueApps, app] : uniqueApps),
    [] as T[],
  );

const getPageRoles = (router) =>
  Object.keys(router.config.patterns)
    .map((pattern) => router.config.patterns[pattern])
    .filter((pageConfig) => !pageConfig.appData?.appDefinitionId)
    .map((pageConfig) => pageConfig.page);

const getCustomPages = async (editorSDK) => {
  const pages: { pageRef: PageRef; role: string }[] = [];
  const routerPages = await editorSDK.document.routers.getAll();

  routerPages.forEach((router) => {
    if (!router.config.patterns) {
      return;
    }

    const pageRoles = getPageRoles(router);

    router.pages.forEach((page) => {
      if (pageRoles.includes(page.pageRoles[0])) {
        pages.push({ pageRef: page.pageRef, role: page.pageRoles[0] });
      }
    });
  });

  return pages;
};

export { getCustomPages, isNotDuplicatedApp, arePagesEqual, getUniqueApplications };
