import type { EditorSDK, PageRef } from '@wix/platform-editor-sdk';

import type { UnifiedManagerTabId } from '../../types';
import { Context, Origin, ReferralInfo } from '../../types/bi';
import { createBIService } from '../../utils/bi';
import { getIsResponsiveEditor } from '../services/applicationState';
import { buildPanelUrl } from '../services/panel-url-builder';
import { APP_TOKEN } from '../constants';
import { SEO_REDIRECT } from '../constants/routes';
import { getWelcomePanelWasShown, setWelcomePanelWasShown } from './site-user-preference';

interface OpenModalPanelProps<T = any> {
  editorSDK: EditorSDK;
  eventPayload?: T;
}

export interface OpenManagePanelPayload {
  pageRef?: unknown;
  referralInfo?: ReferralInfo;
}

export type OpenAddTabsPanelPayload = OpenManagePanelPayload;

export async function openManagePagesPanel({ eventPayload, editorSDK }: OpenModalPanelProps<OpenManagePanelPayload>) {
  const biService = await createBIService({ editorSDK });
  biService.managePagesAddMemberPagesClick({
    origin: Origin.Editor,
    referralInfo: eventPayload?.referralInfo,
    context: Context.ManageMemberPages,
  });

  await editorSDK.editor.openModalPanel(APP_TOKEN, {
    url: await buildPanelUrl(editorSDK, 'managePages.html'),
    width: 1098,
    height: 696,
    shouldHideHeader: true,
    initialData: eventPayload,
  });
}

export async function openAddTabsPanel({ eventPayload, editorSDK }: OpenModalPanelProps<OpenAddTabsPanelPayload>) {
  const biService = await createBIService({ editorSDK });
  biService.managePagesAddMemberPagesClick({
    origin: Origin.Editor,
    referralInfo: eventPayload?.referralInfo,
    context: Context.AddTabs,
  });

  await editorSDK.editor.openModalPanel(APP_TOKEN, {
    url: await buildPanelUrl(editorSDK, 'addTabs.html'),
    width: 1098,
    height: 696,
    shouldHideHeader: true,
    initialData: eventPayload,
  });
}

export async function openAddProfileTabsPanel({
  eventPayload,
  editorSDK,
}: OpenModalPanelProps<OpenAddTabsPanelPayload>) {
  const biService = await createBIService({ editorSDK });
  biService.managePagesAddMemberPagesClick({
    origin: Origin.Editor,
    referralInfo: eventPayload?.referralInfo,
    context: Context.AddProfileTabs,
  });

  await editorSDK.editor.openModalPanel(APP_TOKEN, {
    url: await buildPanelUrl(editorSDK, 'addProfileTabs.html'),
    width: 1098,
    height: 696,
    shouldHideHeader: true,
    initialData: eventPayload,
  });
}

export async function openAddSettingsTabsPanel({
  eventPayload,
  editorSDK,
}: OpenModalPanelProps<OpenAddTabsPanelPayload>) {
  const biService = await createBIService({ editorSDK });
  biService.managePagesAddMemberPagesClick({
    origin: Origin.Editor,
    referralInfo: eventPayload?.referralInfo,
    context: Context.AddSettingsTabs,
  });

  await editorSDK.editor.openModalPanel(APP_TOKEN, {
    url: await buildPanelUrl(editorSDK, 'addSettingsTabs.html'),
    width: 1098,
    height: 696,
    shouldHideHeader: true,
    initialData: eventPayload,
  });
}

export async function openAddPagesPanel({ eventPayload, editorSDK }: OpenModalPanelProps<OpenAddTabsPanelPayload>) {
  const biService = await createBIService({ editorSDK });
  biService.managePagesAddMemberPagesClick({
    origin: Origin.Editor,
    referralInfo: eventPayload?.referralInfo,
    context: Context.AddPages,
  });

  await editorSDK.editor.openModalPanel(APP_TOKEN, {
    url: await buildPanelUrl(editorSDK, 'addPages.html'),
    width: 1098,
    height: 696,
    shouldHideHeader: true,
    initialData: eventPayload,
  });
}

export async function openGeneralSettingsPanel({ editorSDK, eventPayload }: OpenModalPanelProps) {
  const isResponsiveEditor = getIsResponsiveEditor();
  const query = isResponsiveEditor ? '?origin=editor_x' : '';

  await editorSDK.editor.openModalPanel(APP_TOKEN, {
    url: await buildPanelUrl(editorSDK, 'generalSettings.html', query),
    width: 876,
    height: 631,
    shouldHideHeader: true,
    initialData: eventPayload,
  });
}

export function openMemberPrivacySettingsDashboard(editorSDK: EditorSDK) {
  return editorSDK.editor.openDashboardPanel(APP_TOKEN, {
    url: '/member-privacy-settings/profile-privacy?referralInfo=editor_actions_menu',
    closeOtherPanels: false,
  });
}

export const openSeoRedirectDashboard = (editorSDK: EditorSDK) => {
  return editorSDK.editor.openDashboardPanel(APP_TOKEN, { url: SEO_REDIRECT, closeOtherPanels: false });
};

const openWelcomePanel = async (editorSDK: EditorSDK, pageRef: PageRef) => {
  return editorSDK.editor.openModalPanel('', {
    url: await buildPanelUrl(editorSDK, 'welcomePanel.html'),
    height: 442,
    width: 1051.87,
    centered: true,
    shouldHideHeader: true,
    initialData: { pageRef },
  });
};

export const openWelcomePanelIfItWasNotShown = async (editorSDK: EditorSDK, pageRef: PageRef) => {
  const welcomePanelWasShown = await getWelcomePanelWasShown(editorSDK);

  if (welcomePanelWasShown) {
    return;
  }

  await setWelcomePanelWasShown(editorSDK, true);

  return openWelcomePanel(editorSDK, pageRef);
};

export type OpenUnifiedManagerPanelOptions = {
  initialTab: UnifiedManagerTabId;
};

export const openUnifiedManagerPanel = async ({
  editorSDK,
  eventPayload,
}: OpenModalPanelProps<OpenUnifiedManagerPanelOptions>) => {
  return editorSDK.editor.openModalPanel('', {
    url: await buildPanelUrl(editorSDK, 'unifiedManager.html'),
    height: 696,
    width: 1098,
    centered: true,
    shouldHideHeader: true,
    initialData: { ...eventPayload },
  });
};
