import { EditorSDK, ResponsiveLayout, ComponentRef } from '@wix/platform-editor-sdk';

import { log } from '../../utils/monitoring';
import { getIsResponsiveEditor } from '../services/applicationState';
import { LOGIN_COMP_RESPONSIVE_LAYOUT_WIDTH_AND_HEIGHT } from '../constants/layouts/login-bar/responsive-login-bar-layouts';

const maybeUpdateResponsiveLayout = async (editorSDK: EditorSDK, componentRef: ComponentRef) => {
  const responsiveLayout = await editorSDK.responsiveLayout.get('', { componentRef });
  const firstComponent = responsiveLayout.componentLayouts[0];
  const heightType = firstComponent.height?.type;
  const widthType = firstComponent.width?.type;

  if (heightType === 'percentage' && widthType === 'percentage') {
    return;
  }

  const newComponentLayouts = [
    { ...responsiveLayout.componentLayouts[0], ...LOGIN_COMP_RESPONSIVE_LAYOUT_WIDTH_AND_HEIGHT },
  ];
  const newResponsiveLayout = {
    ...responsiveLayout,
    componentLayouts: newComponentLayouts,
  } as unknown as ResponsiveLayout;

  await editorSDK.responsiveLayout.update('', { componentRef, responsiveLayout: newResponsiveLayout });
};

export const maybeFixLoginBarResponsiveLayout = async (editorSDK: EditorSDK) => {
  try {
    const isResponsiveEditor = getIsResponsiveEditor();

    if (isResponsiveEditor) {
      const loginBarComponentRefs = await editorSDK.components.findAllByType('', {
        componentType: 'wysiwyg.viewer.components.LoginSocialBar',
      });

      for (const componentRef of loginBarComponentRefs) {
        await maybeUpdateResponsiveLayout(editorSDK, componentRef);
      }
    }
  } catch (e) {
    log('An error was thrown while fixing a login bar responsive layout', {
      extra: {
        error: e,
      },
    });
  }
};
