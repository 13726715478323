import { ApplicationAction } from '@wix/platform-editor-sdk';
import { TranslationFunction } from 'i18next';

import { MembersAreaEvent } from '../../types';

export const getOpenGeneralSettingsAction = (t: TranslationFunction) => ({
  title: t('Pages_Panel_MemberMenu_Open_GeneralSettings'),
  event: MembersAreaEvent.GeneralSettings,
  icon: 'settingsAction',
});

export const getOpenMemberPrivacyDashboardAction = (t: TranslationFunction) => ({
  title: t('Pages_Panel_MemberMenu_Open_MemberPrivacyDashboard'),
  event: MembersAreaEvent.MemberPrivacyDashboard,
  icon: 'unhideAction',
});

export const VIEW_SITE_MEMBERS: ApplicationAction = {
  // @ts-expect-error
  type: 'view_site_members',
};

export const OPEN_SIGNUP_SETTINGS: ApplicationAction = {
  // @ts-expect-error
  type: 'open_signup_settings',
};

export const getDeleteCustomPageAction = (t: TranslationFunction): ApplicationAction => ({
  title: t('Pages_Panel_MemberMenu_Delete_Custom_Page'),
  event: MembersAreaEvent.RemovePage,
  icon: 'delete_icon',
});

export const getAddPageAction = (t: TranslationFunction): ApplicationAction => ({
  title: t('Pages_Panel_MemberMenu_Add_Page'),
  type: 'add_page',
  event: MembersAreaEvent.CreateBlankPage,
  icon: 'addPagePagesPanel',
});

export const getAdvancedURLSettingsAction = (t: TranslationFunction): ApplicationAction => ({
  title: t('Pages_Panel_MemberMenu_URLSettings_Link'),
  event: MembersAreaEvent.RenameRouter,
  icon: 'globe',
});

export const getDeleteMembersAreaAction = (t: TranslationFunction) => ({
  title: t('Pages_Panel_MemberMenu_Delete_Link'),
  event: MembersAreaEvent.Uninstall,
  icon: 'delete_icon',
});
