import type { EditorSDK } from '@wix/platform-editor-sdk';
import type { WidgetId } from '@wix/members-area-app-definitions';

import { getPageIdFromWidgetId } from '@wix/members-area-app-definitions';

import { APP_TOKEN } from '../../../constants';
import { getCurrentPage, navigateToPageRef } from '../../../wrappers/pages';
import { getMemberSettingsPageRef, getPageRefByTPAPageId } from '../../../wrappers/tpa';
import { getMemberSettingsPageRoutes, getProfilePageRoutes } from './routes';
import { navigateToSection } from './members-blocks-platform';

export const navigateToMemberSettingsPage = async (editorSDK: EditorSDK) => {
  const memberSettingsPageRef = await getMemberSettingsPageRef(editorSDK);
  if (!memberSettingsPageRef) {
    return;
  }

  const currentPage = await getCurrentPage({ editorSDK });
  if (currentPage.id === memberSettingsPageRef.id) {
    return;
  }

  return editorSDK.pages.navigateTo(APP_TOKEN, { pageRef: memberSettingsPageRef });
};

export const navigateToPageByWidgetId = async (editorSDK: EditorSDK, widgetId: WidgetId) => {
  const [settingsRoutes, profileRoutes] = await Promise.all([
    getMemberSettingsPageRoutes(editorSDK),
    getProfilePageRoutes(editorSDK),
  ]);
  const routes = [...settingsRoutes, ...profileRoutes];

  if (routes.some((route) => route.widgetId === widgetId)) {
    return navigateToSection(editorSDK, widgetId);
  }

  const pageId = getPageIdFromWidgetId(widgetId);
  const pageRef = await getPageRefByTPAPageId(editorSDK, pageId);

  if (pageRef) {
    return navigateToPageRef({ editorSDK, pageRef });
  }
};
