import type { AppManifest, EditorSDK, PageSettings } from '@wix/platform-editor-sdk';
import type { TranslationFunction } from 'i18next';

import { buildPanelUrl } from '../../../../services/panel-url-builder';

type PageSettingsOptions = NonNullable<AppManifest['pages']>['pageSettings'];

const getPageInfoSettings = async (editorSDK: EditorSDK, t: TranslationFunction): Promise<PageSettings> => ({
  type: 'page_info',
  title: t('NewPages_Panel_PageInfo_Members_Title'),
  helpId: '2fd96dc5-ff35-4ead-9917-12b487c59fe4',
  url: await buildPanelUrl(editorSDK, 'pageInfoV3.html'),
});

const getLayoutSettings = (t: TranslationFunction): PageSettings => ({
  type: 'layout',
  title: t('NewPages_Panel_Layout_Members_Title'),
});

const getPermissionsSettings = (t: TranslationFunction): PageSettings => ({
  type: 'permissions',
  title: t('NewPages_Panel_Layout_Members_Title'),
  helpId: 'd243ad48-2e17-4786-99d7-23d011aa4bd6',
});

export const getPageSettings = async (editorSDK: EditorSDK, t: TranslationFunction): Promise<PageSettingsOptions> => {
  return {
    default: [],
    profile: [await getPageInfoSettings(editorSDK, t), getLayoutSettings(t), getPermissionsSettings(t)],
    settings: [await getPageInfoSettings(editorSDK, t), getLayoutSettings(t)],
    standalone: [await getPageInfoSettings(editorSDK, t), getLayoutSettings(t)],
    custom: [await getPageInfoSettings(editorSDK, t), getLayoutSettings(t), getPermissionsSettings(t)],
    lightbox: [],
  };
};
